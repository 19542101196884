import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  background-color: rgba(111, 59, 140, 0.95);
  padding-right: 10px;
  padding-left: 10px;
`;

const CloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  height: 80vh;
  background-color: #6f3b8c;
  width: 370px;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 0px) and (max-width: 600px) {
    width: 80%;
  }
`;

const MiniCard = styled.div`
  height: 40vh;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#6f3b8c"};
  width: 150px;
  border: 1px solid transparent;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px 20px 0px 20px;
`;

const BarWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  padding-right: 5px;
`;

const Bar = styled.div`
  width: ${({ width }) => (width ? `${width}px` : 100)};
  height: 5px;
  margin-left: 5px;
  background-color: ${({ active }) => (active ? "#FFFFFF" : "#808080")};
  border: 1px solid transparent;
  border-radius: 5px;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
`;

const NameWrapper = styled.p`
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0px;
`;

const DescriptionWrapper = styled.p`
  color: white;
`;

export {
  Container,
  CardContainer,
  Card,
  MiniCard,
  Bar,
  BarWrapper,
  TextContainer,
  NameWrapper,
  DescriptionWrapper,
  CloseIconContainer,
};
