import React, { useState } from "react";
import * as S from "./styles";
import BuyButton from "../../../components/BuyButton";
import CardsThirdContainerSantoAmaro from "./CardsThirdContainerSantoAmaro";
import CardsThirdContainerTatuape from "./CardsThirdContainerTatuape";
import CardsThirdContainerBrasilia from "./CardsThirdContainerBrasilia";
import { objectMapper } from "../../Unidades/Objects";
import { Link } from "react-router-dom";
import Atracoes from "../../Unidades/Atracoes";
import MobileAtracoes from "../../Unidades/MobileAtracoes";
import { ParkAreas } from "../../../components/ParkAreas";

const ThirdContainer = ({ unit, mobile }) => {
  const [showAtracao, setShowAtracao] = useState(false);
  const [area, setArea] = useState("");

  function handleUnit(unit) {
    if (unit === "SantoAmaro") {
      return <CardsThirdContainerSantoAmaro unit={unit} />;
    } else if (unit === "Tatuape") {
      return <CardsThirdContainerTatuape />;
    } else {
      return <CardsThirdContainerBrasilia />;
    }
  }
  return (
    <S.Container>
      <S.TextWrapper color={"#fdc028"}>
        Aqui você encontra nossos preços e tudo que precisa saber para garantir
        seu ingresso e brincar no Pop!
      </S.TextWrapper>
      {handleUnit(unit)}

      <S.TextWrapper color={"white"} marginTop={"20px"} marginBottom={"20px"}>
        Conheça as atrações
      </S.TextWrapper>
      <ParkAreas />
      <BuyButton width={"300px"} marginTop={"30px"} />
    </S.Container>
  );
};

export default ThirdContainer;
