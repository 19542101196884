import React from "react";
import * as S from "./styles";
import horizontalVector from "../../../assets/horizontalVector.svg";
import calendarIcon from "../../../assets/calendarIcon.svg";
import ticketIcon from "../../../assets/ticketIcon.svg";
import starIcon from "../../../assets/starIcon.svg";
import clockIcon from "../../../assets/clockIcon.svg";
import { getWindowDimensions } from "../../../services/getWindowDimensions";

const SecondContainer = ({ unit }) => {
  return (
    <S.Container>
      <S.TextWrapper>Passo a passo para garantir seu ingresso!</S.TextWrapper>
      <S.CardContainer className="w-100">
        <S.Card>
          <img src={calendarIcon} alt="Icone de calendário" />
          <S.CardTextWrapper>Escolha a data da sua visita</S.CardTextWrapper>
        </S.Card>
        {getWindowDimensions().width > 700 ? (
          <img src={horizontalVector} alt="Vetor Horizontal" />
        ) : null}
        <S.Card>
          <img src={ticketIcon} alt="Icone de calendário" />
          <S.CardTextWrapper>
            <div>Escolha o tipo de ingresso </div>
            <div>(brincante ou não brincante)</div>
          </S.CardTextWrapper>
        </S.Card>
        {getWindowDimensions().width > 700 && unit === "SantoAmaro" ? (
          <img src={horizontalVector} alt="Vetor Horizontal" />
        ) : null}
        {unit === "SantoAmaro" ? (
          <S.Card>
            <img src={starIcon} alt="Icone de calendário" />
            <S.CardTextWrapper>
              <div>Selecione as áreas em que quer brincar</div>
              <div>(Jump, Indoor ou Outdoor)</div>
            </S.CardTextWrapper>
          </S.Card>
        ) : null}
        {getWindowDimensions().width > 700 ? (
          <img src={horizontalVector} alt="Vetor Horizontal" />
        ) : null}
        <S.Card>
          <img src={clockIcon} alt="Icone de calendário" />
          <S.CardTextWrapper>
            Escolha a hora de entrada no parque e a duração da brincadeira
          </S.CardTextWrapper>
        </S.Card>
      </S.CardContainer>
    </S.Container>
  );
};

export default SecondContainer;
