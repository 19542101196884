import React, { useContext, useState } from "react";
export const PartyContext = React.createContext();

const useParty = () => {
  const [partyName, setPartyName] = useState("");
  const [unitParty, setUnitParty] = useState("");

  return [partyName, setPartyName, unitParty, setUnitParty];
};

export const PartyProvider = ({ children }) => {
  const [partyName, setPartyName, unitParty, setUnitParty] = useParty();
  return (
    <PartyContext.Provider
      value={{
        partyName,
        setPartyName,
        unitParty,
        setUnitParty
      }}
    >
      {children}
    </PartyContext.Provider>
  );
};

export const PartyHook = () => {
  const context = useContext(PartyContext);
  return context;
};
