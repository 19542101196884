import styled from "styled-components";

const Container = styled.div`
  margin: 0px;
  height: 80vh;
  min-height: 400px;
  margintop: -20px;
  border: 1px solid transparent;
  borderradius: 20px;
`;

const CenterContainer = styled.div`
  padding: 30px 25% 30px 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;

  @media (min-width: 0px) and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const TitleContainer = styled.div`
  height: 150px;
  background-color: rgb(237, 80, 70);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 2em;
  text-align: center;
`;

const TextWrapper = styled.p`
  color: #6f3b8c;
  font-weight: 700;
  font-size: 26px;
  text-align: left;
  width: 100%;
`;

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;

const SectionWrapper = styled.div`
  width: 30%;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "10px")};
  height: 51px;
  background-color: ${({ active }) => (active ? "#6f3b8c" : "white")};
  border: 1px solid #6f3b8c;
  border-radius: 20px;
  color: ${({ active }) => (active ? "white" : "#6f3b8c")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  margin: 2px;

  @media (min-width: 0px) and (max-width: 600px) {
    font-size: 12px;
  }
`;

const SectionTitleWrapper = styled.h3`
  color: #6f3b8c;
  font-size: 18px;
  text-align: left;
  margin-top: 40px;
  width: 100%;
`;

const QuestionWrapper = styled.p`
  color: #6f3b8c;
  font-size: 18px;
  text-align: left;
  margin-top: 40px;
  width: 100%;
  font-weight: 700;

  @media (min-width: 0px) and (max-width: 600px) {
    font-size: 16px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const AnswerWrapper = styled.p`
  color: #6f3b8c;
  font-size: 16px;
  text-align: left;
  width: 100%;
  font-weight: 500;

  @media (min-width: 0px) and (max-width: 600px) {
    font-size: 14px;
  }

  p {
    margin: 0px;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 51px;
  color: white;
  background-color: #6f3b8c;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 20px;
`;

const UnitSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

const SelectionLabel = styled.span`
  color: #6f3b8c;
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  margin-right: 8px;

  @media (min-width: 0px) and (max-width: 600px) {
    font-size: 16px;
  }
`;

export {
  Container,
  CenterContainer,
  TextWrapper,
  TitleWrapper,
  TitleContainer,
  SectionContainer,
  SectionWrapper,
  SectionTitleWrapper,
  QuestionWrapper,
  AnswerWrapper,
  QuestionContainer,
  Button,
  UnitSelectorContainer,
  SelectionLabel,
};
