import React from "react";
import downArrow from "../../assets/downArrow.svg";
import { Menu } from "../Menu";
import Dropdown from "react-bootstrap/Dropdown";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";

const NavMenu = () => {
  const navigate = useNavigate();
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <S.MenuWrapper
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <img src={downArrow} alt="down arrow" style={{ marginLeft: 5 }} />
    </S.MenuWrapper>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">{children}</ul>
        </div>
      );
    }
  );

  return (
    <Dropdown className="d-flex justify-content-between w-100">
      {Menu.map((item, index) => {
        return (
          <div key={index}>
            {item.submenu ? (
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id={index}>
                  {item.title}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                  {item.submenu.map((subitem, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          navigate(subitem.url);
                          navigate(0);
                        }}
                      >
                        {subitem.title}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <S.MenuWrapper
                onClick={() => (item.func ? item.func() : navigate(item.url))}
              >
                {item.title}
              </S.MenuWrapper>
            )}
          </div>
        );
      })}
    </Dropdown>
  );
};

export default NavMenu;
