import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import SecondContainer from "./SecondContainer";
import FirstContainer from "./FirstContainer";
import ThirdContainer from "./ThirdContainer";
import Header from "../../components/Header";

const Precos = ({ mobile }) => {
  const urlParams = new URLSearchParams(window.location.search.substring(1));
  const unit = urlParams.get("Unidade");

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <>
      <Helmet>
        <title>Preços de ingressos do PopHaus {unit === "SantoAmaro" ? "Santo Amaro" : unit === "Tatuape" ? "Tatuapé" : unit} | PopHaus</title>
      </Helmet>
      <Header />
      <FirstContainer unit={unit} />
      <SecondContainer unit={unit} />
      <ThirdContainer unit={unit} mobile={mobile} />
      <Footer />
    </>
  );
};

export default Precos;
