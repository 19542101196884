import styled from "styled-components";

const Container = styled.div`
  margin: 0px;
  background-repeat: no-repeat;
  ${({ mobile }) =>
    mobile ? "height: calc(100vh); padding-bottom: 50px;" : "height: 100vh;"}
  display: flex;
  flex-direction: center;
  min-height: 500px;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  @media (min-width: 0px) and (max-width: 800px) {
    justify-content: flex-end;
  }
`;

const TitleWrapper = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 40px;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 26px;
  }
`;

const SubTitle = styled.p`
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  max-width: 500px;
  text-align: center;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 16px;
    max-width: 300px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: 0px) and (max-width: 970px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  width: 276px;
  height: 48px;
  border: 1px solid #6f3b8c;
  border-radius: 10px;
  background-color: #6f3b8c;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding-left: 2px;
`;

const IconWrapper = styled.div`
  background-color: #fdc028;
  border: 1px solid #fdc028;
  border-radius: 10px;
  width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-itens: center;
`;

const Button = styled.button`
  width: 303px;
  height: 47px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#fdc028"};
  border: 1px solid transparent;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : "#6f3b8c")};
  font-weight: 700;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 10px;

  @media (min-width: 0px) and (max-width: 350px) {
    width: 95%;
  }
`;

const TextWrapper = styled.div`
  color: ${({ color }) => (color ? color : "white")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  margin-left: 2px;
`;

export {
  Container,
  InputWrapper,
  InputContainer,
  IconWrapper,
  Button,
  TextWrapper,
  CenterContainer,
  TitleWrapper,
  SubTitle,
};
