import React from "react";

const ProgressBar = ({ className = "" }) => {
  return (
    <div
      className={"progress " + className}
      style={{ height: 10, width: "100%", margin: 0, padding: 0 }}
    >
      <div
        class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: "100%", height: 10, borderRadius: 5 }}
      ></div>
    </div>
  );
};

export default ProgressBar;
