import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { objectMapper } from "./Objects";
import * as S from "./styles";
import { BsWhatsapp } from "react-icons/bs";
import { ParkAreas } from "../../components/ParkAreas";
import { PDFViewer } from "../../components/PDFViewer";

const Unidades = ({ mobile }) => {
  const urlParams = new URLSearchParams(window.location.search.substring(1));
  const unit = urlParams.get("Unidade");

  let PDFFile;

  if (unit === "Tatuape") {
    PDFFile =
      "https://pophaus-files.s3.us-east-2.amazonaws.com/rules_recommendations/regras_recomendacoes_ttp_v2.pdf";
  }
  if (unit === "SantoAmaro") {
    PDFFile =
      "https://pophaus-files.s3.us-east-2.amazonaws.com/rules_recommendations/regras_recomendacoes_sta_v3.pdf";
  }

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Conheça o PopHaus {objectMapper[unit].Title}, em São Paulo | PopHaus
        </title>
      </Helmet>
      <S.Container
        mobile={mobile}
        style={{
          background: `linear-gradient(0deg, rgba(111, 59, 140, 0.6), rgba(111, 59, 140, 0.6)), url(${objectMapper[unit].Background})`,
          backgroundSize: "cover",
          BackgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <Header backgroundColor={"transparent"} />
        <S.CenterContainer
          mobile={mobile}
          style={{ justifyContent: "flex-end" }}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ flex: 1 }}
          >
            <S.TitleWrapper>Unidade {objectMapper[unit].Title}</S.TitleWrapper>
            <S.TextWrapper fontSize={"20px"}>
              {objectMapper[unit].SubTitle}
            </S.TextWrapper>
            <S.Button
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_OLD_WEBSITE_URL}/comprar-ingressos?origem=website&filial=${objectMapper[unit].param}`
                )
              }
            >
              Compre seu ingresso
            </S.Button>

            <S.Button
              onClick={() =>
                (window.location.href = `/regras?filial=${objectMapper[unit].param}`)
              }
            >
              Regras e Recomendações
            </S.Button>

            {/* <PDFViewer
              pdf={PDFFile}
              PDFName={"Regras e recomendações"}
              buttonText="Regras e recomendações"
            /> */}

            <S.Button
              onClick={() => window.open(objectMapper[unit].whatsapp_url)}
              style={{ marginBottom: 20 }}
            >
              Fale com a gente!
              {<BsWhatsapp style={{ marginLeft: "1rem" }} size={"1.3rem"} />}
            </S.Button>
          </div>
          <ParkAreas />
        </S.CenterContainer>
      </S.Container>
      <Footer />
    </div>
  );
};

export default Unidades;
