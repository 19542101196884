export const MockedData = {
  units: {
    SANTO_AMARO: [
      // {
      //   id: "shared_parties",
      //   name: "Fevereiro e Março 2024",
      //   date: "QUA A DOM",
      //   duration: "3 A 4 HORAS",
      //   minGuests: "15",
      //   pdf: "https://pophaus-files.s3.us-east-2.amazonaws.com/party_bundles/sta_fev_mar_24.pdf",
      //   click_id: "sta_festas_fev_mar24",
      // },

      // {
      //   id: "shared_parties",
      //   name: "Julho 2024",
      //   date: "QUA A DOM",
      //   duration: "3 A 4 HORAS",
      //   minGuests: "15",
      //   pdf: "https://pophaus-files.s3.us-east-2.amazonaws.com/party_bundles/sta_jul_24.pdf",
      //   click_id: "sta_festas_jul24",
      // },

      {
        id: "semi_exclusive_parties",
        name: "Agosto e setembro 2024",
        description: "",
        date: "SEXTA",
        duration: "4 HORAS",
        minGuests: "30",
        pdf: "https://pophaus-files.s3.us-east-2.amazonaws.com/party_bundles/sta_ago_set_24.pdf",
        click_id: "sta_festas_ago_set24",
      },
    ],
    TATUAPE: [
      // {
      //   id: "shared_parties",
      //   name: "Fevereiro e Março 2024",
      //   date: "QUA A DOM",
      //   duration: "3 A 4 HORAS",
      //   minGuests: "15",
      //   pdf: "https://pophaus-files.s3.us-east-2.amazonaws.com/party_bundles/ttp_fev_mar_24.pdf",
      //   click_id: "ttp_festas_fev_mar24",
      // },

      // {
      //   id: "shared_parties",
      //   name: "Julho 2024",
      //   date: "QUA A DOM",
      //   duration: "3 A 4 HORAS",
      //   minGuests: "15",
      //   pdf: "https://pophaus-files.s3.us-east-2.amazonaws.com/party_bundles/ttp_jul_24.pdf",
      //   click_id: "ttp_festas_jul24",
      // },

      {
        id: "semi_exclusive_parties",
        name: "Agosto e setembro 2024",
        description: "",
        date: "SEXTA",
        duration: "3 HORAS E MEIA",
        minGuests: "25",
        pdf: "https://pophaus-files.s3.us-east-2.amazonaws.com/party_bundles/ttp_ago_set_24.pdf",
        click_id: "ttp_festas_ago_set24",
      },
    ],
  },
};
