import React, { useState } from "react";
import "./styles.scss";

export const ListContact = ({ branchMenu, setBranchMenu, branches }) => {
  const [selectedState, setSelectedState] = useState("SANTO_AMARO");

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setBranchMenu(event.target.value);
  };

  const openMail = (mail) => {
    window.open(`mailto:${mail}`);
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <select
          className="form-select w-50"
          value={selectedState}
          onChange={handleStateChange}
        >
          <option value="SANTO_AMARO">Santo Amaro</option>
          <option value="TATUAPE">Tatuapé</option>
          {/* <option value="BRASILIA">Brasília</option> */}
        </select>
      </div>
      <div className="ListContact__content mt-4">
        <div className="ListContact__infos">
          <h6 className="mt-4 text-secondary fw-bold">
            Horário de funcionamento
          </h6>
          <p className="ListContact__info-data">
            {branches[branchMenu].horario}
          </p>
          <h6 className="mt-4 text-secondary fw-bold">Endereço</h6>
          <p
            className="ListContact__info-data ListContact__link"
            onClick={() => window.open(branches[branchMenu].endereco.url)}
          >
            {branches[branchMenu].endereco.texto}
          </p>
          <h6 className="mt-4 text-secondary fw-bold">Trabalhe conosco</h6>
          <p
            className="ListContact__info-data ListContact__link"
            onClick={() => window.open(branches[branchMenu].work_with_us)}
          >
            {"Clique aqui para acessar o formulário!"}
          </p>
        </div>
        <div>
          <h6 className="mt-4 text-secondary fw-bold">Duvidas e suporte</h6>
          <p
            className="text-white text-start ListContact__link"
            onClick={() => openMail(branches[branchMenu].imprensa)}
          >
            {branches[branchMenu].suporte}
          </p>
          <h6 className="mt-4 text-secondary flex text-start fw-bold">
            WhatsApp
          </h6>
          <p
            className="ListContact__link text-white text-start"
            onClick={() => window.open(branches[branchMenu].whatsapp_url)}
          >
            Número: {branches[branchMenu].whatsapp_number}
          </p>
          <h6 className="mt-4 text-secondary flex text-start fw-bold">
            Telefone
          </h6>
          <p
            className="ListContact__link text-white text-start"
            onClick={() => {
              window.location.href = `tel:${branches[branchMenu].phone_number}`;
            }}
          >
            Número: {branches[branchMenu].phone_number}
          </p>
          <h6 className="mt-4 text-secondary flex text-start fw-bold">CNPJ</h6>
          <p className="text-white text-start">
            CNPJ: {branches[branchMenu].cnpj}
          </p>
          <h6 className="mt-4 text-secondary flex text-start fw-bold">
            Imprensa
          </h6>
          <p
            className="text-white text-start ListContact__link"
            onClick={() => openMail(branches[branchMenu].imprensa)}
          >
            {branches[branchMenu].imprensa}
          </p>
        </div>
      </div>
    </>
  );
};
