import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import * as S from "./styles";
import BootstrapCard from "../../components/BootstrapCard";
import * as worker from "./worker";
import ProgressBar from "../../components/ProgressBar";
import { useNavigate } from "react-router-dom";

const Galeria = ({ doAlert, mobile }) => {
  const navigate = useNavigate();
  const [albums, setAlbums] = useState([]);
  const [albumsLoader, setAlbumsLoader] = useState(false);

  const handleOpenAlbum = (album) => {
    navigate(`/Galeria/${album.id}`);
  };

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  const getAlbums = () => {
    setAlbumsLoader(true);
    worker.getAlbums({}, (res, error) => {
      setAlbumsLoader(false);
      if (error)
        return doAlert(
          "danger",
          error.message ||
            "Erro ao carregar álbuns da galeria! Tente novamente mais tarde ou entre em contato com o suporte!"
        );
      setAlbums(res?.albums);
    });
  };

  useEffect(() => {
    getAlbums();
  }, []);

  return (
    <S.PageContainer>
      <Helmet>
        <title>Galeria de fotos | PopHaus</title>
      </Helmet>
      <Header />
      <S.TitleWrapper>Galeria</S.TitleWrapper>
      <S.Container class="container">
        <div
          class="row justify-content-center"
          style={{ flex: 1, maxWidth: 1100 }}
        >
          {albumsLoader ? (
            <ProgressBar />
          ) : !albums[0] ? (
            <S.EmptyListText>Nada para mostrar.</S.EmptyListText>
          ) : (
            albums.map((i, index) => (
              <div
                key={"album_" + i.id}
                class={`d-flex justify-content-center col-xs-6 col-md-4 col-lg-3`}
              >
                <BootstrapCard
                  title={i.name}
                  description={`${i.pictures_count} fotos`}
                  thumb_url={i.cover_url}
                  action_label={"Acessar Álbum"}
                  action_url={i.url}
                  backgroundColor="transparent"
                  border="none"
                  titleColor={"#fdc028"}
                  descriptionColor={"white"}
                  onClick={() => handleOpenAlbum(i)}
                  mobile={mobile}
                />
              </div>
            ))
          )}
        </div>
      </S.Container>
      <Footer />
    </S.PageContainer>
  );
};

export default Galeria;
