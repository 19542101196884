import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  ${({ isMobile }) => (isMobile ? "flex-direction: column-reverse;" : "")}
  align-items: center;
  ${({ isMobile }) =>
    isMobile ? "justify-content: center;" : "justify-content: space-between;"}
  background-color: #5ea8d9;
  ${({ isMobile }) =>
    isMobile ? "padding: 60px 20px 60px 20px;" : "padding: 150px;"}
`;

export const TitleContainer = styled.div`
  flex: 1;
`;

export const Title = styled.h1`
  ${({ isMobile }) =>
    isMobile
      ? `
  font-size: 32px;
  text-align: center;`
      : `
  font-size: 46px;
  text-align: flex-start;
  align-self: flex-start;
  `}
  color: white;
  font-weight: 700;
`;

export const Subtitle = styled.p`
  ${({ isMobile }) =>
    isMobile
      ? `
  text-align: center;`
      : `
  text-align: start;
  `}
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
`;

export const IconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Icon = styled.img`
  ${({ isMobile }) =>
    isMobile
      ? `
  max-width: 350px;
  max-height: 350px;
  `
      : `
  max-width: 500px;
  max-height: 500px;
  `}
  object-fit: contain;
`;

export const WhiteContainer = styled.div`
  ${({ isMobile }) =>
    isMobile ? "padding: 10px 60px 80px 60px;" : "padding: 0px 80px 80px 80px;"}
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-top: -40px;
  background: white;
`;

export const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #6f3b8c;
  ${({ isMobile }) => (isMobile ? "padding: 10px;" : "padding: 60px;")}
`;

export const IconPopS = styled.img`
  height: 200px;
  width: 200px;
  ${({ isMobile }) =>
    isMobile
      ? `
  max-width: 80px;
  max-height: 80px;
  `
      : `
  max-width: 150px;
  max-height: 150px;
  `}
  object-fit: contain;
`;

export const IconPopT = styled.img`
  max-width: 150px;
  max-height: 150px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export const SecondTitle = styled.h2`
  ${(isMobile) => (isMobile ? `font-size: 28px;` : `font-size: 40px;`)}
  color: #fdc028;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
`;

export const SecondSubtitle = styled.p`
  ${({ isMobile }) => (isMobile ? "" : "font-size: 26px;")}
  color: #fdc028;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 0;
`;

export const Text = styled.p`
  ${({ isMobile }) => (isMobile ? "font-size: 12px;" : "font-size: 18px;")}
  color: white;
  text-align: center;
`;

export const SmallText = styled.p`
  ${({ isMobile }) => (isMobile ? "font-size: 10px;" : "font-size: 14px;")}
  color: #aaa;
  max-width: 350px;
  text-align: center;
`;

export const TextSecond = styled.p`
  font-size: 22px;
  color: white;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-top: 20px;
`;

export const ThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThirdTitle = styled.h2`
  font-size: 32px;
  color: #6f3b8c;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 30px;
`;

export const ThirdInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

export const Number = styled.div`
  font-size: 60px;
  color: #6f3b8c;
  font-weight: 700;
`;

export const ThirdText = styled.div`
  font-size: 18px;
  color: #6f3b8c;
  font-weight: 700;
`;

export const TextDescription = styled.p`
  font-size: 18px;
  color: #6f3b8c;
  text-align: start;
`;

export const TextTitle = styled.h3`
  font-size: 30px;
  color: #fdc028;
  font-weight: 700;
  text-transform: uppercase;
  text-align: start;
`;

export const TextSubtitle = styled.h3`
  font-size: 18px;
  color: #6f3b8c;
  font-weight: 700;
  text-align: start;
`;

export const Button = styled.button`
  padding: 15px 50px 15px 50px;
  gap: 10px;
  border-radius: 20px;
  border: none;
  background-color: #6f3b8c;
  color: white;
  margin-top: 20px;
  align-self: center;
`;

export const Line = styled.hr`
  border: none;
  border-top: 1px solid #6f3b8c;
  width: 100%;
`;

export const ContainerIcons = styled.div`
  display: flex;
  ${({ isMobile }) => (isMobile ? "flex-direction: column;" : "")}
  justify-content: space-between;
  align-items: flex-start;
`;

export const ColumnUnitS = styled.div`
  flex: 1;
`;

export const ColumnUnitT = styled.div``;

export const DivWithLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const ContainerSmallIcon = styled.div`
  ${({ isMobile }) =>
    isMobile
      ? `
  max-width: 100px;
  max-height: 100px;
  `
      : `
  max-width: 180px;
  max-height: 180px;
  `}
  border-radius: 6px;
  border: 1px solid #6f3b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const SmallIcon = styled.img`
  object-fit: contain;
  width: 200px;
  height: 200px;
  ${({ isMobile }) =>
    isMobile
      ? `
  max-height: 80px;
  max-width: 80px;
  `
      : `
  max-height: 150px;
  max-width: 150px;
  `}
`;
export const TextDescriptionIcons = styled.div`
  font-size: 16px;
  color: #6f3b8c;
  margin-left: 10px;
  align-items: center;
`;

export const FourthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #5ea8d9;
  padding: 60px;
`;

export const TextFifthContainer = styled.p`
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  color: white;
`;
export const ButtonFifthContainer = styled.button`
  width: Fixed (275px) px;
  height: Hug (51px) px;
  padding: 15px 30px 15px 30px;
  gap: 10px;
  border-radius: 20px;
  border: none;
  opacity: 0px;
  color: #6f3b8c;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
`;

export const GridContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  ${({ isMobile, column }) =>
    isMobile
      ? `
      ${column ? "flex-direction: column;" : "flex-wrap: wrap;"}
      gap: 10px;
    `
      : `
      gap: 20px;
    `}
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  ${({ isMobile, column }) =>
    isMobile
      ? `
      ${
        column
          ? "flex-direction: row; align-items: center;"
          : "min-width: 100px; flex: 1;"
      }
      gap: 10px;
  `
      : `
      align-items: center;
      flex: 1;
      gap: 20px;
      text-align: center;
  `}
`;

export const GridItemIconContainer = styled.div`
  border-style: solid;
  border-color: #6f3b8c;
  border-width: 1px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isMobile, column }) =>
    isMobile
      ? `
      max-height: 100px;
      ${column ? "max-width: 100px;" : ""}
  `
      : `
      max-height: 180px;
      align-self: stretch;
  `}
  padding: 10px;
`;

export const GridItemIcon = styled.img`
  object-fit: contain;
  width: 200px;
  height: 200px;
  ${({ isMobile }) =>
    isMobile
      ? `
  max-height: 80px;
  max-width: 80px;
  `
      : `
  max-height: 150px;
  max-width: 150px;
  `}
`;

export const GridItemText = styled.div`
  ${({ column }) => (column ? "" : "text-align: center;")}
`;

export const GridItemAction = styled.a`
  color: #6f3b8c;
  font-weight: bold;
  cursor: pointer;
`;
