import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
`;

const Container = styled.div`
  background-color: #ed5046;
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 32px;
`;

const TitleWrapper = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 2em;
  background-color: #ed5046;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 0px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 12px;
`;

const EmptyListText = styled.span`
  color: white;
  text-align: center;
`;

const Button = styled.div`
  background-color: #fdc028;
  height: 44px;
  border: 1px solid #fdc028;
  border-radius: 20px;
  color: #6f3b8c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: ${({ width }) => (width ? width : "")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  cursor: pointer;
`;

export { PageContainer, Container, TitleWrapper, Image, EmptyListText, Button };
