import React from "react";
import * as S from "./styles";
import aboutFirstIcon from "../../../assets/aboutFirstIcon.svg";

const SecondContainer = ({ width }) => {
  return (
    <S.Container>
      <S.TextContainer>
        <S.SubTitleContainer>
          <S.SubTitleWrapper>
            Sim! Inauguramos no Brasil, em 2017, um conceito inédito, que
            mistura lazer, aventura e atividade física! Desde então, nossa
            missão é levar diversão para a vida de todas as pessoas.
            <br /> Todas mesmo: crianças, famílias, amigos... Demais, né? São
            dois endereços em São Paulo, um em Santo Amaro, na Zona Sul, e outro
            no Tatuapé, na Zona Leste.
          </S.SubTitleWrapper>
        </S.SubTitleContainer>
      </S.TextContainer>
      <S.IconContainer className="col-sm-12 col-md-6">
        <img
          src={aboutFirstIcon}
          alt="Price Screen Icon"
          width={width > 1200 ? 500 : 250}
        />
      </S.IconContainer>
    </S.Container>
  );
};

export default SecondContainer;
