import styled from "styled-components";

const Container = styled.div`
  background-color: #f47d41;
  border: 1px solid #f47d41;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 60px;

  @media (min-width: 0px) and (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const TextWrapper = styled.div`
  color: white;
  font-size: 2.2em;
  font-weight: 700;

  @media (min-width: 0px) and (max-width: 700px) {
    font-size: 26px;
    text-align: center;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: 0px) and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
  }
`;

const Card = styled.div`
  width: 252px;
  height: 212px;
  border: 1px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;

  @media (min-width: 0px) and (max-width: 700px) {
    margin-top: 10px;
    height: auto;
    width: 90%;
    flex-direction: row;
    padding: 5px;
    text-align: left;
  }
`;

const CardTextWrapper = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.24px;

  @media (min-width: 0px) and (max-width: 700px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export { Container, TextWrapper, Card, CardContainer, CardTextWrapper };
