import { Modal, Button } from "react-bootstrap";
import BootstrapCarousel from "../../../../components/BootstrapCarousel";
import { useState } from "react";
import { TfiClose } from "react-icons/tfi";

const PromotionsCarouselModal = ({ open, content }) => {
  const [show, setShow] = useState(true);

  if (!open || !content || !content[0]) return <></>;

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} size="xl">
        <Button
          className="align-self-center rounded-circle text-white"
          style={{ position: "absolute", right: 10, top: 10, zIndex: 9999 }}
          onClick={handleClose}
        >
          <TfiClose />
        </Button>
        <BootstrapCarousel images={content} />
      </Modal>
    </>
  );
};

export default PromotionsCarouselModal;
