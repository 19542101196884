import styled from "styled-components";

const Container = styled.div`
  background-color: #32badb;
  border: 1px solid #32badb;
  border-radius: 20px 20px 0px 0px;
  margin: -20px 0px 0px 0px;
  display: flex;
  ${({ mobile }) =>
    mobile
      ? "padding: 50px 0px 20px 0px; flex-direction: column-reverse;"
      : "padding: 100px 0px 100px 80px;"}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const TitleContainer = styled.div`
  margin-left: 100px;
  width: 100%;

  @media (min-width: 0px) and (max-width: 1200px) {
    margin: 0px;
  }
`;

const TitleWrapper = styled.h2`
  color: white;
  font-size: 40px;
  font-weight: 700;
  line-height: 42.2px;

  @media (min-width: 0px) and (max-width: 1200px) {
    font-size: 26px;
    text-align: center;
  }
`;

const SubTitleContainer = styled.div`
  margin-left: 100px;
  margin-top: 10px;

  @media (min-width: 0px) and (max-width: 1200px) {
    margin-left: 20px;
  }
`;

const SubTextWrapper = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.6px;

  @media (min-width: 0px) and (max-width: 1300px) {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Container,
  TextContainer,
  TitleContainer,
  SubTitleContainer,
  TitleWrapper,
  SubTextWrapper,
  IconContainer,
};
