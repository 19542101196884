import React, { useState } from "react";
import Header from "../../components/Header";
import { dataContact } from "../../utils/dataContact";

export const InformationPage = () => {
  console.log("bom dia");

  const [branchMenu, setBranchMenu] = useState("SANTO_AMARO");

  const branches = dataContact;

  const getSocialMedia = () => {
    let socialMedia = [
      branches[branchMenu].instagram,
      branches[branchMenu].facebook,
      branches[branchMenu].youtube,
    ];

    return socialMedia.map((sm) => {
      if (!sm) return null;
      return (
        <div
          className="IconWrapper"
          onClick={() => window.open(sm.url, "_blank")}
        >
          <img src={sm.image} alt="Icon" />
        </div>
      );
    });
  };

  return (
    <div className="Contact__container">
      <Header backgroundColor={"transparent"} />
      <h1 className="d-flex justify-content-center gap-5 text-white mt-4">
        PopHaus Brasília
      </h1>
      <div
        className="d-flex flex-column justify-content-evenly bg-primary mt-4"
        style={{ height: "100%" }}
      >
        <div className="d-flex justify-content-center w-100 text-white">
          <h5 className="w-50">
            Caros clientes, <br /><br />
            Informamos que a unidade do PopHaus em Brasília encerrou suas
            atividades em 06/11/2023. Em caso de dúvidas, entre em contato pelos
            nossos canais oficiais.
            <br /><br /> Atenciosamente, Equipe PopHaus
          </h5>
        </div>
        <div className="contact__SocialMidia bg-primary">
          <h4 className="text-white">Encontre o Pop</h4>
          <div className="d-flex">{getSocialMedia()}</div>
        </div>
      </div>
    </div>
  );
};
