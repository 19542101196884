import styled from "styled-components";

const Container = styled.div`
  background-color: #fdc028;
  height: 578px;
  margin: 0px;
  display: flex;
  padding-bottom: 50px;

  @media (min-width: 0px) and (max-width: 800px) {
    flex-direction: column-reverse;

    height: 80vh;
    min-height: 700px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  @media (min-width: 0px) and (max-width: 800px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const TitleContainer = styled.div`
  margin-left: 100px;
  width: 100%;

  @media (min-width: 0px) and (max-width: 800px) {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TitleWrapper = styled.h1`
  color: #6f3b8c;
  font-size: 2.5em;
  font-weight: 700;
  line-height: 42.2px;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 26px;
    margin-left: 0px;
  }

  @media (min-width: 1500px) and (max-width: 999999px) {
    font-size: 3em;
    margin-left: 0px;
  }
`;

const SubTitleContainer = styled.div`
  margin-left: 100px;
  margin-top: 5px;
  max-width: 500px;

  @media (min-width: 0px) and (max-width: 600px) {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const SubTextWrapper = styled.div`
  color: #6f3b8c;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 23.6px;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 16px;
    text-align: center;
    max-width: 300px;
  }

  @media (min-width: 1500px) and (max-width: 999999px) {
    font-size: 1.5em;
    margin-left: 0px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (min-width: 0px) and (max-width: 800px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export {
  Container,
  TextContainer,
  TitleContainer,
  SubTitleContainer,
  TitleWrapper,
  SubTextWrapper,
  IconContainer,
};
