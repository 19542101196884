import styled from "styled-components";
import { darken } from "polished";
import media from "styled-media-query";
export const Container = styled.div`
  margin: 0px;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 650px;
  background-color: #6f3b8c;
  width: 100%;
`;

export const SubContainer = styled.div`
  background-color: #6f3b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6rem;
  gap: 2rem;
  div:first-child {
    font-weight: 700;
    color: #F8C70E;
  }
`

export const ContainerWrapper = styled.div`
  margin-top: 1rem;
  background-color: #6f3b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.lessThan('large')`
    .row_dates{
      display: flex;
      justify-content: center;

      flex-direction: column;
    }
  `}
  .select{
    width: 22rem;
    background-color: ${darken(0.1, '#6f3b8c')};
    border: none;
    border-radius: 50px;
    height: 3.5rem;
    outline: none;
    color: #fff;
    font-size: 1rem;
    &:focus{
      outline: none;
      border: none;
    }
  }
  .wrapper_buttons{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    .btn_1{
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      span{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
      color: #fff;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 42px;
      text-align: center
    }
    .btn_2{
      margin-top: 0.8rem;
      width: 225px;
      height: 40px;
      border-radius: 25px;
      background-color: #83E193;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 42px;
      text-align: center;
      outline: none;
      border: none;
      opacity: 0.8;
      transition: 0.3s;
      margin-bottom: 0.5rem;
      color: #fff;
      &:hover{
        opacity: 1;
      }
    }
  }
`;

export const Input = styled.input`
  width: 22rem;
  background-color: ${darken(0.1, '#6f3b8c')};
  border: none;
  border-radius: 50px;
  height: 3.5rem;
  outline: none;
  color: #fff;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;


const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;

  @media (min-width: 0px) and (max-width: 800px) {
    justify-content: flex-end;
    margin-top: 0px;
    height: 80%;
    padding-bottom: 30px;
  }
`;

const TitleWrapper = styled.h1`
  color: white;
  font-weight: 700;
  margin-top: 0px;
  text-align: center;
  max-width: 700px;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 26px;
    max-width: 200px;
  }
  @media (min-width: 801px) and (max-width: 99999px) {
    font-size: 64px;
    line-height: 75px;
  }
`;

const InputContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;

  @media (min-width: 0px) and (max-width: 800px) {
    flex-direction: column;
    text-align: center;
  }
  @media (min-width: 801px) and (max-width: 99999px) {
    flex-direction: row;
  }
`;


const InputWrapper = styled.div`
  width: 22rem;
  height: 3.5rem;
  cursor: pointer;
  border: 1px solid ${darken(0.1, '#6f3b8c')};
  border-radius: 50px;
  padding: 10px;
  background-color: ${darken(0.1, '#6f3b8c')};
  margin-left: 10px;
  display: flex;
  align-items: center;
  @media (min-width: 0px) and (max-width: 800px) {
    margin-top: 10px;
  }
`;
const IconWrapper = styled.div`
  background-color: ${({ disabled }) => (disabled ? "#877342" : "#fdc028")};
  border: 1px solid ${({ disabled }) => (disabled ? "#877342" : "#fdc028")};
  border-radius: 10px;
  width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-itens: center;
`;

const DropDownSelector = styled.select`
  color: ${({ disabled }) => (disabled ? "#877342" : "#fdc028")};
  background-color: transparent;
  font-size: 14px;
  font-weight: 800;
  border: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin-left: -40px;
  padding-left: 50px;
`;

const DropDownOption = styled.option`
  color: #fdc028;
  background-color: ${darken(0.1, '#6f3b8c')};
  font-size: 1rem;
  font-weight: 600;
`;

const InputPlaceholder = styled.option`
  color: #fdc028;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 8px;
  margin-top: 1px;
`;

const CalendarContainer = styled.div`
  .react-date-picker__calendar {
    z-index: 10 !important;
  }

  .react-date-picker__calendar-button {
    margin-left: -6px;
  }

  .react-date-picker__wrapper {
    border: none !important;
    align-items: center;
    flex-direction: ${(props) => (props.hideInput ? "row" : "row-reverse")};
  }
  .react-date-picker__wrapper button svg:nth-child(1) {
    stroke: #fff;
  }

  .react-calendar__navigation button[disabled] {
    background-color: #8e47af !important;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #8e47af !important;
  }
  ::placeholder {
    color: #fff;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    width: 2em;
    height: 2em;
  }

  .ticket-date {
    border-radius: 10px;
    background-color:  ${darken(0.1, '#6f3b8c')};
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    max-width: 276px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 16px;
  }

  .react-calendar {
    width: 94% !important;
    margin: 0 3%;
    padding-bottom: 1rem;
    background-color: #8e47af !important;
    border: none !important;
    border-radius: 15px;
  }

  .teste2,
  .react-calendar__month-view__days__day--weekend,
  .react-calendar__month-view__days__day {
    color: #fff !important;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #fff !important;
    font-family: "Poppins";
    font-size: 14px;
    opacity: 0.36;
    text-decoration: none !important;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
  }

  .react-calendar__tile--now {
    background: none !important;
  }

  .react-calendar__tile--active {
    background-color: #753593 !important;
    border-radius: 14px;
  }

  .react-calendar__tile--active:enabled:hover {
    background: #753593 !important;
    border-radius: 11px;
  }
  .react-calendar__navigation__label {
    color: #fff;
    text-transform: capitalize;
  }
  .react-calendar__tile:hover {
    background-color: #753593 !important;
  }

  input {
    color: #fdc028 !important;
  }
  .react-date-picker__wrapper button svg:nth-child(1) {
    stroke: #fff;
  }

  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    background-color: #753593 !important;
    border-radius: 11px;
    margin: 3% 3% !important;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 35px !important;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }

  .select-calendar-hour {
    display: block;
  }

  .react-calendar__navigation__label__labelText--from {
    content: "Ablabla";
  }

  .react-calendar__navigation__label__labelText--from {
    content: "Ablabla";
  }

  .react-date-picker__inputGroup {
    font-size: 14px;
    font-weight: bold;
    color: #fdc028;
    height: min-content;
    margin-left: 6px;
    margin-top: 2px;
    display: ${(props) => (props.hideInput ? "none" : "flex")};
  }
  .react-calendar__tile:disabled {
    background-color: transparent !important;
    color: #c5c5c58a !important;
    border-radius: 15px;
  }

  .react-date-picker__wrapper button svg:nth-child(1) {
    stroke: #fff;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    border-radius: 15px;
  }
`;

const Button = styled.button`
  width: 100%;
  max-width: 303px;
  height: 47px;
  background-color: #fdc028;
  border: 1px solid #fdc028;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f3b8c;
  font-weight: 700;
  font-size: 14px;
  margin-top: 20px;
`;

const TextWrapper = styled.div`
  color: ${({ color }) => (color ? color : "white")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  margin-left: 2px;
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? textDecoration : ""};

  @media (min-width: 0px) and (max-width: 800px) {
    max-width: 300px;
    font-size: 12px;
  }
`;

export {
  InputWrapper,
  InputContainer,
  IconWrapper,
  DropDownSelector,
  DropDownOption,
  Button,
  TextWrapper,
  CenterContainer,
  TitleWrapper,
  CalendarContainer,
  InputPlaceholder,
};
export const TextNotify = styled.span`
font-weight: bold;
color: #6f3b8c;

`