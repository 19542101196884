import React, { useState } from "react";
import * as S from "./styles";
import logoFooter from "../../assets/logoFooter.svg";
import FooterMenu from "../FooterMenu";
import { useNavigate } from "react-router-dom";
import { dataContact } from "../../utils/dataContact";

const DesktopFooter = () => {
  const branches = dataContact
  const navigate = useNavigate();
  const [branchMenu, setBranchMenu] = useState("SANTO_AMARO");

  const getSocialMedia = () => {
    let socialMedia = [
      branches[branchMenu].instagram,
      branches[branchMenu].facebook,
      branches[branchMenu].youtube,
    ];

    return socialMedia.map((sm) => {
      if (!sm) return null;
      return (
        <S.IconWrapper onClick={() => window.open(sm.url, "_blank")}>
          <img src={sm.image} alt="Icon" />
        </S.IconWrapper>
      );
    });
  };

  return (
    <S.Container className="row d-flex">
      <S.LogoWrapper className="col-sm-2" onClick={() => navigate("/")}>
        <img
          src={logoFooter}
          alt="Logo"
          style={{ marginLeft: 20 }}
          width={200}
        />
      </S.LogoWrapper>
      <div className="col-sm-8">
        <FooterMenu
          branchMenu={branchMenu}
          setBranchMenu={setBranchMenu}
          branches={branches}
        />
      </div>
      <div className="col-sm-2 d-flex flex-column gap-2">
        <S.ColumnTitle className="mb-2">Encontre o Pop</S.ColumnTitle>
        <S.IconContainer>{getSocialMedia()}</S.IconContainer>
        <div>
          <S.ColumnItem fontSize={"0.8em"} fontWeight={500} cursor={" "}>
            Contato
          </S.ColumnItem>
          <S.ColumnItem
            fontSize={"0.8em"}
            fontWeight={400}
            marginTop={"0px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location.href = `tel:${branches[branchMenu].phone_number}`;
            }}
          >
            Telefone: {branches[branchMenu].phone_number}
          </S.ColumnItem>
        </div>
      </div>
    </S.Container>
  );
};

export default DesktopFooter;
