import { v4 as uuidv4 } from "uuid";
import { Table as RBTable } from "react-bootstrap";
import { useRef } from "react";

const PricesTables = ({ list, className, children }) => {
  if (!list) list = [];
  const tableRef = useRef();
  const tableID = uuidv4();

  const renderTable = (tableData, ix) => {
    const renderHeaders = () => {
      if (!tableData.headers || !tableData.headers[0]) return;
      return tableData.headers.map((header) => {
        const id = `${tableID}_${uuidv4()}`;

        return (
          <th
            key={`header_item_${id}`}
            className={`bg-transparent text-center py-1 border rounded-2 fw-bold ${
              tableData.headersClassName || "border-primary text-primary"
            }`}
            style={{ verticalAlign: "middle" }}
          >
            {header}
          </th>
        );
      });
    };

    const renderPrices = () => {
      if (!tableData.prices || !tableData.prices[0]) return;

      return tableData.prices.map((price, pix) => {
        return (
          <tr key={`${tableID}_price_item_${price.id}`}>
            {tableData.verticalHeaders[pix] && (
              <td
                className={`bg-transparent text-center py-1 border rounded-2 fw-bold ${
                  tableData.headersClassName || "border-primary text-primary"
                }`}
                style={{ verticalAlign: "middle" }}
              >
                {tableData.verticalHeaders[pix]}
              </td>
            )}
            {price.a >= 0 && (
              <td
                className={`text-center py-1 border rounded-2 fw-bold ${
                  tableData.pricesClassName ||
                  "border-primary bg-primary text-white"
                } border-0`}
                style={{ verticalAlign: "middle" }}
              >
                {price.a.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            )}
            {price.b >= 0 && (
              <td
                className={`text-center py-1 border rounded-2 fw-bold ${
                  tableData.pricesClassName ||
                  "border-primary bg-primary text-white"
                } border-0`}
                style={{ verticalAlign: "middle" }}
              >
                {price.b.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            )}
            {price.c >= 0 && (
              <td
                className={`text-center py-1 border rounded-2 fw-bold ${
                  tableData.pricesClassName ||
                  "border-primary bg-primary text-white"
                } border-0`}
                style={{ verticalAlign: "middle" }}
              >
                {price.c.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            )}
          </tr>
        );
      });
    };

    return (
      <div
        key={`${tableID}_table_item_${tableData.id}`}
        className="col-xs-12 col-lg-6"
      >
        <div
          className={`border border-2 rounded-4 p-2 d-flex gap-2 flex-column h-100 ${
            tableData.className || "text-primary border-primary"
          }`}
        >
          <span className="fw-bolder fs-4">{tableData.title}</span>
          <span className="fw-bold">{tableData.description}</span>
          <div
            className="d-flex flex-column gap-2"
            style={{ maxWidth: "100%", overflow: "auto" }}
          >
            <RBTable responsive ref={tableRef} borderless>
              <thead>
                <tr>{renderHeaders()}</tr>
              </thead>
              <tbody>{renderPrices()}</tbody>
            </RBTable>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`w-100 rounded-top-4 ${className || "bg-secondary"}`}>
      <div className="d-none d-md-block container">
        <div className="d-flex gap-3 p-0 p-md-4 p-lg-5">
          <div className="row w-100 g-2 p-0 m-0 justify-content-center">
            {list.map(renderTable)}
          </div>
        </div>
        {children}
      </div>
      <div className="d-md-none">
        <div className="d-flex gap-3 p-0 p-md-4 p-lg-5">
          <div className="row w-100 g-2 p-0 m-0 justify-content-center">
            {list.map(renderTable)}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PricesTables;
