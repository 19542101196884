import React, { useEffect, useState } from "react";
import Carousel from "../../../external/react-elastic-carousel";
import leftArrow from "../../../assets/leftArrow.svg";
import rightArrow from "../../../assets/rightArrow.svg";
import Item from "./Item";
import moment from "moment";

const ParkAreaAttractionsCarousel = ({
  list = [],
  modal = false,
  onItemClick,
  startIndex = 0,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === "PREV" ? leftArrow : rightArrow;
    if (isEdge) return <></>;
    if (modal && windowWidth <= 850) {
      if (type === "PREV")
        return (
          <button
            onClick={onClick}
            disabled={isEdge}
            style={{
              width: 50,
              height: 50,
              borderTopRightRadius: "50%",
              backgroundColor: "#6F3B8C",
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
            className="d-flex align-items-center justify-content-center border-0"
          >
            <img
              alt="arrow"
              src={pointer}
              width={50}
              height={50}
              style={{ filter: "invert(1)" }}
            />
          </button>
        );
      return (
        <button
          onClick={onClick}
          disabled={isEdge}
          style={{
            width: 50,
            height: 50,
            borderTopLeftRadius: "50%",
            backgroundColor: "#6F3B8C",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
          className="d-flex align-items-center justify-content-center border-0"
        >
          <img
            alt="arrow"
            src={pointer}
            width={50}
            height={50}
            style={{ filter: "invert(1)" }}
          />
        </button>
      );
    }
    return (
      <div className="h-100 d-flex align-items-center">
        <button
          onClick={onClick}
          disabled={isEdge}
          style={{
            width: 50,
            height: 50,
            borderRadius: "50%",
            backgroundColor: "#6F3B8C",
          }}
          className="d-flex align-items-center justify-content-center border-0"
        >
          <img
            alt="arrow"
            src={pointer}
            width={50}
            height={50}
            style={{ filter: "invert(1)" }}
          />
        </button>
      </div>
    );
  };

  return (
    <Carousel
      breakPoints={[
        { width: 0, itemsToShow: 1 },
        { width: 500, itemsToShow: modal ? 1 : 2 },
        { width: 800, itemsToShow: modal ? 2 : 3 },
        { width: 1100, itemsToShow: modal ? 3 : 4 },
        { width: 1300, itemsToShow: modal ? 3 : 5 },
        { width: 1500, itemsToShow: modal ? 4 : 6 },
        { width: 1800, itemsToShow: modal ? 5 : 7 },
      ]}
      renderArrow={myArrow}
      initialActiveIndex={startIndex}
    >
      {list?.map((item, index) => (
        <Item
          key={"carousel_item_" + (item.id || moment().valueOf())}
          item={item}
          modal={modal}
          onItemClick={onItemClick}
          index={index}
        />
      ))}
    </Carousel>
  );
};

export default ParkAreaAttractionsCarousel;
