import React from "react";

import { getWindowDimensions } from "../../../services/getWindowDimensions";

const Item = ({ item = {}, modal = false, onItemClick, index }) => {
  const handleItemClick = () => {
    if (onItemClick) onItemClick(index);
  };

  const { width, height } = getWindowDimensions();

  return (
    <div
      className="d-flex align-items-end"
      style={{
        width: modal ? (width < height ? "75vw" : "calc(75vh * 0.5)") : 200,
        height: modal ? (width < height ? "calc(75vw / 0.5)" : "75vh") : 400,
        backgroundImage: `url(${item.image})`,
        backgroundPosition: "center",
        borderRadius: 20,
        cursor: onItemClick ? "pointer" : "grab",
        backgroundSize: "cover",
        maxHeight: height - 50,
        maxWidth: width - 50,
      }}
      onClick={handleItemClick}
    >
      <div
        style={{
          background: `linear-gradient(0deg, #6F3B8CCC 80%, transparent 100%)`,
          borderRadius: 20,
        }}
        className="w-100 d-flex flex-column text-white align-items-center justify-content-end text-center user-select-none px-2 py-4"
      >
        <strong style={{ zIndex: 2, marginTop: 40, fontSize: 14 }}>
          {item.title}
        </strong>
        <span style={{ zIndex: 2, fontSize: 14 }}>{item.description}</span>
      </div>
    </div>
  );
};

export default Item;
