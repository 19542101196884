import styled from "styled-components";

const Card = styled.div`
  ${({ onClick }) =>
    onClick
      ? `
  &:hover {
    background-color: #0001 !important;
    border-radius: 10px;
  }
`
      : ""}
`;

export { Card };
