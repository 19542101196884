import React, { useState, useContext } from "react";
import { NavigationContext } from "../../../context/navigationContext";
import * as S from "./styles";

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const navigation = useContext(NavigationContext).changeMobileMenu;

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <S.SidebarLink
        to={item.url}
        onClick={() =>
          item.submenu ? showSubnav() : navigation.changeMobileMenu()
        }
      >
        <div>
          <S.SidebarLabel>{item.title}</S.SidebarLabel>
        </div>
        <div>
          {item.submenu && subnav
            ? item.iconOpened
            : item.submenu
            ? item.iconClosed
            : null}
        </div>
      </S.SidebarLink>
      {subnav &&
        item.submenu.map((item, index) => {
          return (
            <S.DropdownLink
              to={item.url}
              onClick={() => navigation.changeMobileMenu()}
              key={index}
            >
              <S.SidebarLabel>{item.title}</S.SidebarLabel>
            </S.DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
