import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SimulatorHook } from "../../../context/simulatorContext";
import { v4 as uuidv4 } from "uuid";
import { MockedData } from "./data";
import "./styles.scss";
import { FaCalendarAlt, FaClock, FaUsers } from "react-icons/fa";
import { Api } from "../../../api/api";
import { PDFViewer } from "../PDFViewer";
import { gtagPixel } from "../../../utils/util";

export const SelectTypeParty = () => {
  const {
    setCurrentStep,
    unit,
    name,
    lastName,
    phone,
    simulationMetricID,
    setSimulationMetricID,
    email,
    setPlanName,
    setPlanModel,
    duration,
    date,
    kioskTarget,
    doubtsId,
    setDoubtsId,
    regularTickets,
    planName,
    planModel,
    planPrice,
  } = SimulatorHook();
  setCurrentStep(2);

  const storedUserInfo = localStorage.getItem("popHausPartyUserInfo");
  const userInfo = JSON.parse(storedUserInfo);

  const dataPackages = MockedData.units[unit];
  const navigate = useNavigate();

  const handleSelectParty = (party) => {
    createSimulationMetric();
    setPlanModel(party.name);
    setPlanName(party.id);
    navigate("/NewSimulator/FinalOrderPage");
    userInfo.typePartyId = party.id;
    localStorage.setItem("popHausPartyUserInfo", JSON.stringify(userInfo));

    gtagPixel("gtm.click", {
      send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
    });

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", `FestaPacote`);
      }
    }

    Api.post(`website_clicks`, { clicked_id: party.click_id });
  };

  const createSimulationMetric = (callback) => {
    if (!simulationMetricID)
      return Api.post(`simulations_metrics`, { email, unit }).then((res) => {
        setSimulationMetricID(res?.data?.data?.id);
        if (callback) callback();
      });
    Api.put(`simulations_metrics/${simulationMetricID}`, { email, unit }).then(
      () => {
        if (callback) callback();
      }
    );
  };

  if (unit === "") {
    navigate("/");
  }

  function handleCreateParty({ answer, doubts = false, editDoubts = false }) {
    const obj = {
      filial: unit,
      spaces: [
        {
          value: "PopIndoor",
          label: "PopIndoor",
        },
        {
          value: "PopOutdoor",
          label: "PopOutdoor",
        },
      ],
      duration: 3,
      regular: regularTickets,
      plus_one: 0,
      kids: 0,
      kids_plus_one: 0,
      pcd: 0,
      pcd_plus_one: 0,
      date: date,
      time: "Manha",
      buffet: true,
      menu_1: false,
      menu_2: false,
      menu_3: false,
      menu_4: false,
      common_sock: 0,
      non_slip_sock: 0,
      cup: 0,
      towel: 0,
      cabinet_rental: 0,
      name: name,
      last_name: lastName,
      email: email,
      phone: phone,
      cotton_candy: 0,
      cotton_candy_machine: 0,
      exclusive_monitor: 0,
      kiosks_target: {
        label: "Sem Quiosque",
        value: "kiosk_0",
        price: 0,
      },
      scheduled: answer,
      doubts: doubts,
      crm: false,
      scheduled_by: "",
      price_total: 0,
      total_number_of_guests: 0,
      additional_info: "",
      created_at: "",
      updated_at: "",
      model: "single",
      duration_kiosk: 3,
      bundle: {
        id: "p",
        name: planName,
        model: planModel,
        price: planPrice,
        event_duration: duration,
        players_min: 0,
        non_players_min: 0,
        players_max: 0,
        kiosks: {
          amount: 1,
          label: kioskTarget,
          value: "kiosk_1",
        },
      },
    };
    if (editDoubts) {
      const response = Api.put(`/party_simulator/update_info/${doubtsId}`, obj)
        .then((res) => {
          if (answer) {
            navigate("/NewSimulator/ConfirmationContainer");
          }
          if (!answer && doubts === false) {
            if (unit === "SANTO_AMARO") {
              window.open("https://api.whatsapp.com/send?phone=5511917706110");
            } else if (unit === "BRASILIA") {
              window.open("https://api.whatsapp.com/send?phone=5561998755443");
            } else if (unit === "TATUAPE") {
              window.open("https://api.whatsapp.com/send?phone=5511917706110");
            } else {
              window.open("https://api.whatsapp.com/send?phone=5511917706110");
            }
          }
          return res;
        })
        .catch(() => {
          window.alert("Err");
        });
      return response;
    } else {
      const response = Api.post("/party_simulator/create_party", obj)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          window.alert(err);
        });
      return response;
    }
  }

  useEffect(() => {
    if (!doubtsId)
      handleCreateParty({ answer: false, doubts: true }).then((res) =>
        setDoubtsId(res.data.id)
      );
  }, []);

  return (
    <div className="secondStep__container">
      {dataPackages.map((partyPackage) => (
        <div key={uuidv4()} className="d-flex flex-column">
          <div></div>
          <div className="secondStep__box">
            <h5 className="card-title text-center text-primary text-wrap mb-3">
              {partyPackage.name}
            </h5>
            <div className="secondStep__description">
              {partyPackage.description}
            </div>

            <div className="d-flex align-items-start justify-content-end flex-column gap-2"></div>
            <hr />
            <PDFViewer
              pdf={partyPackage.pdf}
              PDFName={partyPackage.name}
              handleSelectParty={() => handleSelectParty(partyPackage)}
              click_id={partyPackage.click_id}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
