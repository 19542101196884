import { getWindowDimensions } from "../../../services/getWindowDimensions";

import alfaOmegaDesk from "../../../assets/images/desk/diaFeliz/instituicoes/alfaOmega.webp";
import alfaOmegaMob from "../../../assets/images/mob/diaFeliz/instituicoes/alfaOmega.webp";

import kairosDesk from "../../../assets/images/desk/diaFeliz/instituicoes/kairos.webp";
import kairosMob from "../../../assets/images/mob/diaFeliz/instituicoes/kairos.webp";

import trilheirosAmorDesk from "../../../assets/images/desk/diaFeliz/instituicoes/trilheirosAmor.webp";
// import trilheirosAmorMob from "../../../assets/images/mob/diaFeliz/instituicoes/trilheirosAmor.webp";

import confeiteirosMirinsDesk from "../../../assets/images/desk/diaFeliz/instituicoes/confeiteirosMirins.webp";
import confeiteirosMirinsMob from "../../../assets/images/mob/diaFeliz/instituicoes/confeiteirosMirins.webp";

export const objectMapper = {
  Main: {
    Atracoes: {
      "Alfa e Ômega": {
        Name: "Alfa e Ômega",
        Image: alfaOmegaDesk,
        BackgroundImage: alfaOmegaDesk,
        Toys: [
          {
            Name: "Alfa e Ômega",
            Description:
              "“Essa experiência nos impactou de maneira ímpar! A realidade da maioria dos jovens que foram é duríssima, e ver o sorriso de poderem se divertir não tem preço. Nosso grupo também acolhe alguns adolescentes em processo terapêutico, e esse tempo foi um alívio, um escape. Muito obrigada!”",
            Image:
              getWindowDimensions().width > 1300 ? alfaOmegaDesk : alfaOmegaMob,
          },
        ],
      },
      Kairós: {
        Name: "Kairós",
        Image: kairosDesk,
        BackgroundImage: kairosDesk,
        Toys: [
          {
            Name: "Kairós",
            Description:
              "“Eu amei o passeio e foi muito legal! Obrigado aos tios e tias que cuidaram de nós. Eu agradeço por tudo e quero voltar outras vezes.”",
            Image: getWindowDimensions().width > 1300 ? kairosDesk : kairosMob,
          },
        ],
      },
      "Trilheiros do Amor": {
        Name: "Trilheiros do Amor",
        Image: trilheirosAmorDesk,
        BackgroundImage: trilheirosAmorDesk,
        Toys: [
          {
            Name: "Trilheiros do Amor",
            Description:
              "“Foi uma experiência incrível, alegre, que transbordou através de lágrimas e sorrisos, dos voluntários e colaboradores. Uma visita recheada de lindas surpresas, com uma sensibilidade encantadora de todos os envolvidos! Gratidão por tudo que fizeram aos nossos pequenos!”",
            Image:
              getWindowDimensions().width > 1300
                ? trilheirosAmorDesk
                : trilheirosAmorDesk,
          },
        ],
      },
      "Confeiteiros Mirins": {
        Name: "Confeiteiros Mirins",
        Image: confeiteirosMirinsDesk,
        BackgroundImage: confeiteirosMirinsDesk,
        Toys: [
          {
            Name: "Confeiteiros Mirins",
            Description:
              "“Foi tudo maravilhoso! Agradeço a todos pela recepção e oportunidade. As crianças ficaram tão animadas com o dia que criamos até um grito de guerra para o PopHaus!”",
            Image:
              getWindowDimensions().width > 1300
                ? confeiteirosMirinsDesk
                : confeiteirosMirinsMob,
          },
        ],
      },
    },
  },
};
