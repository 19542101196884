import styled from "styled-components";
import {darken} from "polished";
import media from "styled-media-query";
export const Container = styled.div`
  margin: 0px;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 650px;
  background-color: #6f3b8c;
`;

export const SubContainer = styled.div`
  background-color: #6f3b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6rem;
  gap: 2rem;
  div:first-child {
    font-weight: 700;
    color: #F8C70E;
  }
`

export const ContainerWrapper = styled.div`
  margin-top: 1rem;
  background-color: #6f3b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .input_step{
    width: 22rem;
    background-color: ${darken(0.1,'#6f3b8c')};
    border: none;
    border-radius: 50px;
    height: 3.5rem;
    outline: none;
    color: #fff;
    font-size: 1rem;
    &:focus{
      outline: none;
      border: none;
    }
  }
  .button{
    margin-top: 0.8rem;
    width: 225px;
    height: 45px;
    border-radius: 25px;
    background-color: #83E193;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    outline: none;
    border: none;
    opacity: 0.8;
    transition: 0.3s;
    color: #fff;
    &:hover{
      opacity: 1;
    }
  }
  h3{
    font-size: 1.8rem;
    font-weight: 700;
    color:  #F8C70E;
    padding-bottom: 0.8rem;
    ${media.lessThan('large')}{
      font-size: 1.5rem;
      padding-left: 1rem;
      text-align: center;
    }
  }
  h2{
    color: #F8C70E;
    padding-bottom: 0.8rem;
    text-decoration: underline;
    ${media.lessThan('large')}{
      padding-left: 1.2rem;
      font-size: 1.3rem;
      text-align: center;
    }
  }
  p{
    font-weight: 700;
    color: #fff;
    padding-bottom: 0.8rem;
    p:last-child{
      margin-left: 1.2rem;
    }
    ${media.lessThan('large')}{
      text-align: center;
    }
  }
`