import React, { useState } from "react";
import { useEffect } from "react";
import { SimulatorHook } from "../../../context/simulatorContext";

const bundles = [
  {
    id: "p",
    name: "Pacote P",
    event_duration: 3,
    players_min: 10,
    non_players_min: 5,
    players_max: 24,
    kiosks: { amount: 1, label: "1 Quiosque", value: "kiosk_1" },
  },
  {
    id: "m",
    name: "Pacote M",
    event_duration: 3,
    players_min: 25,
    non_players_min: 10,
    players_max: 40,
    kiosks: { amount: 1, label: "1 Quiosque", value: "kiosk_1" },
    souvenirs: 20,
  },
  {
    id: "g",
    name: "Pacote G",
    event_duration: 3,
    players_min: 41,
    non_players_min: 15,
    kiosks: { amount: 2, label: "2 Quiosques", value: "kiosk_2" },
    souvenirs: 30,
  },
];

const Bundles = ({ onBundleSelected, value }) => {
  const [showing, setShowing] = useState({ p: true, m: false, g: false });
  const [selected, setSelected] = useState(value || {});
  const {
    regularTickets,
    setRegularTickets,
    plusOneTickets,
    setPlusOneTickets,
    bundle,
  } = SimulatorHook();

  const handleSelect = (bundle) => {
    onBundleSelected(bundle);
    setSelected(bundle);
  };

  useEffect(() => {
    if (!bundle) {
      handleSelect(bundles[0]);
      setRegularTickets(bundles[0].players_min);
      setPlusOneTickets(bundles[0].non_players_min);
    }
  }, [bundle]);

  useEffect(() => {
    setShowing({
      p: selected.id === "p" ? true : false,
      m: selected.id === "m" ? true : false,
      g: selected.id === "g" ? true : false,
    });
  }, [selected]);

  const setBundleByPlayers = () => {
    if (regularTickets < 24) return handleSelect(bundles[0]);
    if (regularTickets < 30) {
      if (plusOneTickets < bundles[1].non_players_min)
        setPlusOneTickets(bundles[1].non_players_min);
      return handleSelect(bundles[1]);
    }
    if (plusOneTickets < bundles[2].non_players_min)
      setPlusOneTickets(bundles[2].non_players_min);
    handleSelect(bundles[2]);
  };

  useEffect(() => {
    if (regularTickets) setBundleByPlayers();
  }, [regularTickets]);

  const renderBundleCard = (bundle) => {
    return (
      <div
        key={`bundle_${bundle.name}`}
        className="d-flex flex-column rounded align-items-center p-2"
        style={{
          backgroundColor: "white",
          color: "#6f3b8c",
          cursor: "pointer",
          boxShadow:
            bundle.name === selected.name ? "inset 0 0 0 0.3rem #F8C70E" : null,
          minWidth: 300,
        }}
        onClick={() => {
          handleSelect(bundle);
          setRegularTickets(bundle.players_min);
          setPlusOneTickets(bundle.non_players_min);
        }}
      >
        <div className="position-relative d-flex justify-content-between w-100 align-items-center">
            {selected.name === bundle.name && (
              <div
                style={{
                  position: "absolute",
                  right: 10,
                  top: -37,
                  backgroundColor: "#ed5046",
                }}
                className="rounded shadow-sm p-2 fw-bold text-white text-uppercase"
              >
                Melhor preço
              </div>
            )}
          <h5 style={{ fontWeight: "bolder" }} className="m-0">
            {bundle.name}
          </h5>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowing((prev) => ({
                ...prev,
                [bundle.id]: !prev[bundle.id],
              }));
            }}
            style={{ color: "#6f3b8c" }}
            className="btn bg-transparent d-flex align-items-center"
          >
            {showing[bundle.id] ? <>&#9650;</> : <>&#9660;</>}
          </button>
        </div>
        {showing[bundle.id] && (
          <div className="d-flex flex-column w-100">
            <strong>Duração do evento</strong>
            <span>{`${bundle.event_duration} horas`}</span>
            <strong>Número de convidados</strong>
            <span>{`${bundle.players_min + bundle.non_players_min} (sendo ${
              bundle.players_min
            } brincantes)`}</span>
            <strong>Quiosque privativo</strong>
            <span>{`${bundle.kiosks.amount} durante todo o evento`}</span>
            <strong>Decoração PopHaus</strong>
            <strong>Buffet da casa</strong>
            {bundle.souvenirs && (
              <strong>{`${bundle.souvenirs} lembrancinhas`}</strong>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="d-flex flex-column">
      <h4 className="align-self-center text-white mb-5">Pacotes de Festas</h4>
      <div className="d-flex gap-3 flex-wrap flex-md-nowrap justify-content-center align-items-start">
        {bundles.map((bundle) => renderBundleCard(bundle))}
      </div>
    </div>
  );
};

export default Bundles;
