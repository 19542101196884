import styled from "styled-components";
import { darken } from "polished";
import media from "styled-media-query";
export const Container = styled.div`
  margin: 0px;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 650px;
  background-color: #6f3b8c;
  .wrapper_buttons{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    .btn_1{
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      span{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
      color: #fff;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 42px;
      text-align: center
    }
    .btn_2{
      margin-top: 0.8rem;
      width: 225px;
      height: 40px;
      border-radius: 25px;
      background-color: #83E193;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 42px;
      text-align: center;
      outline: none;
      border: none;
      opacity: 0.8;
      transition: 0.3s;
      margin-bottom: 0.5rem;
      color: #fff;
      &:hover{
        opacity: 1;
      }
    }
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  h1 {
    margin:0 0 26px 0;
    color: #F8C70E;
    font-size: 3.6rem;
    @media(max-width: 700px) {
    font-size: 2rem;
  }
  }
`

export const ContainerWrapper = styled.div`
  margin-top: 1rem;
  background-color: #6f3b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  .check_container{
    margin-left: 3.7rem;
    ${media.lessThan('medium')}{
      margin-left: 0;
    }
  }
  .check_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    padding-top: 0.8rem;
    gap: 0.5rem;
    ${media.lessThan('medium')}{
      margin-left: 2rem;
      label{
        
      }
    }
    label{
      font-size: 0.8rem;
      font-weight: 600;
      color: #fff;
    }
    input{
      width: 1rem;
      height: 3rem;
    }
  }
  .input_step{
    display: block;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    width: 22rem;
    background-color: ${darken(0.1, '#6f3b8c')};
    border: none;
    border-radius: 50px;
    height: 3.5rem;
    outline: none;
    color: #fff;
    font-size: 1rem;
    &:focus{
      outline: none;
      border: none;
    }
  }
  .button{
    margin-top: 0.8rem;
    width: 225px;
    height: 40px;
    border-radius: 25px;
    background-color: #83E193;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 42px;
    text-align: center;
    outline: none;
    border: none;
    opacity: 0.8;
    transition: 0.3s;
    color: #fff;
    &:hover{
      opacity: 1;
    }
    @media (max-width: 768px) {
    }
  }
`