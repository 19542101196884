export const objectMapper = {
  SantoAmaro: {
    Title: "Santo Amaro",
    Description: "Para se divertir no Pop Haus, você precisa de um <strong>ingresso Brincante</strong>, que dá acesso às atrações das áreas selecionadas pelo tempo escolhido.",
    DuasAreas: {
      DuasHoras: {
        SegundaASexta: "R$ 89",
        FdsEFeriado: "R$ 99",
      },
      TresHoras: {
        SegundaASexta: "R$ 109",
        FdsEFeriado: "R$ 119",
      },
      HoraAdicional: {
        SegundaASexta: "R$ 30",
        FdsEFeriado: "R$ 35",
      },
      DayUse: {
        SegundaASexta: "R$ 149",
        FdsEFeriado: "R$ 189",
      },
    },
    TresAreas: {
      DuasHoras: {
        SegundaASexta: "R$ 109",
        FdsEFeriado: "R$ 119",
      },
      TresHoras: {
        SegundaASexta: "R$ 129",
        FdsEFeriado: "R$ 139",
      },
      HoraAdicional: {
        SegundaASexta: "R$ 30",
        FdsEFeriado: "R$ 35",
      },
    },
    DayUse: {
      SeisHoras: {
        SegundaASexta: "R$ 149",
        FdsEFeriado: "R$ 189",
      },
    },
    NaoBrincante: {
      SegundaASexta: "R$ 30",
    },
  },
  Tatuape: {
    Title: "Tatuapé",
    Description: "Para se divertir no PopHaus Tatuapé, você precisa de um <strong>ingresso brincante</strong>, que dá acesso às atrações das duas áreas pelo tempo escolhido.",
    DuasAreas: {
      UmaHora: {
        SegundaASexta: "R$ 69",
        FdsEFeriado: "R$ 79",
      },
      DuasHoras: {
        SegundaASexta: "R$ 89",
        FdsEFeriado: "R$ 99",
      },
      TresHoras: {
        SegundaASexta: "R$ 109",
        FdsEFeriado: "R$ 119",
      },
      HoraAdicional: {
        SegundaASexta: "R$ 30",
        FdsEFeriado: "R$ 35",
      },
    },
    NaoBrincante: {
      SegundaASexta: "R$ 30",
    },
  },
  Brasilia: {
    Title: "Brasília",
    Description: "Para se divertir no PopHaus Brasília, você precisa de um <strong>ingresso brincante</strong>, que dá acesso à todas as atrações pelo tempo escolhido.",
    DuasAreas: {
      DuasHoras: {
        SegundaASexta: "R$ 89",
        FdsEFeriado: "R$ 99",
      },
      TresHoras: {
        SegundaASexta: "R$ 109",
        FdsEFeriado: "R$ 119",
      },
      SeisHoras: {
        SegundaASexta: "R$ 139",
        FdsEFeriado: "R$ 179",
      },
      HoraAdicional: {
        SegundaASexta: "R$ 24",
        FdsEFeriado: "R$ 27",
      },
    },
    NaoBrincante: {
      SegundaASexta: "R$ 30",
    },
  },
};
