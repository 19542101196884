import React, { useState } from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import politicaDePrivacidade from "../../assets/politicaDePrivacidade.pdf";

const FooterMenu = ({ branchMenu, setBranchMenu, branches }) => {
  const navigate = useNavigate();

  const openPrivacyPolitics = () => {
    window.open(
      "https://pophaus-files.s3.us-east-2.amazonaws.com/Pol%C3%ADtica+de+privacidade.pdf",
      "_blank"
    );
  };

  const openUsageTerms = () => {
    window.open(
      "https://pophaus-files.s3.us-east-2.amazonaws.com/Termos+de+uso.pdf",
      "_blank"
    );
  };

  const openMail = (mail) => {
    window.open(`mailto:${mail}`);
  };

  const handleOpenWorkWithUs = () => {
    window.open(branches[branchMenu].work_with_us, "_blank");
  };

  return (
    <div className="row">
      <S.ColumnWrapper className="col-sm-3">
        <S.ColumnTitle>Institucional</S.ColumnTitle>
        <S.ColumnItem onClick={() => navigate("/Sobre")}>
          Sobre o PopHaus
        </S.ColumnItem>
        <S.ColumnItem onClick={() => navigate("/PopPontos")}>
          Programa de Pontos
        </S.ColumnItem>
        <S.ColumnItem onClick={() => navigate("/Galeria")}>
          Galeria
        </S.ColumnItem>
        <S.ColumnItem>
          <div
            className="text-white"
            onClick={openPrivacyPolitics}
            style={{ textDecoration: "none" }}
          >
            Política de Privacidade
          </div>
        </S.ColumnItem>
        <S.ColumnItem>
          <div
            className="text-white"
            onClick={openUsageTerms}
            style={{ textDecoration: "none" }}
          >
            Termos de Uso
          </div>
        </S.ColumnItem>
      </S.ColumnWrapper>
      <S.ColumnWrapper className="col-sm-2">
        <S.ColumnItem
          fontSize={"1em"}
          fontWeight={"bold"}
          onClick={() => setBranchMenu("SANTO_AMARO")}
          marginTop={"0px"}
          style={{ color: branchMenu === "SANTO_AMARO" ? "#fdc028" : null }}
        >
          Unidade Santo Amaro
          {branchMenu === "SANTO_AMARO" && (
            <span style={{ marginLeft: "auto" }}>{"<"}</span>
          )}
        </S.ColumnItem>
        <S.ColumnItem
          fontSize={"1em"}
          fontWeight={"bold"}
          onClick={() => setBranchMenu("TATUAPE")}
          style={{ color: branchMenu === "TATUAPE" ? "#fdc028" : null }}
        >
          Unidade Tatuapé
          {branchMenu === "TATUAPE" && (
            <span style={{ marginLeft: "auto" }}>{"<"}</span>
          )}
        </S.ColumnItem>
        {/* <S.ColumnItem
          fontSize={"1em"}
          fontWeight={"bold"}
          onClick={() => setBranchMenu("BRASILIA")}
          style={{ color: branchMenu === "BRASILIA" ? "#fdc028" : null }}
        >
          Unidade Brasília
          {branchMenu === "BRASILIA" && (
            <span style={{ marginLeft: "auto" }}>{"<"}</span>
          )}
        </S.ColumnItem> */}
      </S.ColumnWrapper>
      <S.ColumnWrapper
        className="col-sm-6"
        style={{
          borderLeftStyle: "solid",
          borderWidth: 2,
          borderRadius: 4,
          borderColor: "#fdc028",
        }}
      >
        <div className="row d-flex align-items-start">
          <div className="col-sm-6 d-flex flex-column justify-content-start">
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={600}
              cursor={" "}
              marginTop={"0px"}
            >
              Horário de funcionamento
            </S.ColumnItem>
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={400}
              marginTop={"0px"}
              cursor={" "}
            >
              {branches[branchMenu].horario}
            </S.ColumnItem>
            <S.ColumnItem fontSize={"0.8em"} fontWeight={600} cursor={" "}>
              Endereço
            </S.ColumnItem>
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={400}
              marginTop={"0px"}
              cursor={"pointer"}
              onClick={() => window.open(branches[branchMenu].endereco.url)}
              style={{ textDecoration: "underline" }}
            >
              {branches[branchMenu].endereco.texto}
            </S.ColumnItem>
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={600}
              onClick={handleOpenWorkWithUs}
              className="text-secondary"
            >
              Trabalhe conosco
            </S.ColumnItem>
          </div>
          <div className="col-sm-6 d-flex flex-column justify-content-start">
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={600}
              cursor={"default"}
              marginTop={"0px"}
            >
              WhatsApp
            </S.ColumnItem>
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={400}
              marginTop={"0px"}
              cursor={"pointer"}
              onClick={() => window.open(branches[branchMenu].whatsapp_url)}
              style={{ textDecoration: "underline" }}
            >
              Número: {branches[branchMenu].whatsapp_number}
            </S.ColumnItem>
            <S.ColumnItem fontSize={"0.8em"} fontWeight={500} cursor={" "}>
              Duvidas e suporte
            </S.ColumnItem>
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={400}
              marginTop={"0px"}
              onClick={() => openMail(branches[branchMenu].imprensa)}
              style={{ textDecoration: "underline" }}
            >
              {branches[branchMenu].suporte}
            </S.ColumnItem>
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={600}
              cursor={"default"}
            >
              CNPJ
            </S.ColumnItem>
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={400}
              marginTop={"0px"}
              cursor={"default"}
            >
              CNPJ: {branches[branchMenu].cnpj}
            </S.ColumnItem>
            <S.ColumnItem fontSize={"0.8em"} fontWeight={500} cursor={" "}>
              Imprensa
            </S.ColumnItem>
            <S.ColumnItem
              fontSize={"0.8em"}
              fontWeight={400}
              marginTop={"0px"}
              onClick={() => openMail(branches[branchMenu].imprensa)}
              style={{ textDecoration: "underline" }}
            >
              {branches[branchMenu].imprensa}
            </S.ColumnItem>
          </div>
        </div>
      </S.ColumnWrapper>
    </div>
  );
};

export default FooterMenu;
