import React from "react";
import * as S from "../../styles";

const Card = ({
  title,
  text,
  width,
  height,
  children,
  paddingLeft,
  paddingRight,
  marginLeft,
}) => {
  return (
    <S.Card
      width={width}
      height={height}
      paddingRight={paddingRight}
      paddingLeft={paddingLeft}
      marginTop={"30px"}
      marginLeft={marginLeft}
    >
      <S.CardTitleWrapper>{title}</S.CardTitleWrapper>
      <S.CardTextWrapper>{text}</S.CardTextWrapper>
      {children}
    </S.Card>
  );
};

export default Card;
