import { Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default function ModalAvailabilityUnit ({open, onClose, title, description}) {
  const navigate = useNavigate()

  return (
    <Modal show={open} onHide={onClose} centered>
      <Modal.Header style={{background: '#6f3b8c'}}>
        {title}
      </Modal.Header>
      <Modal.Body>
        {description}
        <div className="d-flex justify-content-between">
          <div></div>
        <button className="btn" style={{background: '#f8c70e'}} onClick={onClose}>Fechar</button>
       </div>
      </Modal.Body>
    </Modal>
  )
}