import styled from "styled-components";

const Container = styled.div`
  max-width: 600px;
  width: 90%;
  height: 605px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin: 0px 20px 0px 20px;
  background-repeat: no-repeat;
  max-height: 605px;

  @media (min-width: 0px) and (max-width: 900px) {
    margin-top: 10px;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 8px;
  ${(props) =>
    props.mobile ? "justify-content: center; padding-bottom: 44px;" : ""}
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 700;
  line-height: 47.2px;
  text-align: center;
  color: white;

  @media (min-width: 0px) and (max-width: 900px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 23.6px;
  text-align: center;
  color: white;

  @media (min-width: 0px) and (max-width: 900px) {
    font-size: 16px;
    margin-top: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  width: 275px;
  height: 47px;
  border: 1px solid white;
  border-radius: 20px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ color }) => (color ? color : "white")};
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;

  @media (min-width: 0px) and (max-width: 380px) {
    width: 90%;
  }

  @media (min-width: 0px) and (max-width: 900px) {
    font-size: 16px;
  }
`;

export { Container, Title, SubTitle, ButtonContainer, Button, CenterContainer };
