import React, { useEffect } from "react";
import * as S from "../Simulador/styles";
import { Outlet } from "react-router-dom";

import { SimulatorHook } from "../../context/simulatorContext";

import Header from "../../components/Header";
import { StepBar } from "../../components/Steps";

export const NewSimulator = () => {

  const { currentStep } = SimulatorHook();

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <S.Container>
      <Header backgroundColor={"transparent"} />
      <S.SubContainer>
        <h1>Pacotes de festas</h1>
        <div>
          <StepBar currentStep={currentStep} />
        </div>
      </S.SubContainer>
      <Outlet />
    </S.Container>
  );
};
