import React, { useContext } from "react";
import { getWindowDimensions } from "../../services/getWindowDimensions";
import { NavigationContext } from "../../context/navigationContext";
import DesktopHeader from "../DesktopHeader";
import MobileHeader from "../MobileHeader";

const Header = ({ backgroundColor }) => {
  const width = getWindowDimensions().width;
  const navigation = useContext(NavigationContext).changeMobileMenu;
  return (
    <>
      {width > 1300 ? (
        <DesktopHeader backgroundColor={backgroundColor} />
      ) : (
        <MobileHeader
          backgroundColor={backgroundColor}
          setMobileMenu={navigation.changeMobileMenu}
          mobileMenu={navigation.mobileMenu}
        />
      )}
    </>
  );
};

export default Header;
