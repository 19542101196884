import React from "react";
import * as S from "./styles";
import priceScreenIconMobile from "../../../assets/mobileIcons/priceScreenIcon.svg";
import priceScreenIcon from "../../../assets/priceScreenIcon.svg";
import { objectMapper } from "../Objects";
import { getWindowDimensions } from "../../../services/getWindowDimensions";

const FirstContainer = ({ unit }) => {
  return (
    <S.Container>
      <S.TextContainer>
        <S.TitleContainer>
          <S.TitleWrapper>Preços Pop</S.TitleWrapper>
          <S.TitleWrapper>{objectMapper[unit].Title}</S.TitleWrapper>
        </S.TitleContainer>
        <S.SubTitleContainer>
          <S.SubTextWrapper
            dangerouslySetInnerHTML={{ __html: objectMapper[unit].Description }}
          ></S.SubTextWrapper>
        </S.SubTitleContainer>
      </S.TextContainer>
      <S.IconContainer>
        <img
          src={
            getWindowDimensions().width > 1200
              ? priceScreenIcon
              : priceScreenIconMobile
          }
          alt="Price Screen Icon"
        />
      </S.IconContainer>
    </S.Container>
  );
};

export default FirstContainer;
