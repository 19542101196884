import React from "react";
import * as S from "./styles";
import Card from "./Card";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import leftArrow from "../../assets/leftArrow.svg";
import rightArrow from "../../assets/rightArrow.svg";
import { getWindowDimensions } from "../../services/getWindowDimensions";

const ThirdContainer = () => {
  const width = getWindowDimensions().width;

  const customArrowStyles = {
    position: "absolute",
    top: "50%",
    width: 30,
    height: 30,
    cursor: "pointer",
    zIndex: 2,
    backgroundColor: "#fff",
    borderRadius: "50%",
    border: "none",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const customArrowPrevStyles = {
    ...customArrowStyles,
    left: 10,
  };

  const customArrowNextStyles = {
    ...customArrowStyles,
    right: 10,
  };



  return (
    <S.Container>
      {width > 1300 ? (
        <>
          <Card
            title={"Santo Amaro"}
            unit={"SantoAmaro"}
            subtitle={
              "Brinquedos gigantes e muita diversão nas três áreas do parque: Pop Jump, Pop Indoor e Pop Outdoor."
            }
          />
          <Card
            title={"Tatuapé"}
            unit={"Tatuape"}
            subtitle={
              "Atrações incríveis e inéditas divididas em duas áreas: Pop Indoor e Pop Outdoor."
            }
          />
          {/* <Card
            title={"Brasília"}
            unit={"Brasilia"}
            subtitle={
              "O maior parque de brinquedos infláveis da América Latina chegou em Brasília! Atrações gigantescas e aquáticas pra você se divertir muuuito."
            }
          /> */}
        </>
      ) : (
        <div style={{ width: "95%", maxWidth: "800px" }}>
          <Carousel
            centerMode={true}
            showStatus={false}
            swipeable={false}
            centerSlidePercentage={90}
            showThumbs={false}
            showIndicators={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={customArrowPrevStyles}
                >
                  <img src={leftArrow} alt="left arrow" />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, label) => (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={customArrowNextStyles}
              >
                <img src={rightArrow} alt="right arrow" />
              </button>
            )}
          >
            <Card
              title={"Santo Amaro"}
              unit={"SantoAmaro"}
              subtitle={
                "Brinquedos gigantes e muita diversão nas três áreas do parque: Pop Jump, Pop Indoor e Pop Outdoor."
              }
            />
            <Card
              title={"Tatuapé"}
              unit={"Tatuape"}
              subtitle={
                "Atrações incríveis e inéditas divididas em duas áreas: Pop Indoor e Pop Outdoor."
              }
            />
            {/* <Card
              title={"Brasília"}
              unit={"Brasilia"}
              subtitle={
                "O maior parque de brinquedos infláveis da América Latina chegou em Brasília! Atrações gigantescas e aquáticas pra você se divertir muuuito."
              }
            /> */}
          </Carousel>
        </div>
      )}
    </S.Container>
  );
};

export default ThirdContainer;
