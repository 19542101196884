import React from "react";
import PropTypes from "prop-types";
import generateStyleSheet from "./generateStyleSheet";
import injectCss from "./injectCss";

const NAME = "ReactBackgroundSlider";

function BackgroundSlider({ images, duration, transition, style = {} }) {
  React.useEffect(() => {
    injectCss(
      generateStyleSheet({
        imagesCount: images.length,
        duration,
        transition,
      }),
      NAME
    );
  });

  return (
    <div
      id={NAME}
      style={{
        ...style,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      {images.map((img, key) => (
        <figure
          key={key}
          style={{
            backgroundImage: `url(${img})`,
            animationDelay: `${(duration + transition) * key}s`,
          }}
        />
      ))}
    </div>
  );
}

BackgroundSlider.defaultProps = {
  duration: 10,
  transition: 2,
};

BackgroundSlider.propTypes = {
  images: PropTypes.array.isRequired,
  duration: PropTypes.number,
  transition: PropTypes.number,
};

export default BackgroundSlider;
