import React from "react";
import * as S from "./styles.js";

const BootstrapCard = ({
  title,
  thumb_url,
  description,
  action,
  backgroundColor,
  border,
  titleColor,
  descriptionColor,
  onClick,
  mobile,
}) => {
  return (
    <S.Card
      style={{
        backgroundColor,
        border,
        cursor: onClick ? "pointer" : "default",
        alignSelf: "flex-start",
        marginBottom: mobile ? 12 : 0,
      }}
      onClick={onClick}
    >
      {thumb_url && (
        <img
          src={thumb_url}
          alt="Card image cap"
          height={160}
          style={{ objectFit: "cover", borderRadius: 10 }}
        />
      )}
      <div
        class="card-body"
        style={{
          padding: 4,
          paddingTop: 8,
          textAlign: mobile ? "center" : "default",
        }}
      >
        {title && (
          <h5
            class="card-title"
            style={{
              color: titleColor,
              fontWeight: 700,
              fontSize: 18,
              marginBottom: 2,
            }}
          >
            {title}
          </h5>
        )}
        {description && (
          <p
            class="card-text"
            style={{ color: descriptionColor, fontWeight: 500, fontSize: 16 }}
          >
            {description}
          </p>
        )}
        {action}
      </div>
    </S.Card>
  );
};

export default BootstrapCard;
