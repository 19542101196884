import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import CarouselContainer from "../../components/CarouselContainer";
import Footer from "../../components/Footer";
import SecondContainer from "./SecondContainer";
import FirstContainer from "./FirstContainer";

const Home = ({ mobile }) => {
  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <>
      <Helmet>
        <title>Parque de Brinquedos Infláveis em São Paulo | PopHaus</title>
      </Helmet>
      <FirstContainer mobile={mobile} />
      <SecondContainer mobile={mobile} />
      <CarouselContainer />
      <Footer />
    </>
  );
};

export default Home;
