import React, { useEffect, useRef } from "react";
import { SimulatorHook } from "../../../context/simulatorContext";
import { MockedData } from "./data";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { gtagPixel } from "../../../utils/util";
import { userAcceptedLocalStorageUsage } from "../../../LocalStorageAndCookiesModal";
import { Api } from "../../../api/api";

const parameterDictionary = {
  name: "Nome",
  date: "Data",
  duration: "Duração",
  event_time: "Horário do evento",
  min_players: "Número de convidados",
  private_venue: "Espaço privativo",
  event_decoration: "Decoração",
  event_buffet: "Buffet",
  priceParty: "Valor do pacote",
  guestAdditional: "Convidado adiconal",
  monitorAssistant: "Monitor assistente",
  volumeGuardByGuests: "Guarda Volumes",
};

export const SelectPan = () => {
  const {
    unit,
    setCurrentStep,
    setPlanName,
    setRegularTickets,
    setKioskTarget,
    setDuration,
    setPlanPrice,
  } = SimulatorHook();
  const navigate = useNavigate();

  if (unit === "") {
    navigate("/");
  }

  const storedUserInfo = localStorage.getItem("popHausPartyUserInfo");
  const userInfo = JSON.parse(storedUserInfo);
  const dataPackages = MockedData.units[userInfo.unit][userInfo.typePartyId];
  const elementRef = useRef(null);
  setCurrentStep(3);

  function keepOnlyNumbers(string) {
    return string
      .split(/\D+/)
      .filter((n) => n)
      .join("");
  }

  const handleFinalOrderPage = (data) => {
    setKioskTarget(data.private_venue);
    setPlanPrice(data.priceParty);
    setDuration(data.duration);
    setRegularTickets(data.min_players);
    setPlanName(data.name);

    gtagPixel("gtm.click", {
      send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
      packageValue: keepOnlyNumbers(data.priceParty),
    });

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", `FestaPacote`, {
          value: keepOnlyNumbers(data.priceParty),
          currency: "BRL",
        });
      }
    }

    Api.post("website_clicks", {
      clicked_id: `${getUnitPrefix()}${data.click_id}`,
    });

    navigate("/NewSimulator/FinalOrderPage");
  };

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const getUnitPrefix = () => {
    if (userInfo.unit === "TATUAPE") return "ttp_";
    if (userInfo.unit === "SANTO_AMARO") return "sta_";
    return "";
  };

  return (
    <div
      className="d-flex justify-content-center align-content-center gap-3 flex-wrap bg-primary p-2"
      ref={elementRef}
    >
      {dataPackages.map((data) => (
        <div className="flex" key={data.name}>
          <h5 className="text-secondary text-center mt-4">{data.name}</h5>
          <div className="card p-4 flex">
            {Object.entries(data).map(
              ([key, value]) =>
                !["name", "click_id"].includes(key) && (
                  <div key={key}>
                    <span className="selectPan__Span m-1">
                      {parameterDictionary[key] || key}:
                    </span>
                    <span
                      className={`text-${
                        key === "priceParty" ? "danger" : "primary"
                      }`}
                    >
                      {key === "duration"
                        ? `${value} Horas`
                        : key === "priceParty"
                        ? `R$ ${value}`
                        : value}
                    </span>
                    <hr className="mt-2 mb-2" />
                  </div>
                )
            )}
            <button
              onClick={() => handleFinalOrderPage(data)}
              className="button__primary"
              id={`${getUnitPrefix()}${data.click_id}`}
            >
              Selecionar
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
