import React from "react";
import facebookIcon from "../assets/facebookIcon.svg";
import instagramIcon from "../assets/instagramIcon.svg";
import youtubeIcon from "../assets/youtubeIcon.svg";

export const dataContact = {
  SANTO_AMARO: {
    phone_number: "(11) 5641-1720",
    horario: (
      <>
        Sexta, das 13h às 19h
        <br />
        Sábado, das 10h às 20h
        <br />
        Domingo, das 10h às 18h
      </>
    ),
    endereco: {
      texto: (
        <>
          Rua Doutor Rubens Gomes Bueno, 288 - Várzea de Baixo, São Paulo - SP,
          04730-000 (Ver no mapa)
        </>
      ),
      url: "https://goo.gl/maps/pE787VJGLwgk7eSg9",
    },
    cnpj: "22.308.504/0001-06",
    instagram: {
      url: "https://instagram.com/pophaus_sp/",
      image: instagramIcon,
    },
    facebook: {
      url: "https://m.facebook.com/PopHaus4Fun/",
      image: facebookIcon,
    },
    youtube: {
      url: "https://youtube.com/channel/UCov_8HtqI8Wg1WwzmUJAY5g",
      image: youtubeIcon,
    },
    whatsapp_number: "(11) 91770-6110",
    whatsapp_url: "https://api.whatsapp.com/send?phone=5511917706110",
    imprensa: "imprensa@pophaus.com.br",
    suporte: "contato@pophaus.com.br",
    work_with_us: process.env.REACT_APP_WORK_WITH_US_URL_STA,
  },
  TATUAPE: {
    phone_number: "(11) 5641-1720",
    horario: (
      <>
        Sexta, das 13h às 19h
        <br />
        Sábado, das 10h às 20h
        <br />
        Domingo, das 10h às 18h
      </>
    ),
    endereco: {
      texto: (
        <>
          Rua Euclides Pacheco, 483 - Vila Gomes Cardim, São Paulo - SP,
          03321-000 (Ver no mapa)
        </>
      ),
      url: "https://goo.gl/maps/XgeBX66UoYczXbncA",
    },
    cnpj: "41.920.044/0001-17",
    instagram: {
      url: "https://instagram.com/pophaus_sp/",
      image: instagramIcon,
    },
    facebook: {
      url: "https://m.facebook.com/PopHaus4Fun/",
      image: facebookIcon,
    },
    youtube: {
      url: "https://youtube.com/channel/UCov_8HtqI8Wg1WwzmUJAY5g",
      image: youtubeIcon,
    },
    whatsapp_number: "(11) 91770-6110",
    whatsapp_url: "https://api.whatsapp.com/send?phone=5511917706110",
    imprensa: "imprensa@pophaus.com.br",
    suporte: "contato@pophaus.com.br",
    work_with_us: process.env.REACT_APP_WORK_WITH_US_URL_TTP,
  },
};
