import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import * as S from "../../Simulador/styles";
import * as Y from "./styles";
import { SimulatorHook } from "../../../context/simulatorContext";
import InputMask from "react-input-mask";
import validator from "validator";
import ModalAvailabilityUnit from "../modalAvailabilityUnit";

const FormContainer = ({ mobile }) => {
  const {
    name,
    setName,
    lastName,
    setLastName,
    setCurrentStep,
    email,
    setEmail,
    phone,
    setPhone,
    setUnit,
    unit,
  } = SimulatorHook();
  setCurrentStep(1);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const urlParams = new URLSearchParams(window.location.search.substring(1));

  const handleChange = () => {
    setChecked(!checked);
  };

  const UnitDictionary = {
    Brasilia: "BRASILIA",
    SantoAmaro: "SANTO_AMARO",
    Tatuape: "TATUAPE",
  };

  setUnit(UnitDictionary[urlParams.get("un")]);

  const handleValidationForm = () => {
    let err = false;
    if (name === "") {
      err = true;
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (lastName === "") {
      err = true;
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (phone.length < 11) {
      err = true;
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    if (!validator.isEmail(email)) {
      err = true;
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    return err;
  };

  const openPrivacyPolitics = () => {
    window.open(
      "https://pophaus-files.s3.us-east-2.amazonaws.com/Pol%C3%ADtica+de+privacidade.pdf",
      "_blank"
    );
  };

  const navigate = useNavigate();

  const handleStep = () => {
    if (!checked) {
      return setNotifyModal(true);
    }
    if (!handleValidationForm()) {
      const userInfo = {
        name: name,
        lastName: lastName,
        email: email,
        phone: phone,
        unit: UnitDictionary[urlParams.get("un")],
      };

      localStorage.setItem("popHausPartyUserInfo", JSON.stringify(userInfo));
      navigate("/NewSimulator/SelectTypeParty");
    }
  };
  return (
    <S.Container>
      <ModalAvailabilityUnit
        title={<h3 className="text-warning">OPS!</h3>}
        description={
          <>
            <p>
              Precisamos que aceite os
              <Y.TextNotify> termos de dados.</Y.TextNotify>
              <br />
              Clique{" "}
              <a
                className="text-underline"
                onClick={() => openPrivacyPolitics()}
              >
                aqui
              </a>{" "}
              para ler nossa Política de Privacidade.
            </p>
          </>
        }
        open={notifyModal}
        onClose={() => setNotifyModal(false)}
      />

      <S.ContainerWrapper>
        <Form.Group>
          <Form.Label className="text-white">Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Digite seu nome"
            className="input_step"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && <p className="text-warning">Nome obrigatório</p>}
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-white">Sobrenome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Digite seu sobrenome"
            className="input_step"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          {lastNameError && (
            <p className="text-warning">Sobrenome obrigatório</p>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-white">Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Digite seu email"
            className="input_step"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            inputMode="email"
            required
          />
          {emailError && (
            <p className="text-warning">Email obrigatório ou inválido</p>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-white">Telefone</Form.Label>
          <InputMask
            mask="(99) 99999-9999"
            maskChar=""
            className="input_step"
            placeholder="Digite seu telefone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {phoneError && <p className="text-warning">Telefone obrigatório</p>}
        </Form.Group>
        <Form.Group>
          <div className="check_wrapper">
            <input type="checkbox" checked={checked} onChange={handleChange} />
            <Form.Label>
              Estou de acordo com o envio dos meus dados ao PopHaus para
              <br />
              tratamento dos dados enviados e para futuros contatos. <br />
              Clique{" "}
              <a
                className="text-underline"
                onClick={() => openPrivacyPolitics()}
              >
                aqui
              </a>{" "}
              para ler nossa Política de Privacidade.
            </Form.Label>
          </div>
          <p className="text-center text-white"></p>
        </Form.Group>
        <div>
          <button onClick={() => handleStep()} className="button__success">
            Avançar
          </button>
        </div>
      </S.ContainerWrapper>
    </S.Container>
  );
};

export default FormContainer;
