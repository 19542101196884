import React from "react";
import aboutus from "../../../assets/images/aboutus.webp";
import Header from "../../../components/Header";
import * as S from "./styles";

const FirstContainer = () => {
  return (
    <S.Container
      style={{
        background: `linear-gradient(0deg, rgba(111, 59, 140, 0.6), rgba(111, 59, 140, 0.6)), url(${aboutus}`,
        backgroundSize: "cover",
        BackgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <Header backgroundColor={"transparent"} />
      <S.CenterContainer>
        <S.TitleWrapper>Conheça o PopHaus</S.TitleWrapper>
        <S.TextWrapper>
          O primeiro e mais divertido parque de brinquedos infláveis e
          trampolins da América Latina!
        </S.TextWrapper>
      </S.CenterContainer>
    </S.Container>
  );
};

export default FirstContainer;
