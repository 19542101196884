import { useEffect } from 'react';
import * as C from './styles';
export const StepBar = ({ currentStep }) => {
  const stepsInfo = [
    { stepId: 1, stepInfo: '' },
    { stepId: 2, stepInfo: '' },
    { stepId: 3, stepInfo: '' },
    { stepId: 4, stepInfo: '' },
    // { stepId: 5, stepInfo: '' },
    // { stepId: 6, stepInfo: '' },
    // { stepId: 7, stepInfo: '' }
  ]

  useEffect(() => {
    const allSteps = document.querySelectorAll('.steep-selector')
    allSteps.forEach(i => {
      currentStep >= Number(i.id) ? i.classList.add('point-selected') : i.classList.remove('point-selected')
    })

  }, [currentStep])
  return (
    <C.Container>
      <div className='wrapper_steps'>
        {
          stepsInfo.map(step => {
            return (
              <C.Point step={currentStep} className='steep-selector' id={step.stepId} key={step.stepId} />
            )
          })
        }
      </div>
    </C.Container>
  )
}