import React from "react";
import home from "../../../assets/images/festa.webp";
import homeM from "../../../assets/images/homeM.jpg.webp";
import { getWindowDimensions } from "../../../services/getWindowDimensions";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";

const SecondContainer = ({ mobile }) => {
  const navigate = useNavigate();
  return (
    <S.Container
      style={{
        background: `linear-gradient(0deg, rgba(111, 59, 140, 0.6), rgba(111, 59, 140, 0.6)), url(${
          getWindowDimensions().width < 600 ? homeM : home
        }`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      mobileImg={homeM}
      mobile={mobile}
    >
      <S.CenterContainer>
        <S.TitleWrapper>Faça sua festa</S.TitleWrapper>
        <S.SubTitle>
          Imagina comemorar seu aniversário no maior parque de brinquedos
          infláveis e trampolins da América Latina?
        </S.SubTitle>
        <S.InputContainer>
          <S.Button onClick={() => navigate("/Festas?un=Tatuape")}>
            Unidade Tatuapé
          </S.Button>
          <S.Button
            backgroundColor={"#6F3B8C"}
            color={"white"}
            onClick={() => navigate("/Festas?un=SantoAmaro")}
          >
            Unidade Santo Amaro
          </S.Button>
          {/* <S.Button onClick={() => navigate("/Festas?Unidade=Brasilia")}>
            Unidade Brasília
          </S.Button> */}
        </S.InputContainer>
      </S.CenterContainer>
    </S.Container>
  );
};

export default SecondContainer;
