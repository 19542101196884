import "./styles.scss";
import aboutFourthIcon from "../../../assets/aboutFourthIcon.svg";

export const SixthContainer = ({ width }) => {
  return (
    <div className="about__six--Container">
      <div className="row" inverse={true} marginTop={"30px"}>
        <section className="col-sm-12 col-md-6 about__align">
          <h2 className="text-primary mt-5 fw-bold">Missão</h2>
          <p className="about__description">
            Nosso propósito é proporcionar diversão e alegria para toda família,
            em um espaço seguro, com brinquedos inovadores e excelência no
            atendimento.
          </p>
          <h2 className="text-primary mt-5 fw-bold">Visão</h2>
          <p className="about__description">
            Ser referência como centro de entretenimento familiar em todo o
            Brasil.
          </p>
        </section>
        <section className="col-sm-12 col-md-6 about__align">
          <img
            src={aboutFourthIcon}
            alt="Price Screen Icon"
            width={width > 1200 ? 400 : 200}
          />
        </section>
      </div>
      <div className="row">
        <h2 className="mt-4 text-primary flex text-center fw-bold">Valores</h2>
      </div>
      <div className="row">
        <section className="about__align col-sm-12 col-md-6">
          <h5 className="text-primary mt-5 fw-bold">Segurança</h5>
          <p className="about__description">
            "Sua única preocupação é se divertir!"
          </p>
          <h5 className="text-primary mt-5 fw-bold">Ética</h5>
          <p className="about__description">
            "Bom senso no trato das relações interpessoais."
          </p>
          <h5 className="text-primary mt-5 fw-bold">
            Excelência no atendimento
          </h5>
          <p className="about__description">
            "Entregar satisfação a partir do atendimento, fazendo o cliente se
            sentir especial.”
          </p>
        </section>
        <section className="col-sm-12 col-md-6 mb-5">
          <div className="about__align">
            <h5 className="text-primary mt-5 fw-bold">Diversão</h5>
            <p className="about__description">
              "Se divertir é um direito de todos e promover a <br /> diversão é
              o que nos move."
            </p>
            <h5 className="text-primary mt-5 fw-bold">Inclusão</h5>
            <p className="about__description">
              "Aumentar a representatividade do nosso <br /> público e das
              nossas equipes."
            </p>
            <h5 className="text-primary mt-5 fw-bold">Colaboração</h5>
            <p className="about__description">
              "Uma causa em comum agrega esforços."
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
