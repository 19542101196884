import styled from "styled-components";

const Container = styled.div`
  margin: 0px;
  height: 80vh;
  min-height: 400px;
  margintop: -20px;
  border: 1px solid transparent;
  borderradius: 20px;
`;

const CenterContainer = styled.div`
  padding: 30px 25% 30px 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;

  @media (min-width: 0px) and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const TitleContainer = styled.div`
  height: 150px;
  background-color: #ed5046;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 2em;
`;

const TextWrapper = styled.p`
  color: #6f3b8c;
  font-weight: 500;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0")};
  text-align: left;
`;

export {
  Container,
  CenterContainer,
  TextWrapper,
  TitleWrapper,
  TitleContainer,
};
