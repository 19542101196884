export const MockedData = {
  units: {
    BRASILIA: {
      shared_parties: [
        {
          name: "Pacote P",
          date: "SÁB ou DOM",
          duration: "3",
          event_time: "10H às 13H ou 15H às 18H",
          min_players: "15",
          private_venue: "Quiosque de 30M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "2.440",
        },
        {
          name: "Pacote M",
          date: "SÁB ou DOM",
          duration: "3",
          event_time: "10H às 13H ou 15H às 18H",
          min_players: "30",
          private_venue: "Quiosque de 30M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          souvenir: "20 UNIDADES",
          priceParty: "4.180",
        },
        {
          name: "Pacote G",
          date: "SÁB ou DOM",
          duration: "3",
          event_time: "10H às 13H ou 15H às 18H",
          min_players: "45",
          private_venue: "Quiosque de 60M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          souvenir: "30 UNIDADES",
          priceParty: "6.170",
        },
      ],
      semi_exclusive_parties: [
        {
          name: "Pacote P",
          date: "SEXTA",
          duration: "4",
          event_time: "14H às 18H",
          min_players: "25",
          private_venue: "Quiosque de 30M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 140",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "3.550",
        },
        {
          name: "Pacote M",
          date: "SEXTA",
          duration: "4",
          event_time: "14H às 18H",
          min_players: "40",
          private_venue: "Quiosque de 60M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 125",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "5.025",
        },
      ],
      exclusive_parties: [
        {
          name: "Pacote M",
          date: "SEG A SEX",
          duration: "4",
          event_time: "À SUA ESCOLHA",
          min_players: "60",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 125",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "7.500",
        },
        {
          name: "Pacote G",
          date: "SEG A SEX",
          duration: "4",
          event_time: "À SUA ESCOLHA",
          min_players: "100",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 115",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "11.500",
        },
      ],
    },
    SANTO_AMARO: {
      shared_parties: [
        {
          name: "Pacote P",
          date: "QUA A SEX",
          duration: "4",
          event_time: "13H às 17H",
          min_players: "15",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "3.310",
          click_id: "compartilhada_pacote_p",
        },
        {
          name: "Pacote M",
          date: "QUA A SEX",
          duration: "4",
          event_time: "13H às 17H",
          min_players: "20",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "4.080",
          click_id: "compartilhada_pacote_m",
        },
        {
          name: "Pacote G",
          date: "QUA A SEX",
          duration: "4",
          event_time: "13H às 17H",
          min_players: "30",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "5.770",
          click_id: "compartilhada_pacote_g",
        },
        {
          name: "Pacote P Final de semana",
          date: "SÁB E DOM",
          duration: "3",
          event_time: "10H às 13H ou 15H às 18H",
          min_players: "15",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "3.635",
          click_id: "compartilhada_pacote_pfds",
        },
        {
          name: "Pacote M Final de semana",
          date: "SÁB E DOM",
          duration: "3",
          event_time: "10H às 13H ou 15H às 18H",
          min_players: "20",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "4.680",
          click_id: "compartilhada_pacote_mfds",
        },
        {
          name: "Pacote G Final de semana",
          date: "SÁB E DOM",
          duration: "3",
          event_time: "10H às 13H ou 15H ÀS 18H",
          min_players: "30",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "6.770",
          click_id: "compartilhada_pacote_gfds",
        },
      ],
      semi_exclusive_parties: [
        {
          name: "Pacote P",
          date: "SEXTA",
          duration: "4",
          event_time: "19H ÀS 23H",
          min_players: "30",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 143",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "4.600",
          click_id: "noturnase_pacote_p",
        },
        {
          name: "Pacote M",
          date: "SEXTA",
          duration: "4",
          event_time: "19H ÀS 23H",
          min_players: "45",
          private_venue: "Quiosque de 50M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 137",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "6.650",
          click_id: "noturnase_pacote_m",
        },
        {
          name: "Pacote G",
          date: "SEXTA",
          duration: "4",
          event_time: "19H ÀS 23H",
          min_players: "60",
          private_venue: "Quiosque de 50M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 133",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "8.600",
          click_id: "noturnase_pacote_g",
        },
      ],
      exclusive_parties: [
        {
          name: "Pacote M",
          date: "SEG A SEX",
          duration: "4",
          event_time: "À SUA ESCOLHA",
          min_players: "60",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 170",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "10.800",
          click_id: "exclusiva_pacote_m",
        },
        {
          name: "Pacote G",
          date: "SEG A SEX",
          duration: "4",
          event_time: "À SUA ESCOLHA",
          min_players: "100",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 115",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "16.000",
          click_id: "exclusiva_pacote_g",
        },
      ],
    },
    TATUAPE: {
      shared_parties: [
        {
          name: "Pacote P",
          date: "QUA A SEX",
          duration: "4",
          event_time: "13H ÀS 17H",
          min_players: "15",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "2.786",
          click_id: "compartilhada_pacote_p",
        },
        {
          name: "Pacote M",
          date: "QUA A SEX",
          duration: "4",
          event_time: "13H ÀS 17H",
          min_players: "20",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "3.580",
          click_id: "compartilhada_pacote_m",
        },
        {
          name: "Pacote G",
          date: "QUA A SEX",
          duration: "4",
          event_time: "13H ÀS 17H",
          min_players: "30",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "5.170",
          click_id: "compartilhada_pacote_g",
        },
        {
          name: "Pacote P Final de semana",
          date: "SÁB E DOM",
          duration: "3",
          event_time: "10H ÀS 13H ou 15H ÀS 18H",
          min_players: "15",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "3.035",
          click_id: "compartilhada_pacote_pfds",
        },
        {
          name: "Pacote M Final de semana",
          date: "SÁB E DOM",
          duration: "3",
          event_time: "10H ÀS 13H ou 15H ÀS 18H",
          min_players: "20",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "3.880",
          click_id: "compartilhada_pacote_mfds",
        },
        {
          name: "Pacote G Final de semana",
          date: "SÁB E DOM",
          duration: "3",
          event_time: "10H ÀS 13H ou 15H ÀS 18H",
          min_players: "30",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          priceParty: "5.570",
          click_id: "compartilhada_pacote_gfds",
        },
      ],
      semi_exclusive_parties: [
        {
          name: "Pacote P",
          date: "SEXTA",
          duration: "3:30",
          event_time: "19H ÀS 22H30",
          min_players: "25",
          private_venue: "Quiosque de 25M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 143",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "3.950",
          click_id: "noturnase_pacote_p",
        },
        {
          name: "Pacote M",
          date: "SEXTA",
          duration: "3:30",
          event_time: "19H ÀS 22H30",
          min_players: "40",
          private_venue: "Quiosque de 50M2",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 137",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "5.900",
          click_id: "noturnase_pacote_m",
        },
      ],
      exclusive_parties: [
        {
          name: "Pacote M",
          date: "SEG A SEX",
          duration: "4",
          event_time: "À SUA ESCOLHA",
          min_players: "60",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 126",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "8.200",
          click_id: "exclusiva_pacote_m",
        },
        {
          name: "Pacote G",
          date: "SEG A SEX",
          duration: "4",
          event_time: "À SUA ESCOLHA",
          min_players: "100",
          event_decoration: "POPHAUS",
          event_buffet: "Da casa",
          guestAdditional: "R$ 126",
          monitorAssistant: "INCLUSO",
          volumeGuardByGuests: "INCLUSO",
          priceParty: "12.000",
          click_id: "exclusiva_pacote_g",
        },
      ],
    },
  },
};
