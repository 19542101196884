import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: #fdc028;
  border: 1px solid transparent;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 20px;
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  padding: 0px 40px 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Card = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const CardHeader = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  width: 100%;
  height: 94px;
  border: 1px solid transparent;
  border-radius: 30px;
  color: white;
  display: flex;
  justify-content: center;
  font-weight: 700;
  padding-top: 5px;
`;

const CardBody = styled.div`
  background-color: white;
  margin-top: -50px;
  border: 1px solid transparent;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 20px 20px 20px;
`;

const CardTextWrapper = styled.div`
  color: #753694;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};

  @media (min-width: 0px) and (max-width: 1300px) {
    font-size: 16px;
  }
`;

const ImageContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DescriptionTextWrapper = styled.p`
  font-size: 16px;
  color: #6f3b8c;
  margin-left: 10px;
  width: 85%;
`;

const CardTitleWrapper = styled.div`
  color: #753694;
  font-size: 60px;
  height: 100%;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-itens: center;
  margin: 0px;
  line-height: 60px;
  margin-right: 20px;

  @media (min-width: 0px) and (max-width: 1300px) {
    font-size: 36px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
`;

const DonutsContainer = styled.div`
  background-color: #6f3b8c;
  border: 1px solid transparent;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
  width: 100%;
  padding: 40px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DonutsText = styled.p`
  color: white;
  font-weight: 600;
  font-size: 16px;
  max-width: 850px;
  text-align: center;
  margin-top: 20px;
`;

const DonutsCard = styled.div`
  width: 100%;
  max-width: 370px;
  height: auto;
  min-height: 308px;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
`;

const DonutsCardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const TableRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const TableCell = styled.div`
  width: 48%;
  height: 27px;
  max-width: 155px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  color: ${({ color }) => (color ? color : "white")};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "0px")};
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
`;

export {
  Container,
  CardContainer,
  CardHeader,
  CardBody,
  CardTextWrapper,
  Card,
  CardTitleWrapper,
  CardWrapper,
  DescriptionContainer,
  DescriptionTextWrapper,
  ImageContainer,
  DonutsContainer,
  DonutsText,
  DonutsCard,
  DonutsCardContainer,
  TableRow,
  TableCell,
};
