import React from "react";
import * as RiIcons from "react-icons/ri";

export const Menu = [
  {
    title: "Unidades",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    submenu: [
      {
        title: "Santo Amaro",
        url: "/Unidades?Unidade=SantoAmaro",
      },
      { title: "Tatuapé", url: "/Unidades?Unidade=Tatuape" },
      // { title: "Brasília", url: "/Unidades?Unidade=Brasilia" },
    ],
  },
  {
    title: "Preços",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    submenu: [
      { title: "Santo Amaro", url: "/Precos?Unidade=SantoAmaro" },
      { title: "Tatuapé", url: "/Precos?Unidade=Tatuape" },
      // { title: "Brasília", url: "/Precos?Unidade=Brasilia" },
    ],
  },
  {
    title: "Festas",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    submenu: [
      { title: "Santo Amaro", url: "/Festas?un=SantoAmaro" },
      { title: "Tatuapé", url: "/Festas?un=Tatuape" },
      // { title: "Brasília", url: "/Festas?un=Brasilia" },
      // { title: "Simulador de Festas", url: "/Simulador" },
    ],
  },
  {
    title: "Sobre",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    submenu: [
      { title: "Sobre o PopHaus", url: "/Sobre" },
      // { title: "Pop Dia Feliz", url: "/Popdiafeliz" },
      { title: "Galeria", url: "/Galeria" },
      { title: "Pop Pontos", url: "/PopPontos" },
    ],
  },
  { title: "Dúvidas", url: "/Duvidas" },
  { title: "Contato", url: "/Contato"},
];
