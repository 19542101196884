import React from "react";
import * as S from "./styles";
import logoHeader from "../../assets/logoHeader.svg";
import peopleIcon from "../../assets/peopleIcon.svg";
import NavMenu from "../NavMenu";
import BuyButton from "../BuyButton";
import { useNavigate } from "react-router-dom";
import { userAcceptedLocalStorageUsage } from "../../LocalStorageAndCookiesModal";

const DesktopHeader = ({ backgroundColor }) => {
  const navigate = useNavigate();

  const renderLogin = () => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        if (userAcceptedLocalStorageUsage) window.fbq("track", "AddToCart");
      }
    }

    window.open(
      `${process.env.REACT_APP_OLD_WEBSITE_URL}/login?origem=website`,
      "_self"
    );
  };

  return (
    <S.Container
      className="row d-flex align-items-center justify-content-between"
      backgroundColor={backgroundColor}
      id="header"
    >
      <S.LogoWrapper className="col-sm-2" onClick={() => navigate("/")}>
        <img src={logoHeader} alt="Logo" style={{ marginLeft: 20 }} />
      </S.LogoWrapper>

      <S.MenuContainer className="col-sm-7 d-flex justify-content-between">
        <NavMenu />
      </S.MenuContainer>
      <S.ButtonsWrapper className="col-sm-3 row ">
        <BuyButton />
        <div className="col-sm-3">
          <img
            src={peopleIcon}
            alt="People Icon"
            style={{ cursor: "pointer" }}
            onClick={renderLogin}
          />
        </div>
      </S.ButtonsWrapper>
    </S.Container>
  );
};

export default DesktopHeader;
