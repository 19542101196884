import React from "react";

const CircularProgress = ({ color = "primary", size = 30, style = {} }) => {
  return (
    <div
      class={`spinner-border text-${color}`}
      style={{ ...style, width: size, height: size }}
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  );
};

export default CircularProgress;
