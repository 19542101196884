import { Carousel } from "react-bootstrap";

const BootstrapCarousel = ({ images }) => {
  return (
    <>
      <Carousel indicators={false} controls={images[1] ? true : false}>
        {images.map((item) => (
          <Carousel.Item key={item.src}>
            <img className="d-block w-100" src={item.src} alt={item.tooltip} />
            <Carousel.Caption
              className="bg-black bg-opacity-25 rounded w-100 rounded-0"
              style={{ left: 0, right: 0 }}
            >
              {item.tooltip}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default BootstrapCarousel;
