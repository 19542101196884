import styled from "styled-components";

const Container = styled.div`
  background-color: #fdc028;
  border: 1px solid #fdc028;
  border-radius: 20px 20px 0px 0px;
  height: 780px;
  margin: -20px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container };
