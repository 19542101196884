import React from "react";
import * as S from "./styles";
// import aboutusThirdSantoAmaro from "../../../assets/images/aboutusThirdSantoAmaro.webp";
// import aboutusThirdTatuape from "../../../assets/images/aboutusThirdTatuape.webp";
import santoamaroImg from "../../../assets/images/unit_santoamaro.jpg.webp";
import brasiliaImg from "../../../assets/images/unit_brasilia.webp";
import tatuapeImg from "../../../assets/images/unit_tatuape_2.webp";
import { useNavigate } from "react-router-dom";
import { getWindowDimensions } from "../../../services/getWindowDimensions";
import { userAcceptedLocalStorageUsage } from "../../../LocalStorageAndCookiesModal";

const Card = ({ title, unit, subtitle }) => {
  const navigate = useNavigate();
  const mobile = getWindowDimensions().width <= 1300;

  function renderFilialBackground(title) {
    if (title === "Santo Amaro") {
      return santoamaroImg;
    } else if (title === "Tatuapé") {
      return tatuapeImg;
    } else if (title === "Brasília") {
      return brasiliaImg;
    }
  }

  return (
    <S.Container
      style={{
        background: `linear-gradient(0deg, rgba(111, 59, 140, 0.6), rgba(111, 59, 140, 0.6)), url(${renderFilialBackground(
          title
        )}`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <S.CenterContainer mobile={mobile}>
        <S.Title>Unidade</S.Title>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{subtitle}</S.SubTitle>
        <S.ButtonContainer>
          <S.Button
            backgroundColor={"#FDC028"}
            color={"#6F3B8C"}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_OLD_WEBSITE_URL}/comprar-ingressos?origem=website`
              )
            }
          >
            Compre seu ingresso
          </S.Button>
          <S.Button onClick={() => navigate(`/Unidades?Unidade=${unit}`)}>
            Conheça a unidade
          </S.Button>
        </S.ButtonContainer>
      </S.CenterContainer>
    </S.Container>
  );
};

export default Card;
