import styled from "styled-components";
import { darken } from "polished";
export const Container = styled.div`
  margin: 0px;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 650px;
  background-color: #6f3b8c;
`;

export const SubContainer = styled.div`
  background-color: #6f3b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6rem;
  gap: 2rem;
  div:first-child {
    font-weight: 700;
    color: #F8C70E;
  }
`

export const ContainerWrapper = styled.div`
  background-color: #6f3b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .select {
    width: 22rem !important;
    background-color: ${darken(0.1, '#6f3b8c')};
    border: none;
    border-radius: 50px;
    height: 3.5rem;
    outline: none;
    color: #fff;
    font-size: 1rem;
    &:focus{
      outline: none;
      border: none;
    }
  }
  .wrapper_buttons{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    .btn_1{
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      span{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
      color: #fff;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 42px;
      text-align: center
    }
    .btn_2{
      margin-top: 0.8rem;
      width: 225px;
      height: 40px;
      border-radius: 25px;
      background-color: #83E193;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 42px;
      text-align: center;
      outline: none;
      border: none;
      opacity: 0.8;
      transition: 0.3s;
      margin-bottom: 0.5rem;
      color: #fff;
      &:hover{
        opacity: 1;
      }
    }
  }
  .select-area {
    background-color: red;
    border-radius: 50px;
    cursor: pointer;
    padding: .7rem;
    width: 22rem;
    background-color: ${darken(0.1, '#6f3b8c')};
  }

`