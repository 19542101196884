import styled from "styled-components";

const Container = styled.div`
  background-color: #6f3b8c;
  border: 1px solid #6f3b8c;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 40px;
  padding-bottom: 60px;

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const TextWrapper = styled.div`
  color: ${({ color }) => (color ? color : "#000")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "0px")};
  font-size: 2.2em;
  font-weight: 700;
  text-align: center;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 20px;
  }
`;

const Card = styled.div`
  color: ${({ color }) => (color ? color : "white")};
  background-color: ${({ backgrondColor }) =>
    backgrondColor ? backgrondColor : "transparent"};
  width: ${({ width }) => (width ? width : "0px")};
  height: ${({ height }) => (height ? height : "0px")};
  border: 1px solid white;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "20px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: ${({ paddingRight }) =>
    paddingRight ? paddingRight : "30px"};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : "30px")};
  text-align: center;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "30px")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  width: 100%;

  @media (min-width: 0px) and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
`;

const CardTitleWrapper = styled.div`
  color: #fec810;
  font-size: 26px;
  font-weight: 700;
  text-align: left;
  width: 100%;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 20px;
  }
`;

const CardTextWrapper = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  width: 100%;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 14px;
  }
`;

const CardDescriptionWrapper = styled.div`
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  width: 100%;
  margin-top: 10px;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 14px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  alignitems: center;
  justifycontent: center;
  border-radius: 50%;
  border-style: solid;
  border-color: #fdc028;
  border-width: 1px;
  padding: 4px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
  object-position: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100px;
`;

const ItemTextWrapper = styled.p`
  color: white;
  font-weight: 500;
  font-size: 0.8em;
`;

export {
  Container,
  TextWrapper,
  Card,
  CardContainer,
  CardTitleWrapper,
  CardTextWrapper,
  CardDescriptionWrapper,
  RowContainer,
  Image,
  ImageContainer,
  ItemTextWrapper,
};