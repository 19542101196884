import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  background-color: rgba(111, 59, 140, 0.95);
  padding-right: 10px;
  padding-left: 10px;
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 90%;
  flex-direction: column;
`;

const BarWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Bar = styled.div`
  width: ${({ width }) => (width ? `${width}px` : 100)};
  height: 5px;
  margin-left: 5px;
  background-color: ${({ active }) => (active ? "#FFFFFF" : "#808080")};
  border: 1px solid transparent;
  border-radius: 5px;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const NameWrapper = styled.p`
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0px;
`;

const DescriptionWrapper = styled.p`
  color: white;
`;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0")};
`;

export {
  Container,
  CenterContainer,
  Bar,
  BarWrapper,
  TextContainer,
  NameWrapper,
  DescriptionWrapper,
  CloseButtonContainer,
  ButtonContainer,
};
