import { useEffect, useState } from "react";

export const useBootstrapBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const { outerWidth } = window;
      let currentBreakpoint = "";

      if (outerWidth < 576) {
        currentBreakpoint = "xs";
      } else if (outerWidth >= 576 && outerWidth < 768) {
        currentBreakpoint = "sm";
      } else if (outerWidth >= 768 && outerWidth < 992) {
        currentBreakpoint = "md";
      } else if (outerWidth >= 992 && outerWidth < 1200) {
        currentBreakpoint = "lg";
      } else if (outerWidth >= 1200 && outerWidth < 1400) {
        currentBreakpoint = "xl";
      } else currentBreakpoint = "xxl";

      setBreakpoint(currentBreakpoint);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return breakpoint;
};

export const gtagPixel = (event, config) => {
  if (!event || !config || !window.gtag) return;

  return window.gtag("event", event, config);
};
