import React, { useState } from "react";

export const userAcceptedLocalStorageUsage = ["true", null].includes(
  localStorage.getItem("userAcceptedLocalStorageUsage")
);

const LocalStorageAndCookiesModal = ({}) => {
  const [open, setOpen] = useState(
    localStorage.getItem("userAcceptedLocalStorageUsage") === null
  );

  const handleAccept = () => {
    localStorage.setItem("userAcceptedLocalStorageUsage", true);
    setOpen(false);
  };

  const handleReject = () => {
    localStorage.setItem("userAcceptedLocalStorageUsage", false);
    setOpen(false);
  };

  if (!open) return <></>;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        minHeight: 100,
        backgroundColor: "#fffe",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        zIndex: 1000,
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 20,
        paddingBottom: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ maxWidth: 600 }}>
        Este site utiliza cookies e armazenamento local para gerar estatísticas
        e melhorar sua experiência de uso. Deseja permitir o uso destes
        recursos?{" "}
        <a
          href="https://pophaus-files.s3.us-east-2.amazonaws.com/Pol%C3%ADtica+de+privacidade.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Clique aqui
        </a>{" "}
        para ver nossa política de privacidade.
      </span>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ borderRadius: 4, marginLeft: 20, flexDirection: "column" }}
      >
        <button
          className="btn-primary p-2"
          style={{ borderRadius: 4, minWidth: 100 }}
          onClick={handleAccept}
        >
          Aceitar
        </button>
        <button
          className="btn"
          style={{ borderRadius: 4, fontSize: "0.8rem", marginTop: 4 }}
          onClick={handleReject}
        >
          Recusar
        </button>
      </div>
    </div>
  );
};

export default LocalStorageAndCookiesModal;
