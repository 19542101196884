import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
`;

const Container = styled.div`
  background-color: #ed5046;
  flex: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 2em;
  background-color: #ed5046;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 0px;
`;

const EmptyListText = styled.span`
  color: white;
  text-align: center;
`;

const Button = styled.div`
  height: 44px;
  min-width: 100px;
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: ${({ width }) => (width ? width : "")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  cursor: pointer;
  margin-left: auto;
`;

const AlbumThumb = styled.img`
  width: 68px;
  height: 68px;
  border-radius: 34px;
  object-fit: cover;
`;

const AlbumHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const AlbumName = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: white;
  margin-left: 18px;
`;

const GalleryContainer = styled.div`
  flex: 1;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export {
  PageContainer,
  Container,
  TitleWrapper,
  EmptyListText,
  Button,
  AlbumThumb,
  AlbumHeader,
  AlbumName,
  GalleryContainer,
};
