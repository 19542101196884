import React, { useState, useEffect } from "react";
import { SimulatorHook } from "../../../context/simulatorContext";
import { Api } from "../../../api/api";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const FinalOrderPage = () => {
  const {
    name,
    lastName,
    phone,
    email,
    setCurrentStep,
    unit,
    duration,
    date,
    regularTickets,
    kioskTarget,
    setProductsPrices,
    simulationMetricID,
    buttonsDisabled,
    setButtonsDisabled,
    doubtsId,
    setDoubtsId,
    planName,
    planModel,
    planPrice,
  } = SimulatorHook();
  setCurrentStep(4);

  const [reRender, setReRender] = useState(false);

  const navigate = useNavigate();

  if (name === "") {
    navigate("/NewSimulator/ConfirmationContainer");
  }

  useEffect(() => {
    const obj = {
      filial: unit,
      spaces: [
        {
          value: "PopIndoor",
          label: "PopIndoor",
        },
        {
          value: "PopOutdoor",
          label: "PopOutdoor",
        },
      ],
      duration: 3,
      regular: regularTickets,
      plus_one: 0,
      kids: 0,
      kids_plus_one: 0,
      pcd: 0,
      pcd_plus_one: 0,
      date: date,
      time: "Manha",
      kiosk_1: false,
      kiosk_2: false,
      kiosk_3: false,
      kiosk_4: false,
      kiosk_5: false,
      kiosk_6: false,
      kiosk_indoor: false,
      buffet: true,
      menu_1: false,
      menu_2: false,
      menu_3: false,
      menu_4: false,
      common_sock: 0,
      non_slip_sock: 0,
      cup: 0,
      towel: 0,
      cabinet_rental: 0,
      cotton_candy: 0,
      cotton_candy_machine: 0,
      exclusive_monitor: 0,
      kiosks_target: {
        label: "Sem Quiosque",
        value: "kiosk_0",
        price: 0,
      },
      model: "single",
      duration_kiosk: 3,
      bundle: {
        id: "p",
        name: planName,
        model: planModel,
        price: planPrice,
        event_duration: duration,
        players_min: 10,
        non_players_min: 5,
        players_max: 24,
        kiosks: {
          amount: 1,
          label: "kioskTarget",
          value: "kiosk_1",
        },
      },
    };
    Api.post("/party_simulator/party_price", obj)
      .then((res) => {
        setReRender(false);
        setProductsPrices(res.data.data);
      })
      .finally(() => {});
  }, [reRender]);

  function handleCreateParty({ answer, doubts = false, editDoubts = false }) {
    const obj = {
      filial: unit,
      spaces: [
        {
          value: "PopIndoor",
          label: "PopIndoor",
        },
        {
          value: "PopOutdoor",
          label: "PopOutdoor",
        },
      ],
      duration: 3,
      regular: regularTickets,
      plus_one: 0,
      kids: 0,
      kids_plus_one: 0,
      pcd: 0,
      pcd_plus_one: 0,
      date: date,
      time: "Manha",
      buffet: true,
      menu_1: false,
      menu_2: false,
      menu_3: false,
      menu_4: false,
      common_sock: 0,
      non_slip_sock: 0,
      cup: 0,
      towel: 0,
      cabinet_rental: 0,
      name: name,
      last_name: lastName,
      email: email,
      phone: phone,
      cotton_candy: 0,
      cotton_candy_machine: 0,
      exclusive_monitor: 0,
      kiosks_target: {
        label: "Sem Quiosque",
        value: "kiosk_0",
        price: 0,
      },
      scheduled: answer,
      doubts: doubts,
      crm: false,
      scheduled_by: "",
      price_total: 0,
      total_number_of_guests: 0,
      additional_info: "",
      created_at: "",
      updated_at: "",
      model: "single",
      duration_kiosk: 3,
      bundle: {
        id: "p",
        name: planName,
        model: planModel,
        price: planPrice,
        event_duration: duration,
        players_min: 0,
        non_players_min: 0,
        players_max: 0,
        kiosks: {
          amount: 1,
          label: kioskTarget,
          value: "kiosk_1",
        },
      },
    };
    if (editDoubts) {
      const response = Api.put(`/party_simulator/update_info/${doubtsId}`, obj)
        .then((res) => {
          if (answer) {
            navigate("/NewSimulator/ConfirmationContainer");
          }
          if (!answer && doubts === false) {
            if (unit === "SANTO_AMARO") {
              window.open("https://api.whatsapp.com/send?phone=5511917706110");
            } else if (unit === "BRASILIA") {
              window.open("https://api.whatsapp.com/send?phone=5561998755443");
            } else if (unit === "TATUAPE") {
              window.open("https://api.whatsapp.com/send?phone=5511917706110");
            } else {
              window.open("https://api.whatsapp.com/send?phone=5511917706110");
            }
          }
          return res;
        })
        .catch(() => {
          window.alert("Err");
        });
      return response;
    } else {
      const response = Api.post("/party_simulator/create_party", obj)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          window.alert(err);
        });
      return response;
    }
  }

  const updateSimulationMetric = (data) => {
    Api.put(`simulations_metrics/${simulationMetricID}`, data);
  };

  useEffect(() => {
    if (!doubtsId)
      handleCreateParty({ answer: false, doubts: true }).then((res) =>
        setDoubtsId(res.data.id)
      );

    updateSimulationMetric({
      ended_simulation: moment().toJSON(),
      sent_simulation: moment().toJSON(),
      sent_type: "SCHEDULE",
    });
  }, []);

  console.log("data id", doubtsId);
  console.log("simulationMetricID", simulationMetricID);

  return (
    <div className="flex justify-content-center align-content-center h-70">
      <h1 className="text-center text-secondary">
        Tudo Pronto! <br /> Escolha como você gostaria de continuar.
      </h1>
      <>
        <div className="d-flex gap-4 justify-content-center align-content-center mt-5">
          <button
            className="button__info"
            style={{ opacity: buttonsDisabled ? 0.6 : 1 }}
            disabled={doubtsId === null}
            onClick={() => {
              handleCreateParty({
                answer: false,
                doubts: false,
                editDoubts: true,
              });

              updateSimulationMetric({
                sent_simulation: moment().toJSON(),
                sent_type: "DOUBTS",
              });

              setButtonsDisabled(true);
              if (window.fbq) {
                window.fbq("track", "Lead");
              }

              if (window.gtag) {
                window.gtag('event', 'lead', {
                  send_to: 'AW-LEAD_ID/YOUR_LABEL'
                });
              }
            }}
          >
            {doubtsId === null
              ? "Carregando..."
              : "Quero tirar dúvidas sobre os pacotes"}
          </button>
          <button
            className="button__success"
            disabled={doubtsId === null}
            onClick={() => {
              handleCreateParty({
                answer: true,
                doubts: false,
                editDoubts: true,
              });

              updateSimulationMetric({
                sent_simulation: moment().toJSON(),
                sent_type: "SCHEDULE",
              });
              if (window.fbq) {
                window.fbq("track", "Lead");
              }

              if (window.gtag) {
                window.gtag('event', 'lead', {
                  send_to: 'AW-LEAD_ID/YOUR_LABEL'
                });
              }
            }}
          >
            {doubtsId === null ? "Carregando..." : "Solicitar agendamento"}
          </button>
        </div>
      </>
    </div>
  );
};
