import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getWindowDimensions } from "../../services/getWindowDimensions";
import CarouselContainer from "../../components/CarouselContainer";
import Footer from "../../components/Footer";
import SecondContainer from "./SecondContainer";
import FourthContainer from "./FourthContainer";
import FirstContainer from "./FirstContainer";
import FifthContainer from "./FifthContainer";
import { SixthContainer } from "./SixthContainer";

const Sobre = ({ mobile }) => {
  const width = getWindowDimensions().width;

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <>
      <Helmet>
        <title>Sobre o PopHaus | PopHaus</title>
      </Helmet>
      <FirstContainer />
      <SecondContainer width={width} />
      <CarouselContainer />
      <FourthContainer width={width} mobile={mobile} />
      <FifthContainer width={width} />
      <SixthContainer width={width} />
      <Footer />
    </>
  );
};

export default Sobre;
