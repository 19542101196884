import { gtagPixel } from "../../utils/util";
import partyImgSta from "../../assets/festaSantoAmaro/FestaSantoAmaro.webp";
import partyImgTtp from "../../assets/festaTatuape/FestaTatuape.webp";
import partyImg from "../../assets/festaTatuape/festaTatuape.jpg.webp";
import imageCircle from "../../assets/imageCircle.svg";
import blueImageCircle from "../../assets/blueImageCircle.svg";
import starCircle from "../../assets/starCircle.svg";
import starCircleWhite from "../../assets/starCircleWhite.svg";
import ticketCircle from "../../assets/ticketCircle.svg";
import ticketCircle1 from "../../assets/ticketCircle1.svg";
import ticketCircleWhite from "../../assets/ticketCircleWhite.svg";
import localizationCircle from "../../assets/localizationCircle.svg";
import popCornCircle from "../../assets/popCornCircle.svg";
import popCornCircleWhite from "../../assets/popCornCircleWhite.svg";
import yellowStarCircle from "../../assets/yellowStarCircle.svg";
import redStarCircle from "../../assets/redStarCircle.svg";
import redStarCircleWhite from "../../assets/redStarCircleWhiteText.svg";
import blueTicketCircle from "../../assets/blueTicketCircle.svg";
import blueTicketCircle1 from "../../assets/blueTicketCircle1.svg";
import blueTicketCirclePrice from "../../assets/blueTicketCirclePrice.svg";
import greenLocalizationCircle from "../../assets/greenLocalizationCircle.svg";
import purplePopCornCircle from "../../assets/purplePopCornCircle.svg";
import purplePopCornWhiteText from "../../assets/purplePopCornWhiteText.svg";
import clockCardIconPurple from "../../assets/clockCardIconPurple.svg";
import clockCardIcon from "../../assets/clockCardIcon.svg";
import stickersBlue from "../../assets/stickersBlue.svg";
import stickersRed from "../../assets/stickersRed.svg";
import stickersGreen from "../../assets/stickersGreen.svg";
import stickersPurple from "../../assets/stickersPurple.svg";
import candyBlue from "../../assets/candyBlue.svg";
import candyRed from "../../assets/candyRed.svg";
import candyGreen from "../../assets/candyGreen.svg";
import candyPurple from "../../assets/candyPurple.svg";
import sodaBlue from "../../assets/sodaBlue.svg";
import sodaRed from "../../assets/sodaRed.svg";
import sodaGreen from "../../assets/sodaGreen.svg";
import sodaPurple from "../../assets/sodaPurple.svg";

import festaNoturna from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-1.jpg.webp";
import festaNoturna2 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-2.jpg.webp";
import festaNoturna3 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-3.jpg.webp";
import festaNoturna4 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-4.jpg.webp";
import festaNoturna5 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-5.jpg.webp";
import festaNoturna6 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-6.jpg.webp";
import festaNoturna7 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-7.jpg.webp";
import festaNoturna8 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-8.jpg.webp";
import festaNoturna9 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-9.jpg.webp";
import festaNoturna10 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-10.jpg.webp";
import festaNoturna11 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-11.jpg.webp";
import festaNoturna12 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-12.jpg.webp";
import festaNoturna13 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-13.jpg.webp";
import festaNoturna14 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-14.jpg.webp";
import festaNoturna15 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-15.jpg.webp";
import festaNoturna16 from "../../assets/festaSantoAmaro/festaNoturna/festa-noturna-16.jpg.webp";

import { v4 as uuidv4 } from "uuid";
import NumberedCards from "./NumberedCards";
import PricesTables from "./PricesTables";
import Menus from "./Menus";
import Carousel from "./Carousel";
import Donuts from "./Donuts";
import { Api } from "../../api/api";

const popNightImages = [
  festaNoturna,
  festaNoturna2,
  festaNoturna3,
  festaNoturna4,
  festaNoturna5,
  festaNoturna6,
  festaNoturna7,
  festaNoturna8,
  festaNoturna9,
  festaNoturna10,
  festaNoturna11,
  festaNoturna12,
  festaNoturna13,
  festaNoturna14,
  festaNoturna15,
  festaNoturna16,
];

export const data = {
  SantoAmaro: {
    title: "Saiba mais sobre festas e eventos",
    description:
      "Além de festas de aniversário, o Pop também está preparado para receber eventos e confraternizações de todos os tipos! Para ter todos os detalhes, converse com o nosso time."
      ,
    actions: [
      {
        label: "FALE COM A EQUIPE DE FESTAS",
        onClick: () => {
          Api.post("website_clicks", { clicked_id: "sta_contact" });
          gtagPixel("gtm.linkClick", {
            send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
          });
          window.open("https://api.whatsapp.com/send?phone=5511917706110");
        },
      },
    ],
    image: partyImgSta,
    types: [
      {
        id: uuidv4(),
        titleComponent: (
          <span className="fw-bold text-danger fs-5">Festa Avulsa</span>
        ),
        descriptionComponent: (
          <span className="text-primary text-center">
            Sem exclusividade no parque. O PopHaus funciona normalmente e você
            recebe seus convidados.
          </span>
        ),
        categories: [
          {
            id: uuidv4(),
            image: starCircle,
            selectedImage: redStarCircle,
            label: "Como fazer minha festa",
            belowSection: (
              <NumberedCards
                list={[
                  "Defina o tipo de ingresso para cada um dos seus convidados (brincante ou não brincante, quantidade de áreas e tempo de diversão).",
                  "Escolha o espaço desejado para locação - os quiosques de frente para o Pop Outdoor ou na área Indoor. É nele que você cantará parabéns.",
                  "Escolha o cardápio que irá servir ou consuma de forma independente na lanchonete no dia da festa.",
                  "Divirta-se muito com seus convidados!",
                ]}
                className={"bg-secondary"}
              />
            ),
          },
          {
            id: uuidv4(),
            image: ticketCircle,
            selectedImage: blueTicketCircle,
            label: "Ingressos",
            belowSection: (
              <PricesTables
                list={[
                  {
                    id: uuidv4(),
                    title: "Combo duas áreas",
                    description:
                      "Ingresso que dá acesso a duas áreas a sua escolha: Jump, Indoor ou Outdoor.",
                    prices: [
                      { id: uuidv4(), a: 89, b: 99 },
                      { id: uuidv4(), a: 109, b: 119 },
                      { id: uuidv4(), a: 30, b: 35 },
                    ],
                    headers: [
                      <img src={clockCardIconPurple} alt="Ícone relógio" />,
                      "Segunda à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: ["2 horas", "3 horas", "Hora Adicional"],
                  },
                  {
                    id: uuidv4(),
                    title: "Combo três áreas",
                    description:
                      "Ingresso que dá acesso a três áreas a sua escolha: Jump, Indoor ou Outdoor.",
                    prices: [
                      { id: uuidv4(), a: 109, b: 119 },
                      { id: uuidv4(), a: 129, b: 139 },
                      { id: uuidv4(), a: 30, b: 35 },
                    ],
                    headers: [
                      <img src={clockCardIconPurple} alt="Ícone relógio" />,
                      "Segunda à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: ["2 horas", "3 horas", "Hora Adicional"],
                  },
                  {
                    id: uuidv4(),
                    title: "Day Use",
                    description:
                      "Ingresso para as três área: Jump, Indoor ou Outdoor.",
                    prices: [{ id: uuidv4(), a: 149, b: 189 }],
                    headers: [
                      <img src={clockCardIconPurple} alt="Ícone relógio" />,
                      "Segunda à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: ["6 horas"],
                  },
                  {
                    id: uuidv4(),
                    title: "Ingresso não brincante",
                    description:
                      "Dá acesso apenas às dependências do parque, mas não permite a entrada nas atrações.",
                    verticalHeaders: ["Preço único"],
                    prices: [{ id: uuidv4(), a: 30 }],
                  },
                ]}
              />
            ),
          },
          {
            id: uuidv4(),
            image: localizationCircle,
            selectedImage: greenLocalizationCircle,
            label: "Espaços",
            belowSection: (
              <PricesTables
                list={[
                  {
                    id: uuidv4(),
                    title: "Quiosque",
                    description: "Área de 24m²",
                    prices: [{ id: uuidv4(), a: 400, b: 500 }],
                    headers: [
                      <img src={clockCardIconPurple} alt="Ícone relógio" />,
                      "Segunda à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: ["3 horas"],
                  },
                ]}
              />
            ),
          },
          {
            id: uuidv4(),
            image: popCornCircle,
            selectedImage: purplePopCornCircle,
            label: "Cardápios",
            belowSection: (
              <>
                <Menus
                  list={[
                    {
                      id: uuidv4(),
                      className: "bg-info text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 1<br />
                          R$ 50 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description: "Coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersBlue}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description: "Churros de doce de leite.",
                          avatarComponent: (
                            <img src={candyBlue} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja ou uva e refrigerantes.",
                          avatarComponent: (
                            <img src={sodaBlue} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-danger text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 2<br />
                          R$ 60 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersRed}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro e churros de doce de leite.",
                          avatarComponent: (
                            <img src={candyRed} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja ou uva e refrigerantes.",
                          avatarComponent: (
                            <img src={sodaRed} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-success text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 3<br />
                          R$ 70 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersGreen}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro, brigadeiro rosa e churros de doce de leite.",
                          avatarComponent: (
                            <img
                              src={candyGreen}
                              alt="ícone doces"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja e uva, refrigerantes e água de coco.",
                          avatarComponent: (
                            <img src={sodaGreen} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      titleComponent: (
                        <p>
                          OPÇÃO 4<br />
                          R$ 80 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini pão de queijo, pipoca, empada de palmito e empada de frango.",
                          avatarComponent: (
                            <img
                              src={stickersPurple}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro, churros de doce de leite e saladinha de fruta.",
                          avatarComponent: (
                            <img
                              src={candyPurple}
                              alt="ícone doces"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja e uva, refrigerantes e água de coco.",
                          avatarComponent: (
                            <img
                              src={sodaPurple}
                              alt="ícone refri"
                              width={40}
                            />
                          ),
                        },
                      ],
                    },
                  ]}
                />
                <Donuts />
              </>
            ),
          },
        ],
        className: "bg-secondary text-primary ",
        actions: [
          {
            label: "Fale com nossa equipe",
            onClick: () => {
              gtagPixel("gtm.linkClick", {
                send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
              });
              window.open("https://api.whatsapp.com/send?phone=5511913195721" );
            },
            className: "bg-primary text-secondary",
          },
        ],
      },
      {
        id: uuidv4(),
        titleComponent: (
          <span className="fw-bold text-secondary fs-5">Festa Exclusiva</span>
        ),
        descriptionComponent: (
          <span className="text-white text-center">
            Prefere exclusividade? Com esse modelo de festa, você reserva o
            PopHaus inteirinho para você e seus convidados.
          </span>
        ),
        categories: [
          {
            id: uuidv4(),
            image: starCircleWhite,
            selectedImage: yellowStarCircle,
            label: "Como fazer minha festa",
            belowSection: (
              <NumberedCards
                list={[
                  "Defina a quantidade de áreas em que gostaria de brincar com seus convidados (2 ou 3 áreas).",
                  "Escolha o pacote ideal para o seu evento, dia e duração.",
                  "Selecione o cardápio que irá servir ou consuma de forma independente na lanchonete no dia da festa.",
                  "Agora, sua única preocupacão é se divertir com seus convidados!",
                ]}
                className={"bg-danger"}
                cardClassName={"text-white border-white"}
              />
            ),
          },
          {
            id: uuidv4(),
            image: ticketCircleWhite,
            selectedImage: blueTicketCirclePrice,
            label: "Preços",
            belowSection: (
              <PricesTables
                list={[
                  {
                    id: uuidv4(),
                    title: "Duas áreas simultâneas",
                    description:
                      "Escolha entre Jump, Indoor ou Outdoor. Para até 120 convidados.",
                    prices: [
                      { id: uuidv4(), a: 6523, b: 8910, c: 10769 },
                      { id: uuidv4(), a: 7568, b: 10857, c: 12639 },
                    ],
                    headers: [
                      <img src={clockCardIcon} alt="Ícone relógio" />,
                      "Segunda e terça",
                      "Quarta à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: ["3 horas", "4 horas"],
                    headersClassName: "text-white borer-white",
                    pricesClassName: "bg-white borer-white text-danger",
                    className: "border-white text-white",
                  },
                  {
                    id: uuidv4(),
                    title: "Três áreas simultâneas",
                    description:
                      "Jump, Indoor ou Outdoor. Para até 120 convidados.",
                    prices: [
                      { id: uuidv4(), a: 7568, b: 10857, c: 11847 },
                      { id: uuidv4(), a: 8613, b: 12804, c: 13904 },
                    ],
                    headers: [
                      <img src={clockCardIcon} alt="Ícone relógio" />,
                      "Segunda e terça",
                      "Quarta à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: ["2 horas", "3 horas", "Hora Adicional"],
                    headersClassName: "text-white borer-white",
                    pricesClassName: "bg-white borer-white text-danger",
                    className: "border-white text-white",
                  },
                ]}
                className="bg-danger"
                children={
                  <div className="text-white text-center pb-5">
                    <span className="fw-bold">
                      Atenção: a festa ocorre sempre antes ou depois do horário
                      de funcionamento do parque. Nos meses de férias escolares
                      (janeiro, julho e dezembro), os valores e horários
                      disponíveis podem ser diferentes.
                    </span>
                  </div>
                }
              />
            ),
          },
          {
            id: uuidv4(),
            image: popCornCircleWhite,
            selectedImage: purplePopCornWhiteText,
            label: "Cardápios",
            belowSection: (
              <>
                <Menus
                  list={[
                    {
                      id: uuidv4(),
                      className: "bg-info text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 1<br />
                          R$ 50 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description: "Coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersBlue}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description: "Churros de doce de leite.",
                          avatarComponent: (
                            <img src={candyBlue} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja ou uva e refrigerantes.",
                          avatarComponent: (
                            <img src={sodaBlue} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-danger text-white border border-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 2<br />
                          R$ 60 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersRed}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro e churros de doce de leite.",
                          avatarComponent: (
                            <img src={candyRed} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja ou uva e refrigerantes.",
                          avatarComponent: (
                            <img src={sodaRed} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-success text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 3<br />
                          R$ 70 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersGreen}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro, brigadeiro rosa e churros de doce de leite.",
                          avatarComponent: (
                            <img
                              src={candyGreen}
                              alt="ícone doces"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja e uva, refrigerantes e água de coco.",
                          avatarComponent: (
                            <img src={sodaGreen} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-primary text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 4<br />
                          R$ 80 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini pão de queijo, pipoca, empada de palmito e empada de frango.",
                          avatarComponent: (
                            <img
                              src={stickersPurple}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro, churros de doce de leite e saladinha de fruta.",
                          avatarComponent: (
                            <img
                              src={candyPurple}
                              alt="ícone doces"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja e uva, refrigerantes e água de coco.",
                          avatarComponent: (
                            <img
                              src={sodaPurple}
                              alt="ícone refri"
                              width={40}
                            />
                          ),
                        },
                      ],
                    },
                  ]}
                  className={"bg-danger"}
                />
                <Donuts />
              </>
            ),
          },
        ],
        className: "bg-danger text-white",
      },
      {
        id: uuidv4(),
        titleComponent: (
          <span className="fw-bold text-secondary fs-5">Festa Noturna</span>
        ),
        descriptionComponent: (
          <span className="text-white text-center">
            Faça sua festa após as 18h e brilhe muito nas atrações neon do Pop,
            nas áreas Pop Jump e Pop Indoor.
          </span>
        ),
        categories: [
          {
            id: uuidv4(),
            image: starCircleWhite,
            selectedImage: redStarCircleWhite,
            label: "Como fazer minha festa",
            belowSection: (
              <NumberedCards
                list={[
                  "Escolha entre a festa avulsa ou exclusiva.",
                  "Marque a festa após as 18h para aproveitar a iluminação vibrante.",
                  "Selecione o cardápio que irá servir.",
                  "Se quiser, contrate um DJ para animar sua festa e maquiadores com tema neon, pra você e seus convidados brilharem muito.",
                ]}
                className={"bg-primary"}
                cardClassName={"text-secondary border-secondary"}
              />
            ),
          },
          {
            id: uuidv4(),
            image: imageCircle,
            selectedImage: blueImageCircle,
            label: "Veja fotos do espaço",
            belowSection: <Carousel list={popNightImages} />,
          },
        ],
        className: "bg-primary text-white",
      },
    ],
  },
  Tatuape: {
    title: "Saiba mais sobre festas e eventos",
    description:
      "Além de festas de aniversário, o Pop também está preparado para receber eventos e confraternizações de todos os tipos! Para ter todos os detalhes, converse com o nosso time.",
    actions: [
      {
        label: "FALE COM A EQUIPE DE FESTAS",
        onClick: () => {
          Api.post("website_clicks", { clicked_id: "ttp_contact" });
          gtagPixel("gtm.linkClick", {
            send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
          });
          window.open("https://api.whatsapp.com/send?phone=5511917706110");
        },
      },
    ],
    image: partyImgTtp,
    types: [
      {
        id: uuidv4(),
        titleComponent: (
          <span className="fw-bold text-danger fs-5">Festa Avulsa</span>
        ),
        descriptionComponent: (
          <span className="text-primary text-center">
            Sem exclusividade no parque. O PopHaus funciona normalmente e você
            recebe seus convidados.
          </span>
        ),
        categories: [
          {
            id: uuidv4(),
            image: starCircle,
            selectedImage: redStarCircle,
            label: "Como fazer minha festa",
            belowSection: (
              <NumberedCards
                list={[
                  "Defina o tipo de ingresso para cada um dos seus convidados (brincante ou não brincante, quantidade de áreas e tempo de diversão).",
                  "Escolha o espaço desejado para locação - os quiosques de frente para o Pop Outdoor ou na área Indoor. É nele que você cantará parabéns.",
                  "Escolha o cardápio que irá servir ou consuma de forma independente na lanchonete no dia da festa.",
                  "Divirta-se muito com seus convidados!",
                ]}
                className={"bg-secondary"}
              />
            ),
          },
          {
            id: uuidv4(),
            image: ticketCircle,
            selectedImage: blueTicketCircle,
            label: "Ingressos",
            belowSection: (
              <PricesTables
                list={[
                  {
                    id: uuidv4(),
                    title: "Ingresso Brincante",
                    description:
                      "Dá acesso a todas as atrações do parque: Indoor e Outdoor.",
                    prices: [
                      { id: uuidv4(), a: 69, b: 79 },
                      { id: uuidv4(), a: 89, b: 99 },
                      { id: uuidv4(), a: 109, b: 119 },
                      { id: uuidv4(), a: 30, b: 35 },
                    ],
                    headers: [
                      <img src={clockCardIconPurple} alt="Ícone relógio" />,
                      "Segunda à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: [
                      "1 hora",
                      "2 horas",
                      "3 horas",
                      "Hora Adicional",
                    ],
                  },
                  {
                    id: uuidv4(),
                    title: "Ingresso não brincante",
                    description:
                      "Dá acesso apenas às dependências do parque, mas não permite a entrada nas atrações.",
                    verticalHeaders: ["Preço único"],
                    prices: [{ id: uuidv4(), a: 30 }],
                  },
                ]}
              />
            ),
          },
          {
            id: uuidv4(),
            image: localizationCircle,
            selectedImage: greenLocalizationCircle,
            label: "Espaços",
            belowSection: (
              <PricesTables
                list={[
                  {
                    id: uuidv4(),
                    title: "Quiosque",
                    description: "Área de 24m²",
                    prices: [{ id: uuidv4(), a: 400, b: 500 }],
                    headers: [
                      <img src={clockCardIconPurple} alt="Ícone relógio" />,
                      "Segunda à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: ["3 horas"],
                  },
                ]}
              />
            ),
          },
          {
            id: uuidv4(),
            image: popCornCircle,
            selectedImage: purplePopCornCircle,
            label: "Cardápios",
            belowSection: (
              <>
                <Menus
                  list={[
                    {
                      id: uuidv4(),
                      className: "bg-info text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 1<br />
                          R$ 50 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description: "Coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersBlue}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description: "Churros de doce de leite.",
                          avatarComponent: (
                            <img src={candyBlue} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja ou uva e refrigerantes.",
                          avatarComponent: (
                            <img src={sodaBlue} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-danger text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 2<br />
                          R$ 60 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersRed}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro e churros de doce de leite.",
                          avatarComponent: (
                            <img src={candyRed} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja ou uva e refrigerantes.",
                          avatarComponent: (
                            <img src={sodaRed} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-success text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 3<br />
                          R$ 70 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersGreen}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro, brigadeiro rosa e churros de doce de leite.",
                          avatarComponent: (
                            <img
                              src={candyGreen}
                              alt="ícone doces"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja e uva, refrigerantes e água de coco.",
                          avatarComponent: (
                            <img src={sodaGreen} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      titleComponent: (
                        <p>
                          OPÇÃO 4<br />
                          R$ 80 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini pão de queijo, pipoca, empada de palmito e empada de frango.",
                          avatarComponent: (
                            <img
                              src={stickersPurple}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro, churros de doce de leite e saladinha de fruta.",
                          avatarComponent: (
                            <img
                              src={candyPurple}
                              alt="ícone doces"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja e uva, refrigerantes e água de coco.",
                          avatarComponent: (
                            <img
                              src={sodaPurple}
                              alt="ícone refri"
                              width={40}
                            />
                          ),
                        },
                      ],
                    },
                  ]}
                />
                <Donuts />
              </>
            ),
          },
        ],
        className: "bg-secondary text-primary",
        actions: [
          {
            label: "Fale com nossa equipe",
            onClick: () => {
              gtagPixel("gtm.linkClick", {
                send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
              });
              window.open("https://api.whatsapp.com/send?phone=5511976338493");
            },
            className: "bg-primary text-secondary",
          },
        ],
      },
      {
        id: uuidv4(),
        titleComponent: (
          <span className="fw-bold text-secondary fs-5">Festa Exclusiva</span>
        ),
        descriptionComponent: (
          <span className="text-white text-center">
            Prefere exclusividade? Com esse modelo de festa, você reserva o
            PopHaus inteirinho para você e seus convidados.
          </span>
        ),
        categories: [
          {
            id: uuidv4(),
            image: starCircleWhite,
            selectedImage: yellowStarCircle,
            label: "Como fazer minha festa",
            belowSection: (
              <NumberedCards
                list={[
                  "Escolha o pacote ideal para o seu evento, dia e duração.",
                  "Se quiser, complete sua festa: lembrancinhas do Pop, cardápio adicional ou mais convidados.",
                  "Deixe o resto com a gente e divirta-se muito com seus convidados!",
                ]}
                className={"bg-danger"}
                cardClassName={"text-white border-white"}
              />
            ),
          },
          {
            id: uuidv4(),
            image: ticketCircleWhite,
            selectedImage: blueTicketCirclePrice,
            label: "Preços",
            belowSection: (
              <PricesTables
                list={[
                  {
                    id: uuidv4(),
                    title: "Indoor + Outdoor",
                    description:
                      "Para até 60 convidados, com cardápio 2 incluso e servido por 3 horas.",
                    prices: [
                      { id: uuidv4(), a: 6578, b: 9570, c: 10318 },
                      { id: uuidv4(), a: 7832, b: 10890, c: 11770 },
                      { id: uuidv4(), a: 8987, b: 12837, c: 13827 },
                    ],
                    headers: [
                      <img src={clockCardIcon} alt="Ícone relógio" />,
                      "Segunda e terça",
                      "Quarta à sexta",
                      "FDS e Feriados",
                    ],
                    verticalHeaders: ["2 horas", "3 horas", "4 horas"],
                    headersClassName: "text-white borer-white",
                    pricesClassName: "bg-white borer-white text-danger",
                    className: "border-white text-white",
                  },
                ]}
                className="bg-danger"
                children={
                  <div className="text-white text-center pb-5">
                    <span className="fw-bold">
                      Atenção: a festa ocorre sempre antes ou depois do horário
                      de funcionamento do parque. Nos meses de férias escolares
                      (janeiro, julho e dezembro), os valores e horários
                      disponíveis podem ser diferentes.
                    </span>
                  </div>
                }
              />
            ),
          },
          {
            id: uuidv4(),
            image: popCornCircleWhite,
            selectedImage: purplePopCornWhiteText,
            label: "Cardápios",
            belowSection: (
              <>
                <Menus
                  list={[
                    {
                      id: uuidv4(),
                      className: "bg-info text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 1<br />
                          R$ 50 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description: "Coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersBlue}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description: "Churros de doce de leite.",
                          avatarComponent: (
                            <img src={candyBlue} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja ou uva e refrigerantes.",
                          avatarComponent: (
                            <img src={sodaBlue} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-danger text-white border border-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 2<br />
                          R$ 60 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersRed}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro e churros de doce de leite.",
                          avatarComponent: (
                            <img src={candyRed} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja ou uva e refrigerantes.",
                          avatarComponent: (
                            <img src={sodaRed} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-success text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 3<br />
                          R$ 70 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini coxinha, bolinha de queijo e pipoca.",
                          avatarComponent: (
                            <img
                              src={stickersGreen}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro, brigadeiro rosa e churros de doce de leite.",
                          avatarComponent: (
                            <img
                              src={candyGreen}
                              alt="ícone doces"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja e uva, refrigerantes e água de coco.",
                          avatarComponent: (
                            <img src={sodaGreen} alt="ícone refri" width={40} />
                          ),
                        },
                      ],
                    },
                    {
                      id: uuidv4(),
                      className: "bg-primary text-white",
                      titleComponent: (
                        <p>
                          OPÇÃO 4<br />
                          R$ 80 por pessoa
                          <br />
                          (MÍNIMO 15)
                        </p>
                      ),
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini pão de queijo, pipoca, empada de palmito e empada de frango.",
                          avatarComponent: (
                            <img
                              src={stickersPurple}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, brigadeiro, churros de doce de leite e saladinha de fruta.",
                          avatarComponent: (
                            <img
                              src={candyPurple}
                              alt="ícone doces"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de laranja e uva, refrigerantes e água de coco.",
                          avatarComponent: (
                            <img
                              src={sodaPurple}
                              alt="ícone refri"
                              width={40}
                            />
                          ),
                        },
                      ],
                    },
                  ]}
                  className={"bg-danger"}
                />
                <Donuts />
              </>
            ),
          },
        ],
        className: "bg-danger text-white",
      },
    ],
  },
  Brasilia: {
    title: "Faça Sua Festa no PopHaus Brasília",
    description:
      "Imagina comemorar com seus amigos no maior parque de infláveis e trampolins da América Latina?! Seja compartilhada ou exclusiva, a festa no PopHaus é sempre sinônimo de diversão.",
    actions: [
      {
        label: "Fale com nossa equipe",
        onClick: () => {
          gtagPixel("gtm.linkClick", {
            send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
          });
          window.open("https://api.whatsapp.com/send?phone=5561998755443");
        },
      },
    ],
    image: partyImg,
    types: [
      {
        id: uuidv4(),
        titleComponent: (
          <span className="fw-bold text-danger fs-5">Festa Compartilhada</span>
        ),
        descriptionComponent: (
          <span className="text-primary text-center">
            O parque continua aberto ao público normalmente, e você recebe seus
            convidados em uma espaço reservado!
            <br />
            <br />
            As festas compartilhadas são sempre aos finais de semana, e têm 3
            horas de duração.
          </span>
        ),
        categories: [
          {
            id: uuidv4(),
            image: ticketCircle1,
            selectedImage: blueTicketCircle1,
            label: "Pacotes",
            belowSection: (
              <Menus
                list={[
                  {
                    id: uuidv4(),
                    className: "bg-info",
                    titleComponent: (
                      <div className="d-flex flex-column text-center align-items-center pb-4">
                        <span className="text-primary fw-bolder fs-4">
                          COMPARTILHADA P
                        </span>
                        <span className="text-white">
                          Sáb e dom.: R$ 2440,00
                        </span>
                      </div>
                    ),
                    categories: [
                      {
                        id: uuidv4(),
                        name: "Duração do evento",
                        description: "3 horas.",
                      },
                      {
                        id: uuidv4(),
                        name: "Data do evento",
                        description: "sábado ou domingo.",
                      },
                      {
                        id: uuidv4(),
                        name: "Horário",
                        description: "Horário: 10h às 13h ou das 15h às 18h",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidados",
                        description: "15 (sendo 10 brincantes).",
                      },
                      {
                        id: uuidv4(),
                        name: "Espaço privativo",
                        description: "Quiosque de 30m².",
                      },
                      {
                        id: uuidv4(),
                        name: "Decoração PopHaus",
                      },
                      {
                        id: uuidv4(),
                        name: "Buffet da casa incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convite virtual incluso",
                      },
                    ],
                  },
                  {
                    id: uuidv4(),
                    className: "bg-danger",
                    titleComponent: (
                      <div className="d-flex flex-column text-center align-items-center pb-4">
                        <span className="text-primary fw-bolder fs-4">
                          COMPARTILHADA M
                        </span>
                        <span className="text-white">
                          Sáb e dom.: R$ 4180,00
                        </span>
                      </div>
                    ),
                    categories: [
                      {
                        id: uuidv4(),
                        name: "Duração do evento",
                        description: "3 horas.",
                      },
                      {
                        id: uuidv4(),
                        name: "Data do evento",
                        description: "sábado ou domingo.",
                      },
                      {
                        id: uuidv4(),
                        name: "Horário",
                        description: "Horário: 10h às 13h ou das 15h às 18h",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidados",
                        description: "30 (sendo 20 brincantes).",
                      },
                      {
                        id: uuidv4(),
                        name: "Espaço privativo",
                        description: "Quiosque de 30m².",
                      },
                      {
                        id: uuidv4(),
                        name: "Decoração PopHaus",
                      },
                      {
                        id: uuidv4(),
                        name: "Buffet da casa incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convite virtual incluso",
                      },
                    ],
                  },
                  {
                    id: uuidv4(),
                    className: "bg-success",
                    titleComponent: (
                      <div className="d-flex flex-column text-center align-items-center pb-4">
                        <span className="text-primary fw-bolder fs-4">
                          COMPARTILHADA G
                        </span>
                        <span>Sáb e dom.: R$ 6170,00</span>
                      </div>
                    ),
                    categories: [
                      {
                        id: uuidv4(),
                        name: "Duração do evento",
                        description: "3 horas.",
                      },
                      {
                        id: uuidv4(),
                        name: "Data do evento",
                        description: "sábado ou domingo.",
                      },
                      {
                        id: uuidv4(),
                        name: "Horário",
                        description: "Horário: 10h às 13h ou das 15h às 18h",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidados",
                        description: "45 (sendo 30 brincantes).",
                      },
                      {
                        id: uuidv4(),
                        name: "Espaço privativo",
                        description: "Quiosque de 60m².",
                      },
                      {
                        id: uuidv4(),
                        name: "Decoração PopHaus",
                      },
                      {
                        id: uuidv4(),
                        name: "Buffet da casa incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convite virtual incluso",
                      },
                    ],
                  },
                ]}
              />
            ),
          },
          {
            id: uuidv4(),
            image: popCornCircle,
            selectedImage: purplePopCornCircle,
            label: "Cardápios",
            belowSection: (
              <>
                <Menus
                  list={[
                    {
                      id: uuidv4(),
                      className: "bg-info text-white",
                      titleComponent: <p>Buffet da casa</p>,
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini cachorro-quente, mini pizza, mini X-burguer, batata frita, pipoca, coxinha, pão de queijo, quibe.",
                          avatarComponent: (
                            <img
                              src={stickersBlue}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, ninho, brigadeiro e pipoca doce.",
                          avatarComponent: (
                            <img src={candyBlue} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de caju ou uva e refrigerante.",
                          avatarComponent: (
                            <img src={sodaBlue} alt="ícone refri" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Entre em contato com a equipe para personalizar o cardápio!",
                        },
                      ],
                    },
                  ]}
                />
              </>
            ),
          },
          {
            id: uuidv4(),
            image: starCircle,
            selectedImage: redStarCircle,
            label: "Espaços",
            belowSection: (
              <NumberedCards
                list={[
                  "Defina o tipo de festa ideal para você e seus convidados: compartilhada, semi-exclusiva e exclusiva.",
                  "Escolha o pacote que melhor atender as suas necessidades.",
                  "Se quiser, personalize sua festa com opções de cardápio e lembrancinhas! Agora, é só deixar com a gente!",
                ]}
                className={"bg-secondary"}
                cardClassName={"text-primary border-primary"}
              />
            ),
          },
        ],
        className: "bg-secondary text-primary",
        actions: [
          {
            label: "Fale com nossa equipe",
            onClick: () => {
              gtagPixel("gtm.linkClick", {
                send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
              });
              window.open("https://api.whatsapp.com/send?phone=5561998253145");
            },
            className: "bg-primary text-secondary",
          },
        ],
      },
      {
        id: uuidv4(),
        titleComponent: (
          <span className="fw-bold text-secondary fs-5">
            Festa Semi-Exclusiva
          </span>
        ),
        descriptionComponent: (
          <span className="text-white text-center">
            O parque não abre para o público e você compartilha o horário apenas
            com outras festas, mas seus convidados tem uma espaço reservado. As
            festas semi-exclusivas acontecem sempre sexta-feira.
          </span>
        ),
        categories: [
          {
            id: uuidv4(),
            image: ticketCircleWhite,
            selectedImage: blueTicketCirclePrice,
            label: "Preços",
            belowSection: (
              <Menus
                list={[
                  {
                    id: uuidv4(),
                    className: "bg-danger border border-white",
                    titleComponent: (
                      <div className="d-flex flex-column text-center align-items-center pb-4">
                        <span className="text-primary fw-bolder fs-4">
                          SEMI-EXCLUSIVA P
                        </span>
                        <span className="text-white">
                          Sáb e dom.: R$ 3550,00
                        </span>
                      </div>
                    ),
                    categories: [
                      {
                        id: uuidv4(),
                        name: "Duração do evento",
                        description: "4 horas.",
                      },
                      {
                        id: uuidv4(),
                        name: "Data do evento",
                        description: "Sexta Feira.",
                      },
                      {
                        id: uuidv4(),
                        name: "Horário",
                        description: "14h às 18h.",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidados",
                        description: "25 brincantes.",
                      },
                      {
                        id: uuidv4(),
                        name: "Espaço privativo",
                        description: "Quiosque de 30m².",
                      },
                      {
                        id: uuidv4(),
                        name: "Decoração PopHaus",
                      },
                      {
                        id: uuidv4(),
                        name: "Buffet da casa incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convite virtual incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Monitor assistente incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidado adicional",
                        description: "R$ 125 por pessoa.",
                      },
                    ],
                  },
                  {
                    id: uuidv4(),
                    className: "bg-success",
                    titleComponent: (
                      <div className="d-flex flex-column text-center align-items-center pb-4">
                        <span className="text-primary fw-bolder fs-4">
                          SEMI-EXCLUSIVA M
                        </span>
                        <span style={{ color: "white" }}>
                          Sáb e dom.: R$ 5.025,00
                        </span>
                      </div>
                    ),
                    categories: [
                      {
                        id: uuidv4(),
                        name: "Duração do evento",
                        description: "4 horas.",
                      },
                      {
                        id: uuidv4(),
                        name: "Data do evento",
                        description: "Sexta Feira.",
                      },
                      {
                        id: uuidv4(),
                        name: "Horário",
                        description: "14h às 18h.",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidados",
                        description: "40 brincantes.",
                      },
                      {
                        id: uuidv4(),
                        name: "Espaço privativo",
                        description: "Quiosque de 30m².",
                      },
                      {
                        id: uuidv4(),
                        name: "Decoração PopHaus",
                      },
                      {
                        id: uuidv4(),
                        name: "Buffet da casa incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convite virtual incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Monitor assistente incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidado adicional",
                        description: "R$ 130 por pessoa.",
                      },
                    ],
                  },
                ]}
                className={"bg-danger"}
              />
            ),
          },
          {
            id: uuidv4(),
            image: popCornCircleWhite,
            selectedImage: purplePopCornWhiteText,
            label: "Cardápios",
            belowSection: (
              <>
                <Menus
                  list={[
                    {
                      id: uuidv4(),
                      className: "bg-info text-white",
                      titleComponent: <p>Buffet da casa</p>,
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini cachorro-quente, mini pizza, mini X-burguer, batata frita, pipoca, coxinha, pão de queijo, quibe.",
                          avatarComponent: (
                            <img
                              src={stickersBlue}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, ninho, brigadeiro e pipoca doce.",
                          avatarComponent: (
                            <img src={candyBlue} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de caju ou uva e refrigerante.",
                          avatarComponent: (
                            <img src={sodaBlue} alt="ícone refri" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Entre em contato com a equipe para personalizar o cardápio!",
                        },
                      ],
                    },
                  ]}
                  className={"bg-danger"}
                />
              </>
            ),
          },
          {
            id: uuidv4(),
            image: starCircleWhite,
            selectedImage: yellowStarCircle,
            label: "Como fazer minha festa",
            belowSection: (
              <NumberedCards
                list={[
                  "Defina o tipo de festa ideal para você e seus convidados: compartilhada, semi-exclusiva e exclusiva.",
                  "Escolha o pacote que melhor atender as suas necessidades.",
                  "Se quiser, personalize sua festa com opções de cardápio e lembrancinhas! Agora, é só deixar com a gente!",
                ]}
                className={"bg-danger"}
                cardClassName={"text-white border-white"}
              />
            ),
          },
        ],
        className: "bg-danger text-white",
      },
      {
        id: uuidv4(),
        titleComponent: (
          <span className="fw-bold text-secondary fs-5">Festa Exclusiva</span>
        ),
        descriptionComponent: (
          <span className="text-white text-center">
            O parque inteirinho pra você e seus convidados! O parque abre apenas
            para receber sua festa, de segunda a sexta, e seus convidados
            brincam livremente em ambas áreas durante o período.
          </span>
        ),
        categories: [
          {
            id: uuidv4(),
            image: ticketCircleWhite,
            selectedImage: blueTicketCirclePrice,
            label: "Preços",
            belowSection: (
              <Menus
                list={[
                  {
                    id: uuidv4(),
                    className: "bg-success",
                    titleComponent: (
                      <div className="d-flex flex-column text-center align-items-center pb-4">
                        <span className="text-primary fw-bolder fs-4">
                          EXCLUSIVA M
                        </span>
                        <span className="text-white">
                          Sáb a sex.: R$ 7500,00
                        </span>
                      </div>
                    ),
                    categories: [
                      {
                        id: uuidv4(),
                        name: "Duração do evento",
                        description: "4 horas.",
                      },
                      {
                        id: uuidv4(),
                        name: "Data do evento",
                        description: "segunda à sexta.",
                      },
                      {
                        id: uuidv4(),
                        name: "Horário",
                        description: "À sua escolha.",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidados",
                        description: "60 brincantes.",
                      },
                      {
                        id: uuidv4(),
                        name: "Espaço privativo",
                        description: "Quiosque de 30m².",
                      },
                      {
                        id: uuidv4(),
                        name: "Decoração PopHaus",
                      },
                      {
                        id: uuidv4(),
                        name: "Buffet da casa incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convite virtual incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Monitor assistente incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidado adicional",
                        description: "R$ 125 por pessoa.",
                      },
                      {
                        id: uuidv4(),
                        name: "Guarda-volumes para os convidados",
                      },
                    ],
                  },
                  {
                    id: uuidv4(),
                    className: "bg-danger",
                    titleComponent: (
                      <div className="d-flex flex-column text-center align-items-center pb-4">
                        <span className="text-primary fw-bolder fs-4">
                          EXCLUSIVA G
                        </span>
                        <span className="text-white">
                          Sáb a sex.: R$ 11500,00
                        </span>
                      </div>
                    ),
                    categories: [
                      {
                        id: uuidv4(),
                        name: "Duração do evento",
                        description: "4 horas.",
                      },
                      {
                        id: uuidv4(),
                        name: "Data do evento",
                        description: "segunda à sexta.",
                      },
                      {
                        id: uuidv4(),
                        name: "Horário",
                        description: "À sua escolha.",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidados",
                        description: "100 brincantes.",
                      },
                      {
                        id: uuidv4(),
                        name: "Espaço privativo",
                        description: "Quiosque de 30m².",
                      },
                      {
                        id: uuidv4(),
                        name: "Decoração PopHaus",
                      },
                      {
                        id: uuidv4(),
                        name: "Buffet da casa incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convite virtual incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Monitor assistente incluso",
                      },
                      {
                        id: uuidv4(),
                        name: "Convidado adicional",
                        description: "R$ 115 por pessoa.",
                      },
                      {
                        id: uuidv4(),
                        name: "Guarda-volumes para os convidados",
                      },
                    ],
                  },
                ]}
                className={"bg-primary"}
              />
            ),
          },
          {
            id: uuidv4(),
            image: popCornCircleWhite,
            selectedImage: purplePopCornWhiteText,
            label: "Cardápios",
            belowSection: (
              <>
                <Menus
                  list={[
                    {
                      id: uuidv4(),
                      className: "bg-info text-white",
                      titleComponent: <p>Buffet da casa</p>,
                      categories: [
                        {
                          id: uuidv4(),
                          name: "Salgadinhos",
                          description:
                            "Mini cachorro-quente, mini pizza, mini X-burguer, batata frita, pipoca, coxinha, pão de queijo, quibe.",
                          avatarComponent: (
                            <img
                              src={stickersBlue}
                              alt="ícone pipoca"
                              width={40}
                            />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Docinhos",
                          description:
                            "Beijinho, ninho, brigadeiro e pipoca doce.",
                          avatarComponent: (
                            <img src={candyBlue} alt="ícone doces" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Bebidas",
                          description:
                            "Água, suco de caju e uva e refrigerante.",
                          avatarComponent: (
                            <img src={sodaBlue} alt="ícone refri" width={40} />
                          ),
                        },
                        {
                          id: uuidv4(),
                          name: "Entre em contato com a equipe para personalizar o cardápio!",
                        },
                      ],
                    },
                  ]}
                  className={"bg-primary"}
                />
              </>
            ),
          },
          {
            id: uuidv4(),
            image: starCircleWhite,
            selectedImage: yellowStarCircle,
            label: "Como fazer minha festa",
            belowSection: (
              <NumberedCards
                list={[
                  "Defina o melhor dia para a sua festa exclusiva.",
                  "Se quiser, personalize sua festa com opções de cardápio e lembrancinhas.",
                  "Agora, é só se divertir e deixar que a gente cuida de tudo!",
                ]}
                className={"bg-primary"}
                cardClassName={"text-white border-white"}
              />
            ),
          },
        ],
        className: "bg-primary text-white",
      },
    ],
  },
};
