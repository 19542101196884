import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./styles.scss";
import * as S from "../../pages/Unidades/styles";

export const PDFViewer = ({ pdf, PDFName, buttonText }) => {
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openModal = () => {
    if (isMobile) {
      window.open(`${pdf}`, "_blank");
    } else {
      setShowModal(true);
    }
  };

  const closeModal = () => setShowModal(false);

  const handleClick = () => {
    setShowModal(false);
  };

  return (
    <div>
      <S.Button  className="button" onClick={openModal}>
        {buttonText}
      </S.Button>
      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        centered
        className={isMobile ? "dpf-viewer__mobile" : "dpf-viewer__desk"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{PDFName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe title="PDF Viewer" src={pdf} width="100%" height="100%" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClick}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};