import React from "react";
import { getWindowDimensions } from "../../services/getWindowDimensions";
import MobileFooter from "../MobileFooter";
import DesktopFooter from "../DesktopFooter";

const Footer = () => {
  const width = getWindowDimensions().width;
  return (
    <div id={"footer"}>
      {width > 1300 ? <DesktopFooter /> : <MobileFooter />}
    </div>
  );
};

export default Footer;
