import styled from "styled-components";

const Container = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#6F3B8C"};
  margin: 0;
  padding: 12px;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #fdc028;
  border-radius: 12px;
  background-color: #fdc028;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
`;

const ColumnItem = styled.div`
  color: white;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "600")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.8em")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "10px")};
  cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
  display: flex;
`;

const ColumnTitle = styled.div`
  color: white;
  font-weight: 700;
`;

export {
  Container,
  LogoWrapper,
  IconWrapper,
  IconContainer,
  ColumnItem,
  ColumnTitle,
};
