import React from "react";
import Carousel from "./index";
import { ReactComponent as CloseIcon } from "../../../assets/closeIcon.svg";

const Modal = ({ list = [], handleClose, startIndex = 0 }) => {
  return (
    <div style={styles.container()}>
      {handleClose && (
        <CloseIcon onClick={handleClose} style={styles.closeIcon()} />
      )}
      <Carousel list={list} modal startIndex={startIndex} />
    </div>
  );
};

const styles = {
  container: () => ({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#ed5046cc",
    zIndex: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  }),
  closeIcon: () => ({
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
    height: 40,
    backgroundColor: "#6F3B8C",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: 20,
    padding: "6px 0px 6px 2px",
    cursor: "pointer",
  }),
};

export default Modal;
