import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const Carousel = ({ list, className }) => {
  const carouselId = uuidv4();
  const id = `bs_carousel_${carouselId}`;
  const [active] = useState(list[0]);

  const renderCarousel = () => {
    return (
      <div className="p-5">
        <div id={id} className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {list.map((imgSrc) => (
              <div
                key={`carousel_item_${imgSrc}`}
                className={`carousel-item ${imgSrc === active ? "active" : ""}`}
              >
                <img
                  src={imgSrc}
                  className="d-block w-100 rounded-5"
                  alt={imgSrc}
                  style={{ maxHeight: "70vh", objectFit: "cover" }}
                />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target={`#${id}`}
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target={`#${id}`}
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={`rounded-top-4 w-100 ${className || "bg-primary"}`}>
      <div className="container">{renderCarousel()}</div>
    </div>
  );
};

export default Carousel;
