import React from "react";
import mobileMenuIcon from "../../assets/mobileIcons/mobileMenuIcon.svg";
import logoHeader from "../../assets/logoHeader.svg";
import peopleIcon from "../../assets/peopleIcon.svg";
import { useNavigate } from "react-router-dom";
import { getWindowDimensions } from "../../services/getWindowDimensions";

const MobileHeader = ({ backgroundColor, setMobileMenu, mobileMenu }) => {
  const navigate = useNavigate();

  const renderLogin = () => {
    window.open(
      `${process.env.REACT_APP_OLD_WEBSITE_URL}/login?origem=website`,
      "_self"
    );
  };

  const width = getWindowDimensions().width;

  return (
    <div
      className="d-flex justify-content-between"
      style={{
        padding: 10,
        backgroundColor: backgroundColor ? backgroundColor : "#6F3B8C",
      }}
      id="header"
    >
      <img
        src={logoHeader}
        alt={"Logo"}
        onClick={() => navigate("/")}
        width={width > 300 ? 150 : 100}
      />
      <div>
        <img
          src={peopleIcon}
          alt="People Icon"
          style={{ cursor: "pointer" }}
          onClick={renderLogin}
        />

        <img
          src={mobileMenuIcon}
          alt={"Mobile Icon"}
          style={{ cursor: "pointer" }}
          onClick={() => setMobileMenu(!mobileMenu)}
        />
      </div>
    </div>
  );
};

export default MobileHeader;
