import styled from "styled-components";

const Container = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#6F3B8C"};
  height: 139px;
  margin: 0px;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;

const MenuContainer = styled.div``;

const ButtonsWrapper = styled.div`
  margin: 0
`;

const BuyButton = styled.div`
  background-color: #fdc028;
  height: 44px;
  border: 1px solid #fdc028;
  border-radius: 20px;
  color: #6f3b8c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;

export { Container, LogoWrapper, MenuContainer, ButtonsWrapper, BuyButton };
