import React from "react";
import * as S from "../styles";
import Card from "./Card";
import clockCardIcon from "../../../../assets/clockCardIcon.svg";
import { objectMapper } from "../../Objects";
import { getWindowDimensions } from "../../../../services/getWindowDimensions";

const CardsThirdContainerBrasilia = () => {
  const data = objectMapper.Brasilia;
  return (
    <>
      <S.CardContainer>
        <Card
          title={"Ingresso Brincante"}
          text={"Dá acesso a todas as atrações do parque:"}
          width={getWindowDimensions().width > 530 ? "521px" : "100%"}
          height={"350px"}
          paddingLeft={getWindowDimensions().width > 530 ? "50px" : "10px"}
          paddingRight={getWindowDimensions().width > 530 ? "50px" : "10px"}
          marginLeft={getWindowDimensions().width > 530 ? "30px" : "0px"}
        >
          <div className="w-100 d-flex flex-row justify-content-center mt-2">
            <div className="d-flex flex-column">
              <S.Card
                width={getWindowDimensions().width > 530 ? "106px" : "80px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                paddingRight={"0px"}
                paddingLeft={"0px"}
                marginLeft={getWindowDimensions().width > 530 ? "30px" : "0px"}
              >
                <img src={clockCardIcon} alt={"Icone de relogio"} />
              </S.Card>
              <S.Card
                width={getWindowDimensions().width > 530 ? "106px" : "80px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                paddingRight={"0px"}
                paddingLeft={"0px"}
                marginTop={"5px"}
                marginLeft={getWindowDimensions().width > 530 ? "30px" : "0px"}
              >
                2 horas
              </S.Card>
              <S.Card
                width={getWindowDimensions().width > 530 ? "106px" : "80px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                paddingRight={"0px"}
                paddingLeft={"0px"}
                marginTop={"5px"}
                marginLeft={getWindowDimensions().width > 530 ? "30px" : "0px"}
              >
                3 horas
              </S.Card>
              <S.Card
                width={getWindowDimensions().width > 530 ? "106px" : "80px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                paddingRight={"0px"}
                paddingLeft={"0px"}
                marginTop={"5px"}
                marginLeft={getWindowDimensions().width > 530 ? "30px" : "0px"}
              >
                6 horas
              </S.Card>
              <S.Card
                width={getWindowDimensions().width > 530 ? "106px" : "80px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                paddingRight={"0px"}
                paddingLeft={"0px"}
                marginTop={"5px"}
                marginLeft={getWindowDimensions().width > 530 ? "30px" : "0px"}
              >
                Hora Adicional
              </S.Card>
            </div>
            <div className="d-flex flex-column">
              <S.Card
                width={getWindowDimensions().width > 530 ? "154px" : "115px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                marginLeft={"5px"}
                paddingRight={"0px"}
                paddingLeft={"0px"}
              >
                FDS e Feriados
              </S.Card>
              <S.Card
                width={getWindowDimensions().width > 530 ? "154px" : "115px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                marginLeft={"5px"}
                marginTop={"5px"}
                backgrondColor={"white"}
                color={"#6F3B8C"}
                weight={"500"}
              >
                {data.DuasAreas.DuasHoras.FdsEFeriado}
              </S.Card>
              <S.Card
                width={getWindowDimensions().width > 530 ? "154px" : "115px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                marginLeft={"5px"}
                marginTop={"5px"}
                backgrondColor={"white"}
                color={"#6F3B8C"}
                weight={"500"}
              >
                {data.DuasAreas.TresHoras.FdsEFeriado}
              </S.Card>
              <S.Card
                width={getWindowDimensions().width > 530 ? "154px" : "115px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                marginLeft={"5px"}
                marginTop={"5px"}
                backgrondColor={"white"}
                color={"#6F3B8C"}
                weight={"500"}
              >
                {data.DuasAreas.SeisHoras.FdsEFeriado}
              </S.Card>
              <S.Card
                width={getWindowDimensions().width > 530 ? "154px" : "115px"}
                height={getWindowDimensions().width > 530 ? "40px" : "50px"}
                borderRadius={"5px"}
                marginLeft={"5px"}
                marginTop={"5px"}
                backgrondColor={"white"}
                color={"#6F3B8C"}
                weight={"500"}
              >
                {data.DuasAreas.HoraAdicional.FdsEFeriado}
              </S.Card>
            </div>
          </div>
        </Card>

        <Card
          title={"Ingresso não brincante"}
          text={
            "Dá acesso apenas às dependências do parque, mas não permite a entrada nas atrações."
          }
          width={getWindowDimensions().width > 530 ? "521px" : "100%"}
          height={"280px"}
          paddingLeft={getWindowDimensions().width > 530 ? "50px" : "10px"}
          paddingRight={getWindowDimensions().width > 530 ? "50px" : "10px"}
          marginLeft={getWindowDimensions().width > 530 ? "30px" : "0px"}
        >
          <div className="w-100 d-flex flex-row justify-content-center mt-2">
            <div className="d-flex flex-column">
              <S.Card
                width={getWindowDimensions().width > 530 ? "266px" : "180px"}
                height={"32px"}
                borderRadius={"5px"}
                marginLeft={"5px"}
                paddingRight={"0px"}
                paddingLeft={"0px"}
              >
                Preço único
              </S.Card>
            </div>
            <div className="d-flex flex-column">
              <S.Card
                width={getWindowDimensions().width > 530 ? "153px" : "115px"}
                height={"32px"}
                borderRadius={"5px"}
                marginLeft={"5px"}
                backgrondColor={"white"}
                color={"#6F3B8C"}
                weight={"500"}
              >
                {data.NaoBrincante.SegundaASexta}
              </S.Card>
            </div>
          </div>
        </Card>
      </S.CardContainer>
    </>
  );
};

export default CardsThirdContainerBrasilia;
