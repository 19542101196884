import styled from "styled-components";

const Container = styled.div`
  margin: 0px;
  background-repeat: no-repeat;
  min-height: 650px;
  ${({ mobile }) =>
    mobile
      ? "height: calc(100vh - 56px); padding-bottom: 50px;"
      : "height: 100vh;"}
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 50px;
  ${({ mobile }) => (mobile ? "justify-content: flex-end;" : "")}
`;

const TitleWrapper = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 2em;

  @media (min-width: 0px) and (max-width: 800px) {
    max-width: 300px;
    font-size: 26px;
  }
`;

const SubtitleWrapper = styled.div`
  color: white;
  font-weight: 400;
  font-size: 1.2em;
  text-align: center;

  @media (min-width: 0px) and (max-width: 800px) {
    max-width: 300px;
    font-size: 16px;
  }
`;

const Button = styled.button`
  width: 303px;
  height: 47px;
  background-color: #fdc028;
  border: 1px solid #fdc028;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f3b8c;
  font-weight: 700;
  font-size: 1em;
  margin-top: 20px;
`;

export { Container, CenterContainer, TitleWrapper, SubtitleWrapper, Button };
