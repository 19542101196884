import styled from "styled-components";
import { darken } from "polished";
import media from "styled-media-query";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6f3b8c;
  padding-bottom: 34px;
`;


export const ContainerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 550px;
  .select-area {
    background-color: red;
    border-radius: 50px;
    cursor: pointer;
    padding: .7rem;
    width: 22rem;
    width: 100%;
    background-color: ${darken(0.1, '#6f3b8c')};
  }
  @media(max-width: 700px) {
    padding: 0 36px;
  }

  .select {
    background-color: ${darken(0.1, '#6f3b8c')};
    border: none;
    color: #fff;
  }
`;