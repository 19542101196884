import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import { SimulatorHook } from "../../../context/simulatorContext";

export const ConfirmationContainer = () => {
  const navigate = useNavigate();
  const {
    setCurrentStep,
  } = SimulatorHook();
  useEffect(() => {
    setCurrentStep(7)
  }, []);
  return (
    <S.Container>
      <S.ContainerWrapper>
        <h2>Sua solicitação de agendamento foi enviada com sucesso!</h2>

        <p>
          Nossa equipe de eventos irá validar a sua solicitação e entrará em
          contato por WhatsApp para confirmar a disponibilidade da data e
          período.
        </p>

        <p>
          Após validação, você receberá um link para pagar seu evento e
          confirmar a sua reserva."
        </p>

        <button onClick={() => navigate("/")} className="button__success">
          Voltar para a Homepage
        </button>
      </S.ContainerWrapper>
    </S.Container>
  );
};
