import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import * as S from "./styles";

const Duvidas = () => {
  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);
  return (
    <>
      <Header />
      <S.TitleContainer>
        <S.TitleWrapper>Página Padráo</S.TitleWrapper>
      </S.TitleContainer>
      <S.CenterContainer>
        <S.TextWrapper fontSize={"1.2em"}>Título</S.TextWrapper>
        <S.TextWrapper>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
          turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
          fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus
          elit sed risus. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
          dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus,
          ut interdum tellus elit sed risus. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a,
          mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem
          sollicitudin lacus, ut interdum tellus elit sed risus. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie,
          dictum est a, mattis tellus. Sed dignissim, metus nec fringilla
          accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed
          risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
          eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
          nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
          tellus elit sed risus. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit.{" "}
        </S.TextWrapper>
      </S.CenterContainer>
      <Footer />
    </>
  );
};

export default Duvidas;
