import styled from "styled-components";

export const Container = styled.div`
  margin: 10px 0 24px 0;
  .wrapper_steps{
    width: 580px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10px;
    background-color: #F8C70E;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    display: none;
    .wrapper_steps{
      display: none;
    }
  }

  div.wrapper_steps div {
      width: 32px;
      background-color: #F8C70E;
      height: 32px;
      border: 2px solid #F8C70E;
      border-radius: 50%;
      @media (max-width: 768px) {
        display: none;
    }
  }
  div.wrapper_steps div.point-selected {
    background-color: #6f3b8c !important;
  }
  
`;
export const Point = styled.div`
 
`;
