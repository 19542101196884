import React from "react";
import * as S from "./styles";
import aboutSecondIcon from "../../../assets/aboutSecondIcon.svg";
import SwipeTimeline from "../../../components/SwipeTimeline";

const timeline = [
  { date: "Julho de 2017", name: "Inauguração do Pop Santo Amaro." },
  {
    date: "Outubro de 2017",
    name: "O Slip N' Slide chegou ao Pop, inaugurando a era dos escorregadores infláveis aquáticos, queridinhos da galera.",
  },
  {
    date: "Julho de 2018",
    name: "Primeira festa de aniversário no parque. Desde então, milhares de aniversários foram comemorados nos nossos espaços.",
  },
  {
    date: "Setembro de 2018",
    name: "Chegada do primeiro Pop Zone, inflável gigante que reúne inúmeras atrações e é marca registrada do PopHaus.",
  },
  { date: "Fevereiro de 2019", name: "Primeira edição do CarnaPop." },
  {
    date: "Setembro de 2020",
    name: "Começa o Pop Dia Feliz, uma das iniciativas sociais do PopHaus.",
  },
  {
    date: "Fevereiro de 2021",
    name: "O PopHaus Santo Amaro passa a ter uma terceira área e muitos pulos - o Pop Jump.",
  },
  { date: "Julho de 2021", name: "Inaguração da unidade Tatuapé." },
  { date: "Outubro de 2021", name: "Primeira edição do ScareHaus." },
  {
    date: "Outubro de 2022",
    name: "O primeiro brinquedo inflável neon do mundo, o novo Pop Zone, chega ao PopHaus Santo Amaro, com 832m².",
  },
];

const FourthContainer = ({ width, mobile }) => {
  return (
    <S.Container className="row" mobile={mobile}>
      <S.TextContainer className="col-sm-12 col-md-6">
        <S.TitleContainer>
          <S.TitleWrapper>Linha do Tempo</S.TitleWrapper>
        </S.TitleContainer>
        <S.SubTitleContainer>
          <S.SubTextWrapper>
            As datas que marcaram a história do PopHaus!
          </S.SubTextWrapper>
        </S.SubTitleContainer>
        <SwipeTimeline myList={timeline} labelWidth={200} amountMove={200} />
      </S.TextContainer>
      <S.IconContainer className="col-sm-12 col-md-6">
        <img
          src={aboutSecondIcon}
          alt="Price Screen Icon"
          width={width > 1200 ? 500 : 250}
        />
      </S.IconContainer>
    </S.Container>
  );
};

export default FourthContainer;
