import styled from "styled-components";

const Container = styled.div`
  background-color: #7ac6af;
  border: 1px solid #7ac6af;
  border-radius: 20px 20px 0px 0px;
  height: 685px;
  margin: -20px 0px 0px 0px;
  display: flex;

  @media (min-width: 0px) and (max-width: 1300px) {
    flex-direction: column-reverse;
    height: 1000px;
    padding-bottom: 30px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  @media (min-width: 0px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  margin-left: 100px;

  @media (min-width: 0px) and (max-width: 1200px) {
    margin: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  color: white;
  font-size: 40px;
  font-weight: 700;
  line-height: 42.2px;

  @media (min-width: 0px) and (max-width: 1200px) {
    text-align: center;
    font-size: 26px;
  }
`;

const SubTitleContainer = styled.div`
  margin-left: 100px;
  margin-top: 10px;

  @media (min-width: 0px) and (max-width: 1200px) {
    margin: 10px 0px 0px 0px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const SubTextWrapper = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.6px;

  @media (min-width: 0px) and (max-width: 1200px) {
    max-width: 300px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (min-width: 0px) and (max-width: 1200px) {
    width: 100%;
  }
`;

export {
  Container,
  TextContainer,
  TitleContainer,
  SubTitleContainer,
  TitleWrapper,
  SubTextWrapper,
  IconContainer,
};
