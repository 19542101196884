import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import "./styles.scss";
import { ListContact } from "./ListContact/ListContact";
import { dataContact } from "../../utils/dataContact";

export const Contact = () => {
  const [branchMenu, setBranchMenu] = useState("SANTO_AMARO");

  const branches = dataContact;

  const getSocialMedia = () => {
    let socialMedia = [
      branches[branchMenu].instagram,
      branches[branchMenu].facebook,
      branches[branchMenu].youtube,
    ];

    return socialMedia.map((sm) => {
      if (!sm) return null;
      return (
        <div
          className="IconWrapper"
          onClick={() => window.open(sm.url, "_blank")}
        >
          <img src={sm.image} alt="Icon" />
        </div>
      );
    });
  };

  return (
    <div className="Contact__container">
      <Helmet>
        <title>Contato, telefone e endereço do PopHaus em São Paulo | PopHaus</title>
      </Helmet>
      <Header backgroundColor={"transparent"} />
      <h1 className="d-flex justify-content-center gap-5 text-white">
        Contatos
      </h1>
      <h4 className="d-flex justify-content-center text-white mt-5">
        Escolha a unidade
      </h4>
      <div className="Contact__Content bg-primary">
        <div className="ListContact ">
          <ListContact
            branchMenu={branchMenu}
            setBranchMenu={setBranchMenu}
            branches={branches}
          />
        </div>
        <div className="contact__SocialMidia bg-primary">
          <h4 className="text-white">Encontre o Pop</h4>
          <div className="d-flex">{getSocialMedia()}</div>
        </div>
      </div>
    </div>
  );
};
