import { getWindowDimensions } from "../../../services/getWindowDimensions";

import homeSecond from "../../../assets/images/homeSecond.webp";
import popJump from "../../../assets/images/popJump.svg";
import popIndoor from "../../../assets/images/popIndoor.svg";
import popOutdoor from "../../../assets/images/popOutdoor.svg";
import brasiliaAtracao from "../../../assets/images/brasiliaAtracao.svg";

/* POP JUMP SANTO AMARO */
import basquetImg from "../../../assets/images/santoAmaroDesk/unidade/popJump/basquete.jpg.webp";
import basquetMob from "../../../assets/images/santoAmaroMob/unidade/popJump/basquete.jpg.webp";

import freeJump from "../../../assets/images/santoAmaroDesk/unidade/popJump/freeJump.jpg.webp";
import freeJumpMob from "../../../assets/images/santoAmaroMob/unidade/popJump/freeJump.jpg.webp";

import piscinaEspuma from "../../../assets/images/santoAmaroDesk/unidade/popJump/piscinaEspuma.jpg.webp";
import piscinaEspumaMob from "../../../assets/images/santoAmaroMob/unidade/popJump/piscinaEspuma.jpg.webp";

import circuitoNinja from "../../../assets/images/santoAmaroDesk/unidade/popJump/circuitoNinja.jpg.webp";
import circuitoNinjaMob from "../../../assets/images/santoAmaroMob/unidade/popJump/circuitoNinja.jpg.webp";

import parkour from "../../../assets/images/santoAmaroDesk/unidade/popJump/parkour.jpg.webp";
import parkourMob from "../../../assets/images/santoAmaroMob/unidade/popJump/parkour.jpg.webp";

/* POP INDOR SANTO AMARO */
import basqueteIndoor from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/basquete.jpg.webp";
import basqueteInddorMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/basquete.jpg.webp";

import circuitoObstaculos from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/circuitoObstaculos.jpg.webp";
import circuitoObstaculosMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/circuitoObstaculos.jpg.webp";

import bigSlide from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/bigSlide.jpg.webp";
import bigSlideMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/bigSlide.jpg.webp";

import demolidor from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/demolidor.jpg.webp";
import demolidorMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/demolidor.jpg.webp";

import contonete from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/guerraContonete.jpg.webp";
import contoneteMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/guerraContonete.jpg.webp";

import subidaMaluca from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/subidaMaluca.jpg.webp";
import subidaMalucaMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/subidaMaluca.jpg.webp";

import jumpBalls from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/jumpBalls.jpg.webp";
import jumpBallsMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/jumpBalls.jpg.webp";

import quedaLivre from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/quedaLivre.jpg.webp";
import quedaLivreMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/quedaLivre.jpg.webp";

import corridaHardcore from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/corridaHardcore.jpg.webp";
import corridaHardcoreMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/corridaHardcore.jpg.webp";

import superSalto from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/superSalto.jpg.webp";
import superSaltoMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/superSalto.jpg.webp";

import pulaObstaculo from "../../../assets/images/santoAmaroDesk/unidade/popIndoor/pulaObstaculos.jpg.webp";
import pulaObstaculoMob from "../../../assets/images/santoAmaroMob/unidade/popIndoor/pulaObstaculos.jpg.webp";

/* POP OUTDOOR SANTO AMARO */
import slipNSlide from "../../../assets/images/santoAmaroDesk/unidade/popOutDoor/slipNSlide.jpg.webp";
import slipNSlideMob from "../../../assets/images/santoAmaroMob/unidade/popOutDoor/slipNSlide.jpg.webp";

import contoneteOutdoor from "../../../assets/images/santoAmaroDesk/unidade/popOutDoor/guerraContonete.jpg.webp";
import contoneteOutdoorMob from "../../../assets/images/santoAmaroDesk/unidade/popOutDoor/guerraContonete.jpg.webp";

import megaSlide from "../../../assets/images/santoAmaroDesk/unidade/popOutDoor/megaSlide.jpg.webp";
import megaSlideMob from "../../../assets/images/santoAmaroMob/unidade/popOutDoor/megaSlide.jpg.webp";

import batalhaJatoDAgua from "../../../assets/images/santoAmaroDesk/unidade/popOutDoor/batalhaJatoDAgua.jpg.webp";
import batalhaJatoDAguaMob from "../../../assets/images/santoAmaroDesk/unidade/popOutDoor/batalhaJatoDAgua.jpg.webp";

/* POP INDOOR TATUAPE */
import tatuapeTuboSlide from "../../../assets/images/tatuapeMob/unidade/popIndoor/tuboSlide.jpg.webp";
import tatuapeTuboSlideDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/tuboSlide.jpg.webp";

import tatuapeSubidaMaluca from "../../../assets/images/tatuapeMob/unidade/popIndoor/subidaMaluca.jpg.webp";
import tatuapeSubidaMalucaDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/subidaMaluca.jpg.webp";

import tatuapeFootDart from "../../../assets/images/tatuapeMob/unidade/popIndoor/footDart.jpg.webp";
import tatuapeFootDartDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/footDart.jpg.webp";

import tatuapeBasquete from "../../../assets/images/tatuapeMob/unidade/popIndoor/basquete.jpg.webp";
import tatuapeBasqueteDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/basquete.jpg.webp";

import tatuapeAreaKids from "../../../assets/images/tatuapeMob/unidade/popIndoor/areaKids.jpg.webp";
import tatuapeAreaKidsDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/areaKids.jpg.webp";

import tatuapeBigSlide from "../../../assets/images/tatuapeMob/unidade/popIndoor/bigSlide.jpg.webp";
import tatuapeBigSlideDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/bigSlide.jpg.webp";

import tatuapeGuerraCotonetes from "../../../assets/images/tatuapeMob/unidade/popIndoor/guerraCotonetes.jpg.webp";
import tatuapeGuerraCotonetesDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/guerraCotonetes.jpg.webp";

import tatuapeVulcao from "../../../assets/images/tatuapeMob/unidade/popIndoor/vulcao.jpg.webp";
import tatuapeVulcaoDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/vulcao.jpg.webp";

import tatuapeGiroRadical from "../../../assets/images/tatuapeMob/unidade/popIndoor/giroRadical.jpg.webp";
import tatuapeGiroRadicalDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/giroRadical.jpg.webp";

import tatuapejumpBalls from "../../../assets/images/tatuapeMob/unidade/popIndoor/jumpBalls.jpg.webp";
import tatuapejumpBallsDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/jumpBalls.jpg.webp";

import tatuapeFreeJump from "../../../assets/images/tatuapeMob/unidade/popIndoor/freeJump.jpg.webp";
import tatuapeFreeJumpDesk from "../../../assets/images/tatuapeDesk/unidade/popIndoor/freeJump.jpg.webp";

/* POP OUTDOOR TATUAPE */
import tatuapeMegaSlide from "../../../assets/images/tatuapeMob/unidade/popOutdoor/megaSlide.jpg.webp";
import tatuapeMegaSlideDesk from "../../../assets/images/tatuapeDesk/unidade/popOutdoor/megaSlide.jpg.webp";

import tatuapeFutJump from "../../../assets/images/tatuapeMob/unidade/popOutdoor/futJump.jpg.webp";
import tatuapeFutJumpDesk from "../../../assets/images/tatuapeDesk/unidade/popOutdoor/futJump.jpg.webp";

import tatuapeSlingShot from "../../../assets/images/tatuapeMob/unidade/popOutdoor/slingShot.jpg.webp";
import tatuapeSlingShotDesk from "../../../assets/images/tatuapeDesk/unidade/popOutdoor/slingShot.jpg.webp";

import tatuapeNinjaSplash from "../../../assets/images/tatuapeMob/unidade/popOutdoor/ninjaSplash.jpg.webp";
import tatuapeNinjaSplashDesk from "../../../assets/images/tatuapeDesk/unidade/popOutdoor/ninjaSplash.jpg.webp";

import tatuapeBatalhaJatoDagua from "../../../assets/images/tatuapeMob/unidade/popOutdoor/batalhaJatoDagua.jpg.webp";
import tatuapeBatalhaJatoDaguaDesk from "../../../assets/images/tatuapeDesk/unidade/popOutdoor/batalhaJato.jpg.webp";

import backgroundSantoAmaroDesk from "../../../assets/images/santoAmaroDesk/conheca/conheca.jpg.webp";
import backgroundSantoAmaroMob from "../../../assets/images/santoAmaroMob/conheca/conheca.jpg.webp";

import backgroundTatuapeMob from "../../../assets/images/tatuapeMob/conheca/conheca.jpg.webp";
import backgroundTatuapeDesk from "../../../assets/images/unit_tatuape_2.webp";

// Brasilia Images
import brasiliaAcquaZone from "../../../assets/images/brasiliaDesk/unidade/atracoes/acquaZone.webp";
import brasiliaFutJump from "../../../assets/images/brasiliaDesk/unidade/atracoes/futJump.webp";
import brasiliaBubbleSoccer from "../../../assets/images/brasiliaDesk/unidade/atracoes/arenaBubbleSoccer.webp";
import brasiliaNinjaSplash from "../../../assets/images/brasiliaDesk/unidade/atracoes/ninjaSplash.webp";
import brasiliaMegaSlide from "../../../assets/images/brasiliaDesk/unidade/atracoes/megaSlide.webp";

// Brasilia Background
import brasiliaDesk from "../../../assets/images/brasiliaDesk/capaDesk.webp";
import brasiliaMob from "../../../assets/images/brasiliaDesk/capaMobile.webp";

export const objectMapper = {
  SantoAmaro: {
    param: "SANTO_AMARO",
    Title: "Santo Amaro",
    Background:
      getWindowDimensions().width > 1300
        ? backgroundSantoAmaroDesk
        : backgroundSantoAmaroMob,
    SubTitle:
      "Venha se divertir no PopHaus Santo Amaro! São 4 mil metros divididos em 3 áreas: Pop Jump, Pop Indoor e Pop Outdoor.",
    Atracoes: {
      PopJump: {
        Name: "Pop Jump",
        Image: popJump,
        BackgroundImage: homeSecond,
        Toys: [
          {
            Name: "Basquete",
            Description:
              "Quantas cestas você consegue fazer? Com a ajuda de um trampolim, vai ser muito mais fácil enterrar as bolas!",
            Image: getWindowDimensions().width > 1300 ? basquetImg : basquetMob,
          },
          {
            Name: "Free Jump",
            Description:
              "Uma área livre enooorme para você pular como quiser e garantir muitas risadas e momentos inesquecíveis.",
            Image: getWindowDimensions().width > 1300 ? freeJump : freeJumpMob,
          },
          {
            Name: "Piscina de Espuma",
            Description:
              "Um mergulho sempre vai bem! Hora de pular bem alto e se jogar sem medo nessa piscina de espuma.",
            Image:
              getWindowDimensions().width > 1300
                ? piscinaEspuma
                : piscinaEspumaMob,
          },
          {
            Name: "Parkour",
            Description:
              "Equilíbrio e adrenalina. Salte das paredes altas do parkour e caia direto em um trampolim!",
            Image: getWindowDimensions().width > 1300 ? parkour : parkourMob,
          },
          {
            Name: "Circuito Ninja",
            Description:
              "Será que você é ninja o suficiente para passar por todos esses obstáculos sem cair?",
            Image:
              getWindowDimensions().width > 1300
                ? circuitoNinja
                : circuitoNinjaMob,
          },
        ],
      },
      PopIndoor: {
        Name: "Pop Indoor",
        Image: popIndoor,
        BackgroundImage: homeSecond,
        Toys: [
          {
            Name: "Circuito de Obstáculos",
            Description:
              "O objetivo aqui é atravessar diversos pneus e cones até a escada que vai para o escorregador. Vale até apostar com os amigos!",
            Image:
              getWindowDimensions().width > 1300
                ? circuitoObstaculos
                : circuitoObstaculosMob,
          },
          {
            Name: "Basquete",
            Description:
              "Quantas cestas você consegue fazer? Com a ajuda de um trampolim, vai ser muito mais fácil enterrar as bolas!",
            Image:
              getWindowDimensions().width > 1300
                ? basqueteIndoor
                : basqueteInddorMob,
          },
          {
            Name: "Big Slide",
            Description:
              "São duas pistas de escorregadores, ideal para apostar com os amigos quem vence o frio na barriga e garantir boas risadas.",
            Image: getWindowDimensions().width > 1300 ? bigSlide : bigSlideMob,
          },
          {
            Name: "Demolidor",
            Description:
              "Será que você consegue escapar da bola demolidora e ainda derrubar os adversários? Perfeita para aqueles que curtem aventura.",
            Image:
              getWindowDimensions().width > 1300 ? demolidor : demolidorMob,
          },
          {
            Name: "Guerra de Cotonetes",
            Description:
              "Já imaginou tentar derrubar seus amigos em um megabrinquedo inflável com cotonetes gigantes e macios? Agora você pode!",
            Image:
              getWindowDimensions().width > 1300 ? contonete : contoneteMob,
          },
          {
            Name: "Subida Maluca",
            Description:
              "Um dos grandes desafios do Pop Zone é completar a Subida Maluca sem escorregar. São três níveis de altura e a aventura é conseguir chegar ao topo!",
            Image:
              getWindowDimensions().width > 1300
                ? subidaMaluca
                : subidaMalucaMob,
          },
          {
            Name: "Jump Balls",
            Description:
              "Quão longe você consegue pular? Equilíbrio e saltos largos são o que você precisa pra atravessar o Jump Balls sem cair.",
            Image:
              getWindowDimensions().width > 1300 ? jumpBalls : jumpBallsMob,
          },
          {
            Name: "Corrida Hardcore",
            Description:
              "Hora de ver quem chega primeiro depois de passar por todos os obstáculos da Corrida Hardcore. Desafie um amigo e veja quem sai na frente nos tubos e bastões.",
            Image:
              getWindowDimensions().width > 1300
                ? corridaHardcore
                : corridaHardcoreMob,
          },
          {
            Name: "Queda Livre",
            Description:
              "Você tem medo de altura? Ou é do tipo que curte aqueeeeele frio na barriga? Na Queda Livre, você experimenta a aventura caindo num grande inflável.",
            Image:
              getWindowDimensions().width > 1300 ? quedaLivre : quedaLivreMob,
          },
          {
            Name: "Super Salto",
            Description:
              "Que tal se sentir um atleta olímpico testando quão alto você consegue pular? Você vai precisar de um super salto para passar pelos desafios do PopZone!",
            Image:
              getWindowDimensions().width > 1300 ? superSalto : superSaltoMob,
          },
          {
            Name: "Pula Obstáculo",
            Description:
              "Quantos obstáculos você consegue pular sem cair? Dentro do PopZone, têm vários deles para testar seu equilíbrio e flexibilidade.",
            Image:
              getWindowDimensions().width > 1300
                ? pulaObstaculo
                : pulaObstaculoMob,
          },
        ],
      },
      PopOutdoor: {
        Name: "Pop Outdoor",
        Image: popOutdoor,
        BackgroundImage: homeSecond,
        Toys: [
          {
            Name: "Slip N' Slide",
            Description:
              "Ideal para os dias de sol, o Slip N' Slide conta com um escorregador de 25 metros de comprimento! Pegue sua boia e venha deslizar na água! Vale até apostar quem consegue deslizar mais rápido.",
            Image:
              getWindowDimensions().width > 1300 ? slipNSlide : slipNSlideMob,
          },
          {
            Name: "Guerra de Cotonetes",
            Description:
              "Já imaginou tentar derrubar seus amigos em um brinquedo inflável com cotonetes gigantes e macios? Agora você pode!",
            Image:
              getWindowDimensions().width > 1300
                ? contoneteOutdoor
                : contoneteOutdoorMob,
          },
          {
            Name: "Mega Slide",
            Description:
              "Um escorregador gigante de 12m que acaba em uma piscina. Ideal para os que querem se refrescar e gostam de adrenalina. É um dos maiores escorregadores infláveis da América Latina! Altura mínima para brincar na atração: 1,20m",
            Image:
              getWindowDimensions().width > 1300 ? megaSlide : megaSlideMob,
          },
          {
            Name: "Batalha Jato d’água",
            Description:
              "Brincar de batalha de água já é divertido, no meio de um labirinto fica ainda melhor! Será que você consegue encontrar e molhar seus amigos?",
            Image:
              getWindowDimensions().width > 1300
                ? batalhaJatoDAgua
                : batalhaJatoDAguaMob,
          },
        ],
      },
    },
    whatsapp_url: "https://wa.me/+5511917706110",
  },
  Tatuape: {
    param: "TATUAPE",
    Title: "Tatuapé",
    SubTitle:
      "Você está preparado para se divertir muito? Então o Pop Tatuapé é o seu lugar! Atrações incríveis e inéditas divididas em duas áreas: Pop Indoor e Pop Outdoor.",
    Background: backgroundTatuapeDesk,
    Atracoes: {
      PopIndoor: {
        Name: "Pop Indoor",
        Image: popIndoor,
        BackgroundImage: homeSecond,
        Toys: [
          {
            Name: "Tubo Slide",
            Description:
              "O Tubo Slide é ideal para você mostrar toda a sua habilidade em alpinismo e depois finalizar essa escalada em um escorregador.",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeTuboSlideDesk
                : tatuapeTuboSlide,
          },
          {
            Name: "Subida Maluca",
            Description:
              "Um dos grandes desafios do Indoor é completar a Subida Maluca sem escorregar. São três níveis de altura e a aventura é conseguir chegar ao topo sem deslizar!",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeSubidaMalucaDesk
                : tatuapeSubidaMaluca,
          },
          /*
          {
            Name: "Foot Dart",
            Description:
              "Imagine um alvo gigante e bolas de futebol à frente. Ao melhor estilo “tiro ao alvo com os pés”, ganha quem acertar o alvo mais próximo do centro. Uma boa oportunidade de dar risadas com amigos e amigas ruins de pontaria.",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeFootDartDesk
                : tatuapeFootDart,
          },*/
          {
            Name: "Basquete",
            Description:
              "Quantas cestas você consegue fazer? Em uma área inflável, vai ficar muito mais fácil de enterrar as bolas!",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeBasqueteDesk
                : tatuapeBasquete,
          },
          {
            Name: "Área Kids",
            Description:
              "Temos uma área separada para os menores, própria para crianças de até 5 anos. ",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeAreaKidsDesk
                : tatuapeAreaKids,
          },
          {
            Name: "Big Slide",
            Description:
              "São duas pistas de escorregadores de 5m de altura, ideal para apostar corrida com os amigos e garantir boas risadas.",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeBigSlideDesk
                : tatuapeBigSlide,
          },
          {
            Name: "Guerra de Cotonetes",
            Description:
              "Já imaginou tentar derrubar seus amigos em um megabrinquedo inflável com cotonetes gigantes e macios? Agora você pode!",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeGuerraCotonetesDesk
                : tatuapeGuerraCotonetes,
          },
          /*
          {
            Name: "Free Jump",
            Description:
              "Uma área livre enooorme para você pular como quiser e garantir muitas risadas e momentos inesquecíveis.",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeFreeJumpDesk
                : tatuapeFreeJump,
          },*/
          {
            Name: "Vulcão",
            Description:
              "Nesse vulcão inflável será divertido escalar e depois escorregar!",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeVulcaoDesk
                : tatuapeVulcao,
          },
          {
            Name: "Jump Balls",
            Description:
              "Quão longe você consegue pular? Equilíbrio e saltos largos são o que você precisa pra atravessar o Jump Balls sem cair.",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapejumpBallsDesk
                : tatuapejumpBalls,
          },
          /*
          {
            Name: "Giro Radical",
            Description:
              "Quem será que consegue se manter em pé por mais tempo? No Giro Radical, você precisa pular e se abaixar para desviar dos troncos de espuma. Nós controlamos o nível de dificuldade de acordo com a idade dos participantes!",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeGiroRadicalDesk
                : tatuapeGiroRadical,
          },
          */
        ],
      },
      PopOutdoor: {
        Name: "Pop Outdoor",
        Image: popOutdoor,
        BackgroundImage: homeSecond,
        Toys: [
          {
            Name: "Mega Slide",
            Description:
              "Um escorregador gigante de 12m que acaba em uma piscina. Ideal para os que querem se refrescar e gostam de adrenalina. É um dos maiores escorregadores infláveis da América Latina! Altura mínima para brincar na atração: 1,20m",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeMegaSlideDesk
                : tatuapeMegaSlide,
          },
          {
            Name: "Fut Jump",
            Description:
              "Fazer gol é uma tarefa só pros que conseguirem ficar em pé no Fut Jump! Uma área inflável que exige equilíbrio para jogar futebol e dar muitas risadas com os amigos. Durante o inverno, essa atração estará disponível na versão seca e não aquática.",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeFutJumpDesk
                : tatuapeFutJump,
          },
          {
            Name: "Ninja Splash",
            Description:
              "Inspirado nas provas dos grandes programas de TV, o Ninja Splash é um circuito inflável, em uma piscina de 100 mil litros. Correr, pular, escalar e escorregar em obstáculos que flutuam na água são alguns dos desafios para completar o percurso sem cair na água. Altura mínima para brincar na atração: 1,20m",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeNinjaSplashDesk
                : tatuapeNinjaSplash,
          },
          {
            Name: "Batalha Jato D'água",
            Description:
              "Brincar de batalha de água já é divertido, no meio de um labirinto fica ainda melhor! Será que você consegue encontrar e molhar seus amigos?",
            Image:
              getWindowDimensions().width > 1300
                ? tatuapeBatalhaJatoDaguaDesk
                : tatuapeBatalhaJatoDagua,
          },
        ],
      },
    },
    whatsapp_url: "https://wa.me/+5511917706110",
  },
  Brasilia: {
    param: "BRASILIA",
    Title: "Brasília",
    SubTitle:
      "O maior parque de brinquedos infláveis da América Latina também está em Brasília! Atrações gigantescas e aquáticas pra você se divertir muuuito.",
    Background: getWindowDimensions().width > 1300 ? brasiliaDesk : brasiliaMob,
    Atracoes: {
      PopOutdoor: {
        Name: "Atrações",
        Image: brasiliaAtracao,
        BackgroundImage: homeSecond,
        Toys: [
          {
            Name: "Fut Jump",
            Description:
              "Fazer gol é uma tarefa só pros que conseguirem ficar em pé no Fut Jump, o maior futebol inflável do Brasil! O segredo é ter equilíbrio para jogar e dar muitas risadas com os amigos.",
            Image: brasiliaFutJump,
          },
          {
            Name: "Bubble Soccer",
            Description:
              "Imagina jogar futebol dentro de uma bolha? É diversão garantida! Chame seus amigos para ver quem consegue fazer mais gols e derrubar o maior número de adversários! Para brincar nessa atração, a altura mínina é de 1,20m.",
            Image: brasiliaBubbleSoccer,
          },
          {
            Name: "Mega Slide",
            Description:
              "Gigante! O Mega Slide é um dos maiores escorregadores infláveis da América Latina! Com 11 metros de altura, a adrenalina começa na subida e vai aumentando na descida. No final da queda, uma rasa piscina te espera!",
            Image: brasiliaMegaSlide,
          },
          {
            Name: "Ninja Splash",
            Description:
              "Inspirado nas provas dos grandes programas de TV, o Ninja Splash tem 130 metros de comprimento de infláveis aquáticos em uma piscina de 1 milhão de litros! Correr, pular, escalar e escorregar em obstáculos que flutuam na água são alguns dos desafios para completar o percurso sem cair na água. *Altura mínima para brincar na atração: 1,30m",
            Image: brasiliaNinjaSplash,
          },
          {
            Name: "Acqua Zone ",
            Description:
              "Um castelo inflável e aquático como você nunca viu! Corra pelos corredores, escale as paredes e escorregue em descidas de diferentes altitudes.  O Acqua Zone é indicado apenas para crianças de até 1,50m - exceto responsáveis acompanhantes. ",
            Image: brasiliaAcquaZone,
          },
        ],
      },
    },
    whatsapp_url: "https://wa.me/+5561998755443",
  },
};
