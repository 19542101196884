import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import * as S from "./styles";
import purpleUpArrow from "../../assets/purpleUpArrow.svg";
import purpleDownArrow from "../../assets/purpleDownArrow.svg";
import lineQuestion from "../../assets/lineQuestion.svg";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Api } from "../../api/api";
import { toast } from "react-toastify";
import ProgressBar from "../../components/ProgressBar";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const TodasDuvidas = () => {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [load, setLoad] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState({ page: 0, limit: 5 });
  const unit_id = useParams().unit;
  const [searchParams] = useSearchParams();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(searchParams.get("secao") || null);
  const [loadCategories, setLoadCategories] = useState(false);

  const handleOpenQuestions = () => {
    window.open("https://my.forms.app/parquepophaus/duvidas");
  };

  const getCategories = () => {
    setLoadCategories(true);
    Api.get("doubts_categories/has_doubts", { params: { unit_id } })
      .then((res) => {
        setCategories(res.data.list);
        if (
          res.data.list[0] &&
          (!category || !res.data.list.find((item) => item.id === category))
        )
          setCategory(res.data.list[0].id);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadCategories(false));
  };

  useEffect(() => {
    if (unit_id) getCategories();
  }, [unit_id]);

  const getList = () => {
    setLoad(true);
    Api.get("doubts", {
      params: {
        ...filter,
        doubt_category_id: category,
        unit_id,
      },
    })
      .then((res) => {
        setList(res.data.list);
        setTotal(res.data.total);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    if (unit_id && category) getList();
  }, [category, unit_id, filter]);

  const getPagesCount = () => {
    const division = Number(Number(total) / Number(filter.limit));
    let count = Math.floor(division);

    if (!Number.isInteger(division)) count += 1;

    return count;
  };

  const getPaginationItems = () => {
    const items = [];

    for (let index = 0; index < getPagesCount(); index++) {
      const currentPage = index + 1;
      items.push(
        <Pagination.Item
          onClick={() => setFilter((prev) => ({ ...prev, page: index }))}
          active={index === filter.page}
          style={{ zIndex: 0 }}
        >
          {currentPage}
        </Pagination.Item>
      );
    }

    return items;
  };

  const handleLimitChange = (e) => {
    setFilter((prev) => ({ ...prev, limit: e.target.value }));
  };

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <>
      <Header />
      <S.TitleContainer>
        <S.TitleWrapper>Dúvidas</S.TitleWrapper>
      </S.TitleContainer>
      <S.CenterContainer>
        <S.TextWrapper fontSize={"1.2em"}>
          Todas as dúvidas sobre a Unidade {searchParams.get("unit_name")}
        </S.TextWrapper>
        {unit_id && (
          <S.SectionContainer>
            {categories.map((sec, secInd) => (
              <S.SectionWrapper
                key={"section_" + sec.id}
                marginLeft={secInd === 0 ? "0px" : undefined}
                onClick={() => setCategory(sec.id)}
                active={category === sec.id ? true : false}
              >
                {sec.name}
              </S.SectionWrapper>
            ))}
          </S.SectionContainer>
        )}
        {load && <ProgressBar className="m-1" />}
        {list.map((question, index) => {
          return (
            <div key={"question_" + question.id} className="w-100">
              <S.QuestionContainer
                onClick={() => {
                  selectedItem === question.id
                    ? setSelectedItem(null)
                    : setSelectedItem(question.id);
                }}
              >
                <S.QuestionWrapper>{question.question}</S.QuestionWrapper>
                <img
                  src={
                    selectedItem === question.id
                      ? purpleUpArrow
                      : purpleDownArrow
                  }
                  alt="arrow"
                  style={{ pointerEvents: "none", userSelect: "none" }}
                />
              </S.QuestionContainer>
              {selectedItem === question.id ? (
                <S.AnswerWrapper
                  dangerouslySetInnerHTML={{ __html: question.answer }}
                />
              ) : null}
              <img src={lineQuestion} alt="line" width={"100%"} />
            </div>
          );
        })}
        {!list[0] && !load && (
          <span>Não há dúvidas cadastradas nesta unidade.</span>
        )}
        <Pagination className="d-flex align-items-center mt-1">
          {getPaginationItems()}
          <Form.Select
            value={filter.limit}
            onChange={handleLimitChange}
            className="w-auto ml-1"
            style={{ marginLeft: "1rem" }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </Form.Select>
        </Pagination>
      </S.CenterContainer>
      <Footer />
    </>
  );
};

export default TodasDuvidas;
