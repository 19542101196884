import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { data } from "./data";
import { gtagPixel } from "../../utils/util";
import { useNavigate } from "react-router-dom";

import { SimulatorHook } from "../../context/simulatorContext";
import { Api } from "../../api/api";

const Festas = () => {
  const urlParams = new URLSearchParams(window.location.search.substring(1));
  const unit = urlParams.get("un");
  const navigate = useNavigate();

  const unitData = data[unit];

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  if (!unitData)
    return window.open(process.env.REACT_APP_NEW_WEBSITE_URL, "_self");

  const renderActions = (actions) => {
    let idContact;
    if (unit === "Brasilia") {
      idContact = "bsb_contact";
    } else if (unit === "SantoAmaro") {
      idContact = "sta_contact";
    } else if (unit === "Tatuape") {
      idContact = "ttp_contact";
    }

    const handleClick = (action) => {
      if (action.onClick) {
        action.onClick();
      }
    };

    if (
      (!actions || !actions[0]) &&
      (!unitData.actions || !unitData.actions[0])
    ) {
      return null;
    }

    return (actions || unitData.actions).map((act, ix) => (
      <button
        className="button__secondary"
        onClick={() => handleClick(act)}
        id={idContact}
      >
        {act.label}
      </button>
    ));
  };

  let idSimulator;
  if (unit === "Brasilia") {
    idSimulator = "bsb_simulator";
  } else if (unit === "SantoAmaro") {
    idSimulator = "sta_simulator";
  } else if (unit === "Tatuape") {
    idSimulator = "ttp_simulator";
  }

  const openSimulator = () => {
    Api.post("website_clicks", { clicked_id: idSimulator });
    gtagPixel("gtm.linkClick", {
      send_to: "AW-835815937/KaVLCJ2kjKsYEIGUxo4D",
    });
    navigate(`/NewSimulator/FormContainer?un=${unit}`);
  };

  return (
    <>
      <Helmet>
        <title>
         Saiba mais sobre festas e eventos
        </title>
      </Helmet>
      <Header />
      <div className="container pb-4">
        <div className="py-4 p-md-4 p-lg-5 d-flex flex-column gap-3">
          <div className="row g-3">
            <div className="col-xs-12 col-md-6">
              <div className="d-flex flex-column gap-2 align-items-start justify-content-center h-100">
                <h1 className="text-primary fw-bolder">
                Festa de aniversário é no PopHaus{" "}
                  {unit === "SantoAmaro"
                    ? "Santo Amaro"
                    : unit === "Tatuape"
                    ? "Tatuapé"
                    : unit}
                  
                </h1>
                <span className="text-primary fs-5">
                  Que tal comemorar com a família e amigos no primeiro parque de
                  infláveis da América Latina? Escolha o modelo e personalize
                  como quiser
                </span>
                <button
                  onClick={openSimulator}
                  className="button__primary mt-2 text-uppercase"
                  id={idSimulator}
                >
                  Conheça os pacotes
                </button>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="d-flex align-items-center justify-content-center h-100">
                <img
                  alt="Imagem festa"
                  src={unitData.image}
                  className="rounded-4"
                  width={"100%"}
                />
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-xs-12">
              <div className="d-flex flex-column text-center p-md-5 border rounded-4 border-primary w-100 h-100 align-items-center justify-content-center">
                <span
                  className="text-danger fs-1 fw-bold"
                  style={{ maxWidth: 400 }}
                >
                  {unitData.title}
                </span>
                <span
                  className="text-primary fw-bold mb-4"
                  style={{ maxWidth: 400 }}
                >
                  {unitData.description}
                </span>
                {renderActions()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Festas;
