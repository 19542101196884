import AppRoutes from "./routes";
import { NavigationProvider } from "./context/navigationContext";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { SimulatorProvider } from "./context/simulatorContext";
import { ResponsibilityTerm } from "./components/ResponsibilityTerm";
import { ToastContainer } from "react-toastify";
import { PartyProvider } from "../src/context/partyContext";

const App = () => {
  const tagManagerArgs = {
    gtmId: "GTM-NHNXK6S",
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="App">
      <NavigationProvider>
      <PartyProvider>
        <SimulatorProvider>
          <ResponsibilityTerm />
          <ToastContainer />
          <AppRoutes />
        </SimulatorProvider>
        </PartyProvider>
      </NavigationProvider>
    </div>
  );
};

export default App;
