import Image from "react-bootstrap/Image";

const Menus = ({ list, className }) => {
  const renderMenus = (menu) => {
    if (!list || !list[0]) return;

    const renderCategories = () => {
      return menu.categories.map((category) => (
        <div key={`category_item_${category.id}`} className="d-flex gap-2">
          {category.avatarComponent}
          <div style={{ flex: 1 }}>
            <span className="fw-bold">
              {category.name}
              {category.description ? ":" : ""}
            </span>
            <span> {category.description}</span>
          </div>
        </div>
      ));
    };

    return (
      <div
        className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
        key={`menu_item_${menu.id}`}
      >
        <div
          className={`p-0 rounded-5 pt-4 pb-5 ${
            menu.className || "bg-primary"
          }`}
        >
          <span className="fs-5 fw-bold d-flex justify-content-center">
            {menu.titleComponent}
          </span>
        </div>
        <div style={{ marginTop: -60 }}>
          <div className="m-0 d-flex flex-column gap-4 bg-white text-primary rounded-5 p-3 pb-5">
            {renderCategories()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`rounded-4 pb-5 ${className || "bg-secondary text-white"}`}>
      <div className="container">
        <div className="d-flex gap-3 p-2 p-md-4 p-lg-5">
          <div className="row w-100 g-3 p-0 m-0 justify-content-center">
            {list.map(renderMenus)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menus;
