import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import * as S from "./styles";
import * as worker from "./worker";
import ProgressBar from "../../components/ProgressBar";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import "react-18-image-lightbox/style.css";
import GalleryGrid from "./GalleryGrid";

const Album = ({ doAlert, mobile }) => {
  const navigate = useNavigate();
  const albumId = useParams().album_id;
  const [album, setAlbum] = useState(null);
  const [albumLoader, setAlbumLoader] = useState(false);
  const [gridPictures, setGridPictures] = useState([]);
  const [gridPicturesLoader, setGridPicturesLoader] = useState(false);

  const [index, setIndex] = useState(-1);
  const currentImage = gridPictures[index];
  const nextIndex = (index + 1) % gridPictures.length;
  const nextImage = gridPictures[nextIndex] || currentImage;
  const prevIndex = (index + gridPictures.length - 1) % gridPictures.length;
  const prevImage = gridPictures[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const getAlbumPics = () => {
    if (!album?.id) return doAlert("danger", "ID do Álbum indefinido!");
    setGridPicturesLoader(true);
    worker.getAlbumPics({ albums_id: album?.id }, (res, error) => {
      if (error)
        return doAlert(
          "danger",
          error.message ||
            "Erro ao carregar fotos do álbum! Tente novamente mais tarde ou entre em contato com o suporte!"
        );
      if (res?.photos[0]) treatPics(res?.photos);
      else setGridPicturesLoader(false);
    });
  };

  const getAlbum = () => {
    if (!albumId) return doAlert("danger", "ID do Álbum indefinido!");
    setAlbumLoader(true);
    worker.getAlbum({ id: albumId }, (res, error) => {
      setAlbumLoader(false);
      if (error)
        return doAlert(
          "danger",
          error.message ||
            "Erro ao carregar detalhes do álbum! Tente novamente mais tarde ou entre em contato com o suporte!"
        );
      setAlbum(res.data);
    });
  };

  useEffect(() => {
    getAlbum();
  }, [albumId]);

  useEffect(() => {
    if (album?.id) getAlbumPics();
  }, [album?.id]);

  const treatPics = (pictures) => {
    let treatedPics = [];
    pictures.map((i) => {
      const img = new Image();
      img.src = i.file_url;
      img.onload = () => {
        treatedPics.push({
          src: i.file_url,
          width: img.width,
          height: img.height,
        });

        if (treatedPics.length == pictures.length)
          setGridPictures([...treatedPics]);
      };
    });
  };

  useEffect(() => {
    setGridPicturesLoader(false);
  }, [gridPictures]);

  const goToGallery = () => {
    navigate("/Galeria");
  };

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <S.PageContainer>
      <Header />
      <S.Container>
        <S.GalleryContainer>
          <S.AlbumHeader>
            {albumLoader ? (
              <CircularProgress size={68} />
            ) : (
              <S.AlbumThumb src={album?.cover_url} alt={album?.name} />
            )}
            <S.AlbumName>
              {albumLoader ? "Carregando álbum..." : album?.name}
            </S.AlbumName>
            <S.Button onClick={goToGallery}>Voltar</S.Button>
          </S.AlbumHeader>
          {album && (
            <>
              {gridPicturesLoader ? (
                <ProgressBar />
              ) : !gridPictures[0] ? (
                <S.EmptyListText>Nada para mostrar.</S.EmptyListText>
              ) : (
                <GalleryGrid
                  mobile={mobile}
                  currentImage={currentImage}
                  prevImage={prevImage}
                  nextImage={nextImage}
                  handleClick={handleClick}
                  handleClose={handleClose}
                  gridPictures={gridPictures}
                  handleMoveNext={handleMoveNext}
                  handleMovePrev={handleMovePrev}
                />
              )}
            </>
          )}
        </S.GalleryContainer>
      </S.Container>
      <Footer />
    </S.PageContainer>
  );
};

export default Album;
