import styled from "styled-components";

const Container = styled.div`
  height: 987px;
  background-color: #6f3b8c;
  border: 1px solid #6f3b8c;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 0px) and (max-width: 800px) {
    height: 800px;
  }

  ${(props) =>
    props.mobile
      ? `
  justify-content: flex-end;
  padding-bottom: 68px;
  `
      : ""}
`;

const TitleWrapper = styled.div`
  color: white;
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 26px;
  }
`;

const FormContainer = styled.div`
  width: 631px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 0px) and (max-width: 650px) {
    width: 95vw;
  }
`;

const LabelWrapper = styled.div`
  color: white;
  font-weight: 700;
  font-size: 18px;
  margin-top: 10px;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 16px;
  }
`;

const InputWrapper = styled.input`
  width: 631px;
  height: 40px;
  border: 1px solid white;
  border-radius: 10px;
  background-color: transparent;
  margin-top: 5px;
  padding-left: 10px;
  color: white;

  @media (min-width: 0px) and (max-width: 800px) {
    width: 100%;
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const Button = styled.button`
  width: 303px;
  height: 47px;
  background-color: #fdc028;
  border: 1px solid #fdc028;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f3b8c;
  font-weight: 700;
  font-size: 14px;
  margin-top: 20px;
`;

export {
  Container,
  TitleWrapper,
  FormContainer,
  LabelWrapper,
  InputWrapper,
  Button,
};
