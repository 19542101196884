import PropTypes from "prop-types";

const icon = require("../../assets/PopPontosIcon.png");

const PopPontosIcon = ({ style }) => {
  return <img style={style} src={icon} width={160} alt="PopPontosIcon" />;
};

PopPontosIcon.propTypes = {
  style: PropTypes.object,
};

export default PopPontosIcon;
