import React, { useState, useEffect } from "react";
import calendarHome from "../../../assets/calendarHome.svg";
import locationHome from "../../../assets/locationHome.svg";
//import aboutus from "../../../assets/images/aboutus.webp";
import Header from "../../../components/Header";
import * as S from "./styles";
import ReactDatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";

import BackgroundSlider from "../../../components/BackgroundSlider/BackgroundSlider";
import { userAcceptedLocalStorageUsage } from "../../../LocalStorageAndCookiesModal";

import { Api } from "../../../api/api";
import { toast } from "react-toastify";

import moment from "moment";
import PromotionsCarouselModal from "./PromotionsCarouselModal";

const FirstContainer = ({ mobile }) => {
  const [websiteContent, setWebsiteContent] = useState({});
  const [load, setLoad] = useState(false);

  const getWebsiteContent = () => {
    setLoad(true);
    Api.get("website_content")
      .then((res) => {
        setWebsiteContent(res.data.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    getWebsiteContent();
  }, []);

  const backgroundImagesList =
    websiteContent.home_carousel_content?.map((item) =>
      mobile ? item.mobile : item.desktop
    ) || [];

  const stores = [
    ["TATUAPE", "Tatuapé"],
    ["SANTO_AMARO", "Santo Amaro"],
    // ["BRASILIA", "Brasília"],
  ];

  const [selectedStore, setSelectedStore] = useState("");
  const [date, setDate] = useState("");
  const [avaliableDatePicker, setAvaliableDatePicker] = useState(null);
  const [calendarOpened, setCalendarOpened] = useState(false);

  const tagManagerArgs = {
    gtmId: "GTM-NHNXK6S",
  };

  useEffect(() => {
    if (userAcceptedLocalStorageUsage) TagManager.initialize(tagManagerArgs);
  }, []);

  const changeDate = (value) => {
    setDate(value);
  };

  const handleStoreSelection = (event) => {
    setSelectedStore(event.target.value);
  };

  const addToCartPixel = () => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        if (userAcceptedLocalStorageUsage) window.fbq("track", "AddToWishlist");
      }
    }
  };

  const handleOpenSalesClick = () => {
    addToCartPixel();
    if (!selectedStore || !moment(date).isValid())
      return window.open(
        `${process.env.REACT_APP_OLD_WEBSITE_URL}/comprar-ingressos?origem=website`
      );
    window.open(
      `${
        process.env.REACT_APP_OLD_WEBSITE_URL
      }/comprar-ingressos?filial=${selectedStore}&data=${moment(date).format(
        "YYYY-MM-DD"
      )}&origem=website`
    );
  };

  useEffect(() => {
    if (!selectedStore) return setAvaliableDatePicker([]);
    setDate("");
    Api.get("calendar_dates", {
      params: {
        unit_var_name: selectedStore,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().add(1, "month").endOf("month").format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        setAvaliableDatePicker(res.data.list);
      })
      .catch((error) => {
        setSelectedStore("");
      });
  }, [selectedStore]);

  const handleOpenCalendar = () => setCalendarOpened(true);
  const handleCloseCalendar = () => setCalendarOpened(false);

  return (
    <>
      <PromotionsCarouselModal
        open={[true, "true"].includes(websiteContent?.promo_banner_enabled)}
        content={websiteContent?.promo_banner_content?.map((item) => ({
          src: mobile ? item.mobile : item.desktop,
          tooltip: item.tooltip,
        }))}
      />
      <S.Container
        style={{
          background: `rgba(111, 59, 140, 0.6)`,
        }}
        mobile={mobile}
        className="position-relative"
      >
        <Header backgroundColor={"transparent"} />

        <BackgroundSlider
          id={"ReactBackgroundSlider"}
          images={backgroundImagesList}
          duration={websiteContent.home_carousel_item_duration}
          transition={2}
          style={{ minHeight: 650, zIndex: -50 }}
        />

        <S.CenterContainer mobile={mobile}>
          <S.TitleWrapper>{websiteContent.home_carousel_title}</S.TitleWrapper>
          <S.InputContainer>
            <S.InputWrapper>
              <S.IconWrapper>
                <img src={locationHome} alt={"Escolha a Unidade"} />
              </S.IconWrapper>
              <S.DropDownSelector
                id="store_name_selector"
                value={selectedStore}
                onChange={handleStoreSelection}
              >
                <S.DropDownOption value="" disabled hidden>
                  Escolha a Unidade
                </S.DropDownOption>
                {stores.map(([storeName, storeNameDisplay]) => {
                  return (
                    <S.DropDownOption key={storeName} value={storeName}>
                      {storeNameDisplay}
                    </S.DropDownOption>
                  );
                })}
              </S.DropDownSelector>
            </S.InputWrapper>
            <S.InputWrapper onClick={handleOpenCalendar}>
              <S.CalendarContainer hideInput={!date}>
                <ReactDatePicker
                  className="ticket-date"
                  onChange={(value) => changeDate(value)}
                  clearIcon={null}
                  value={date}
                  isOpen={calendarOpened}
                  calendarIcon={
                    <S.IconWrapper>
                      <img src={calendarHome} alt={"Escolha a Data"} />
                    </S.IconWrapper>
                  }
                  onCalendarOpen={handleOpenCalendar}
                  onCalendarClose={handleCloseCalendar}
                  locale={"pt-BR"}
                  minDetail={"year"}
                  showLeadingZeros={true}
                  minDate={new Date()}
                  maxDate={
                    avaliableDatePicker && avaliableDatePicker?.length > 0
                      ? new Date(
                          avaliableDatePicker[
                            avaliableDatePicker.length - 1
                          ]?.date
                        )
                      : null
                  }
                  tileDisabled={({ activeStartDate, date, view }) => {
                    const formatedDate = moment(date).format("YYYY-MM-DD");

                    const calendarDate = avaliableDatePicker?.find(
                      (item) =>
                        formatedDate === moment(item.date).format("YYYY-MM-DD")
                    );

                    if (
                      calendarDate &&
                      calendarDate.occupation_rate !== "100.00"
                    ) {
                      return false;
                    }
                    return true;
                  }}
                />
              </S.CalendarContainer>
              <S.InputPlaceholder
                hidden={date}
                style={{ fontSize: "14px", fontWeight: "bold" }}
                onClick={handleOpenCalendar}
              >
                Escolha a Data
              </S.InputPlaceholder>
            </S.InputWrapper>
          </S.InputContainer>

          <S.Button onClick={handleOpenSalesClick}>
            Ver preços e disponibilidade
          </S.Button>
          <S.InputContainer>
            <S.TextWrapper>
              Compra presencial sujeita à lotação. Garanta online
              antecipadamente!
            </S.TextWrapper>
            <Link to="/Duvidas">
              <S.TextWrapper
                textDecoration={"underline #FDC028"}
                color={"#FDC028"}
                style={{ cursor: "pointer" }}
              >
                Dúvidas? Veja aqui!
              </S.TextWrapper>
            </Link>
          </S.InputContainer>
        </S.CenterContainer>
      </S.Container>
    </>
  );
};

export default FirstContainer;
