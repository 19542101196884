import { v4 as uuidv4 } from "uuid";
import donuts from "../../../assets/donuts.svg";
import PricesTables from "../PricesTables";

const Donuts = () => {
  return (
    <div style={{ marginTop: -40 }} className="bg-primary rounded-top-5 p-1">
      <div className="d-flex flex-column align-items-center justify-content-center gap-3 pt-5">
        <img src={donuts} alt="ícone donuts" />
        <span
          className="text-white text-center fw-bold"
          style={{ maxWidth: 800 }}
        >
          Você sabia que o PopHaus Santo Amaro tem uma unidade d’O
          Tradicionalíssimo integrada ao parque? Você pode adicionar mini donuts
          deliciosos ao cardápio da sua festa.
        </span>
      </div>
      <PricesTables
        className={"bg-primary"}
        list={[
          {
            id: uuidv4(),
            title: "Sabores",
            description:
              "Classical, tradicional, churros, formigueiro, ovomaltine, the simpson e beijinho",
            prices: [
              { id: uuidv4(), a: 5 },
              { id: uuidv4(), a: 4.75 },
              { id: uuidv4(), a: 4.5 },
              { id: uuidv4(), a: 4.25 },
              { id: uuidv4(), a: 4 },
            ],
            headers: [],
            verticalHeaders: [
              "Até 9 unidades",
              "De 10 a 20 unidades",
              "De 21 a 30 unidades",
              "De 31 a 40 unidades",
              "A partir de 41 unidades",
            ],
            className: "text-white border-white",
            headersClassName: "text-white border-white",
            pricesClassName: "border-white bg-white text-primary",
          },
          {
            id: uuidv4(),
            title: "Sabores",
            description: "Paçoca, Kit Kat Branco e Kit Kat Preto",
            prices: [
              { id: uuidv4(), a: 5.5 },
              { id: uuidv4(), a: 5.23 },
              { id: uuidv4(), a: 4.95 },
              { id: uuidv4(), a: 4.68 },
              { id: uuidv4(), a: 4.4 },
            ],
            headers: [],
            verticalHeaders: [
              "Até 9 unidades",
              "De 10 a 20 unidades",
              "De 21 a 30 unidades",
              "De 31 a 40 unidades",
              "A partir de 41 unidades",
            ],
            className: "text-white border-white",
            headersClassName: "text-white border-white",
            pricesClassName: "border-white bg-white text-primary",
          },
        ]}
      />
    </div>
  );
};

export default Donuts;
