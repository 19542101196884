import styled from "styled-components";

const Container = styled.div`
  background-color: #6f3b8c;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  border: 1px solid transparent;
  border-radius: 20px 20px 0px 0px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const ColumnTitle = styled.div`
  color: white;
  font-weight: 700;
  cursor: pointer;
  margin-top: 20px;
`;

const ColumnItem = styled.div`
  color: white;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
`;

const ColumnText = styled.div`
  color: white;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.9em")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "10px")};
  cursor: ${({ cursor }) => (cursor ? cursor : "")};
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;

const IconWrapper = styled.div`
  width: 64px;
  height: 55px;
  border: 1px solid #fdc028;
  border-radius: 12px;
  background-color: #fdc028;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export {
  ColumnWrapper,
  ColumnTitle,
  ColumnItem,
  LogoWrapper,
  IconWrapper,
  IconContainer,
  Container,
  TitleContainer,
  ColumnText,
};
