import styled from "styled-components";

const MenuWrapper = styled.div`
  font-size: 1em;
  font-weight: 700;
  color: white;
  cursor: pointer;
`;

export { MenuWrapper };
