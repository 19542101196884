import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Image from "react-bootstrap/Image";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import * as S from "./styles";
import PopPontosImage from "../../assets/PopPontos.png";
import { Button, Col, Row } from "react-bootstrap";
import { IoEnterOutline, IoCartOutline } from "react-icons/io5";
import { AiOutlineStar } from "react-icons/ai";
import { useBootstrapBreakpoint } from "../../utils/util";
import PopPontosIcon from "../../components/PopPontosIcon";

const PopPontos = () => {
  const breakpoint = useBootstrapBreakpoint();

  const termsTitleRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search.substring(1));
  const param = urlParams.get("param");

  useEffect(() => {
    if (param === "openTerms") {
      termsTitleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [param]);

  const list = [
    {
      label: "Faça o login",
      icon: IoEnterOutline,
      description: "Faça o seu login em nosso site compras.pophaus.com.br",
    },
    {
      label: "Realize sua compra",
      icon: IoCartOutline,
      description:
        "Adicione no seu carrinho a quantidade de ingressos e outros produtos PopHaus que gostaria de adquirir",
    },
    {
      label: "Prossiga para a finalização da compra",
      icon: PopPontosIcon,
      description:
        "No momento do pagamento, adicione a quantidade de pontos que quer usar e veja o desconto ser aplicado no valor dos ingressos",
    },
  ];

  const getItems = () => {
    return list.map((item) => (
      <Col
        key={`card_item_${item.label}`}
        xs={12}
        sm={6}
        lg={4}
        className="d-flex flex-column gap-4 text-center"
      >
        <div
          className="btn-primary bg-primary rounded-4 p-4 fs-6 fw-bold d-flex align-items-center justify-content-center"
          style={{ height: 90 }}
        >
          {item.label}
        </div>
        <div className="rounded-4 border border-4 border-primary p-4 text-primary">
          {<item.icon size="10rem" style={{ maxWidth: "10rem" }} />}
        </div>
        <span
          className="text-primary align-self-center fw-bold"
          style={{ maxWidth: 300, fontSize: "1rem" }}
        >
          {item.description}
        </span>
      </Col>
    ));
  };

  const handleOpenCheckout = () => {
    window.open(`${process.env.REACT_APP_OLD_WEBSITE_URL}/comprar-ingressos`);
  };

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Programa de fidelidade Pop Pontos: ganhe desconto no PopHaus | PopHaus
        </title>
      </Helmet>
      <Header />
      <S.TitleContainer
        className="d-flex bg-info w-100 flex-wrap-reverse gap-5 pb-5"
        style={styles.containerPadding(breakpoint)}
      >
        <div style={{ flex: 1 }} className="d-flex flex-column gap-4">
          <S.TitleWrapper className="fs-1">Programa Pop Pontos</S.TitleWrapper>
          <span className="text-white" style={{ fontSize: "1.2em" }}>
            Acumule pontos em cada compra feita pelo site e ganhe descontos no
            pagamento de novos ingressos.
          </span>
          <Button
            className="rounded-pill fw-bold align-self-start px-5"
            onClick={handleOpenCheckout}
          >
            Aproveite já o benefício
          </Button>
        </div>
        <div
          style={{ flex: 1 }}
          className="d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <Image
            fluid
            src={PopPontosImage}
            style={{ minWidth: 200, maxHeight: 250 }}
          />
        </div>
      </S.TitleContainer>
      <S.CenterContainer
        className="bg-secondary pb-5"
        style={styles.containerPadding(breakpoint)}
      >
        <S.TitleWrapper className="" style={{ fontSize: "2.2em" }}>
          Como utilizar seus Pop Pontos nas suas compras:
        </S.TitleWrapper>
        <Row className="g-5 mt-2 w-100 justify-content-center">
          {getItems()}
        </Row>
      </S.CenterContainer>
      <S.CenterContainer
        className="bg-orange text-black pb-5"
        style={styles.containerPadding(breakpoint)}
        ref={termsTitleRef}
      >
        <div className="bg-white rounded-4 p-4 w-100">
          <S.TitleWrapper
            className="text-primary"
            style={{ fontSize: "2.2em" }}
          >
            Termos e Condições
          </S.TitleWrapper>
          <div
            className="text-start"
            style={{
              whiteSpace: "pre-wrap",
              maxHeight: "70vh",
              overflowY: "auto",
              lineHeight: "1.5",
            }}
          >
            {`
O Pop Pontos é o programa de fidelidade do Grupo PopHaus, que concede pontos a cada compra nos parques; os pontos podem ser trocados por descontos em compras futuras. Confira os Termos de Serviço e as condições de Uso do programa Pop Pontos:

1. Conversão: cada R$ 1 em compras feitas no site do Pop gera 1 Pop Ponto;
      1.1. Apenas o valor da compra é convertido em Pop Pontos. Taxas e descontos não contabilizam para a conversão;
      1.2 A relação da conversão de pontos recebidos em compras poderá ser distinta entre as campanhas e o Grupo PopHaus pode mudar a relação de conversão a seu exclusivo critério, sem aviso prévio;
      1.3. O PopHaus se reserva o direito de aumentar ou diminuir a equivalência dos pontos em reais sem aviso prévio.
  
2. Os pontos adquiridos podem ser acumulados e trocados por descontos em compras futuras. 

3. Os pontos disponíveis podem ser usados para o pagamento integral ou parcial de novas compras. 

4. Os pontos disponíveis podem ser usados apenas para o pagamento completo ou parcial de ingressos, não se aplicando a outros produtos do PopHaus.

5. Os pontos recebidos só ficam disponíveis para uso 24 horas após a visita ao parque correspondente ao ingresso comprado que rendeu a pontuação.

6. O prazo para o cliente resgatar os pontos acumulados e usufruir dos benefícios será de 2 (dois) meses, contados a partir do dia em que os pontos ficarem disponíveis no extrato.

7. Especificamente para o resgate de pontos do programa Pop Pontos no site do PopHaus, as regras serão as seguintes:
      a. Para o cliente resgatar os pontos, ele deve fazer login no site www.pophaus.com.br;
      b. Selecionar normalmente os ingressos que gostaria de comprar; 
      c. Se o cliente tiver pontos para serem resgatados, o saldo de pontos será exibido na tela de checkout (finalização de compra);
      d. Digitar os pontos que gostaria de usar na hora do pagamento; 
      e. O desconto equivalente aos pontos será aplicado apenas sobre o valor dos ingressos - demais produtos do PopHaus não fazem parte do programa.
      
8. Pagamentos feitos com pontos não geram novos pontos. Para compras com pagamento parcial em pontos, o saldo pago com outro método de pagamento gerará novos pontos.

9. Só acumulam pontos as compras feitas pelo site www.pophaus.com.br. 

10. É possível conferir os pontos adquiridos e seus respectivos status (pendente, resgatado, expirado ou disponível) no ambiente logado do cliente no site www.pophaus.com.br.

11. No ambiente logado, os pontos podem ser consultados por: 
      a. Disponíveis: pontos que já podem ser usados em novas compras;
      b. Pendentes: pontos que já constam no extrato, mas ainda não podem ser utilizados em novas compras; 
      c. Resgatados: pontos que foram usados em compras
      d. Expirados: pontos que não foram usados antes dos 2 meses.
      
12. Em caso de pagamento parcial ou integral com pontos de compras recusadas ou reembolsadas, os pontos serão reembolsados ao comprador e aparecerão no extrato como “disponíveis”, mantendo a data de expiração original dos pontos. 

13. Em caso de compras estornadas os pontos recebidos também serão estornados.

14. A inscrição no Pop Pontos é feita de forma automática com a primeira compra feita no parque a partir de 08 de junho de 2023. 

15. Caso não queira participar do programa Pop Pontos, o cliente pode optar por não usar os pontos recebidos.

16. O programa Pop Pontos passa a valer a partir de 08 de junho de 2023. 

17. Encerramento do Programa: o Programa Pop Pontos vigorará por tempo indeterminado, a partir da data de lançamento, podendo ser suspenso a critério do PopHaus mediante comunicação, com antecedência de 30 (trinta) dias, a qual será afixada nas lojas e disponibilizada no site www.pophaus.com.br.
    
18. Bloqueio/Suspensão cadastral do Programa: o Programa Pop Pontos poderá ser bloqueado ou suspenso, para um determinado cliente, cujos dados de cadastro sejam inexatos, incorretos ou falsos.
      18.1. A suspensão ou o encerramento do Programa não dá direito a qualquer indenização aos Clientes fidelizados que, desde já, estão cientes que se trata de uma prerrogativa do PopHaus;
      18.2. O Cliente será comunicado previamente sobre qualquer alteração do Programa pelos principais meios de comunicação do PopHaus.

19. Uso da conta por terceiros: O PopHaus não será responsável por eventual acesso de terceiros não autorizados à conta do Cliente, cabendo somente ao Cliente a responsabilidade pela guarda do login e senha em local seguro, assim como o não compartilhamento destas informações com terceiros.

20. Detecção de Fraudes: Caso seja detectado indício(s) de fraude(s) no uso do Programa pelo Cliente, o PopHaus poderá apurar a situação e, sendo confirmado seu uso indevido, poderá bloquear e/ou excluir o cadastro do Cliente por infração às regras do Programa “Pop Pontos".

21. Caso o Cliente, através de meios ilícitos, descumpra ou desrespeite intencionalmente, em benefício próprio ou de terceiros, qualquer das cláusulas e condições deste Regulamento, o PopHaus poderá bloquear e/ou excluir o cadastro do Cliente por infração às regras do Programa “Pop Pontos”, sem prejuízo de responsabilização penal ou civil.

22. Alteração Cadastral: o participante do Programa Pop Pontos deverá comunicar ao PopHaus, qualquer alteração cadastral, sendo o único responsável por prejuízos ou quaisquer danos ocorridos em decorrência do evento citado acima.

23. Utilização de dados: o participante autoriza o PopHaus a armazenar em banco de dados, para posterior consulta e análise, automatizada ou não, das informações contidas no cadastro no Programa Pop Pontos com base na LGPD – Lei Geral de Proteção de Dados Pessoais, bem como as informações referentes aos hábitos de consumo. O PopHaus poderá desenvolver e implantar ações promocionais e institucionais, fazendo a comunicação direta e/ou indireta, com base nas informações colhidas pela utilização do Programa Pop Pontos, além de oferecer Descontos personalizados com base nas informações fornecidas.

24. Isenção de responsabilidade: O PopHaus não se responsabiliza por: 
      a. uso indevido da plataforma pelo cliente;
      b. por inobservância do Cliente às regras e condições de uso do programa e da plataforma plataformas;
      c. por problemas de conexão de Internet ou de dados no servidor do Cliente;
      d. por consumo de franquia do plano do Cliente;
      e. demais situações que estejam fora do controle do PopHaus para funcionamento e uso do programa e plataformas.

25. Atualização dos Termos: O Cliente está ciente que os Termos estão sujeitos a constante atualização para aprimoramento. Assim, o PopHaus reserva-se ao direito de modificá-los a qualquer momento, conforme necessidade ou conveniência, bem como para adequação legal decorrente de Lei ou normas que possuam força jurídica equivalente.
      
26. Lei aplicável e foro. Estes Termos serão interpretados segundo a legislação brasileira, no idioma português, sendo eleito o foro da comarca de São Paulo para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
      26.1. O Cliente será comunicado da atualização dos Termos sempre que acessar o seu cadastro, podendo dar o “Aceite” à nova versão, dando continuidade e permanência nos Programas e utilização dos benefícios ofertados pela PopHaus.

Ao usar o programa Pop Pontos, você concorda com estes Termos de Serviço e com as Condições de Uso do PopHaus.
          `}
          </div>
        </div>
      </S.CenterContainer>
      <Footer />
    </>
  );
};

const styles = {
  containerPadding: (breakpoint) => {
    if (breakpoint === "xs") return { padding: "1rem 2rem 1rem 2rem" };
    if (breakpoint === "sm") return { padding: "2rem 4rem 2rem 4rem" };
    return { padding: "4rem 8rem 4rem 8rem" };
  },
};

export default PopPontos;
