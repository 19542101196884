import styled from "styled-components";

const Container = styled.div`
  margin: 0px;
  height: 80vh;
  min-height: 400px;
  margin-top: -20px;
  border: 1px solid transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-size: cover,
  background-repeat: no-repeat,
  background-position: center center,
`;

const CenterContainer = styled.div`
  padding-left: 25%;
  padding-right: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.mobile ? "flex-end" : "center")};
  ${(props) => (props.mobile ? "padding-bottom: 56px;" : "")}
  align-items: center;
  height: 80vh;

  @media (min-width: 0px) and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const TitleWrapper = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 2em;
  margin-bottom: 30px;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 26px;
    max-width: 300px;
  }
`;

const TextWrapper = styled.p`
  color: white;
  font-weight: 500;
  font-size: 0.8em;
`;

const ImageContainer = styled.div`
  display: flex;
  alignitems: center;
  justifycontent: center;
  border-radius: 50%;
  border-style: solid;
  border-color: #fdc028;
  border-width: 1px;
  padding: 4px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
  object-position: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100px;
`;

export {
  Container,
  CenterContainer,
  TextWrapper,
  TitleWrapper,
  ImageContainer,
  Image,
  RowContainer,
};
