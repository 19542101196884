import styled from "styled-components";

const Container = styled.div`
  height: 969px;
  background-color: #ed5046;
  border: 1px solid #ed5046;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 0px) and (max-width: 800px) {
    height: 900px;
  }

  ${(props) =>
    props.mobile
      ? `
   justify-content: flex-end;
   padding-bottom: 60px;
  `
      : ""}
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  @media (min-width: 0px) and (max-width: 800px) {
    text-align: center;
    flex-direction: column-reverse;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 0px) and (max-width: 600px) {
    margin-bottom: 30px;
  }
`;

const TitleWrapper = styled.div`
  color: white;
  font-size: 40px;
  font-weight: 700;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "0px")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};

  @media (min-width: 0px) and (max-width: 900px) {
    margin-left: 0px;
    font-size: 26px;
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  }
`;

const TextWrapper = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 400;
  margin-left: 100px;

  @media (min-width: 0px) and (max-width: 900px) {
    margin-left: 0px;
    font-size: 16px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Container,
  TitleWrapper,
  TextWrapper,
  CenterContainer,
  TextContainer,
  IconContainer,
  IconWrapper,
};
