import styled from "styled-components";

const Container = styled.div`
  margin: 0px;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  ${({ mobile }) => (mobile ? "justify-content: flex-end;" : "")}
`;

const TitleWrapper = styled.h1`
  color: white;
  font-weight: 700;
  margin-top: 5px;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 26px;
    max-width: 250px;
    text-align: center;
  }
  @media (min-width: 801px) and (max-width: 99999px) {
    font-size: 64px;
    line-height: 75px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  width: 276px;
  height: 48px;
  border: 1px solid #6f3b8c;
  border-radius: 10px;
  background-color: #6f3b8c;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding-left: 2px;
`;

const IconWrapper = styled.div`
  background-color: #fdc028;
  border: 1px solid #fdc028;
  border-radius: 10px;
  width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-itens: center;
`;

const Button = styled.button`
  width: 303px;
  height: 47px;
  background-color: #fdc028;
  border: 1px solid #fdc028;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f3b8c;
  font-weight: 700;
  font-size: 14px;
  margin-top: 20px;
`;

const TextWrapper = styled.div`
  color: ${({ color }) => (color ? color : "white")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  margin-left: 2px;
  max-width: 500px;
  text-align: center;

  @media (min-width: 0px) and (max-width: 800px) {
    font-size: 16px;
    max-width: 300px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  alignitems: center;
  justifycontent: center;
  border-radius: 50%;
  border-style: solid;
  border-color: #fdc028;
  border-width: 1px;
  padding: 4px;
  ${({ selected }) => (selected ? "background-color: #fdc028" : "")}
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
  object-position: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  min-width: 100px;
`;

const ItemTextWrapper = styled.p`
  color: white;
  font-weight: 500;
  font-size: 0.8em;
`;

export {
  Container,
  InputWrapper,
  InputContainer,
  IconWrapper,
  Button,
  TextWrapper,
  CenterContainer,
  TitleWrapper,
  ImageContainer,
  Image,
  RowContainer,
  ItemTextWrapper,
};
