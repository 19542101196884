import React, { useState } from "react";
import "./styles.scss";

export const ResponsibilityTerm = ({}) => {
  const [open, setOpen] = useState(
    localStorage.getItem("ResponsibilityTermPopHausas") === null
  );

  const handleAccept = () => {
    localStorage.setItem("ResponsibilityTermPopHausas", true);
    setOpen(false);
    // Navigate to the /Pophaus route after accepting
  };

  const handleReject = () => {
    localStorage.setItem("ResponsibilityTermPopHausas", false);
    setOpen(false);
  };

  if (!open) return <></>;

  return (
    <div className="responsibilityTerm__container">
      <div className="d-flex flex-column">
        <h6>Atualização dos termos de uso dos Pop Pontos</h6>
        <span style={{ maxWidth: 600 }}>
          Os Termos e condições do Programa Pop Pontos foram atualizados. <br />
          <a
            style={{ cursor: "pointer", textDecoration: "underline" }}
            href={"/PopPontos?param=openTerms"}
          >
            Clique aqui
          </a>
          {" "}para ver as novas condições.
        </span>
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ borderRadius: 4, marginLeft: 20, flexDirection: "column" }}
      >
        <button
          className="btn-primary p-2"
          style={{ borderRadius: 4, minWidth: 100 }}
          onClick={handleAccept}
        >
          Concordar
        </button>
      </div>
    </div>
  );
};
