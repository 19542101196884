import styled from "styled-components";
import { darken } from "polished";
export const InputWrapper = styled.div`
    display: flex;
    background-color: ${darken(0.1, '#6f3b8c')};
    border-radius: 50px;
    width: 240px;
    height: 48px;
    padding-right: 10px;
    align-items: center;
    input {
        background-color: transparent;
        border-radius:50px 0 0 50px;
        height: 100%;
        width: 100%;
        padding: 10px;
        color: white;
        border-color:transparent;
        :focus{
            border: none;
            outline: none;
        }
    }
    button {
       border-radius: 50%;
       width: 36px;
       height: 36px;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color:  ${darken(0.15, '#6f3b8c')};
       font-size: 22px;
       border-color: #FFF;
       color: #fff;
        :first-of-type{
            margin-right: 5px;
        }
    }

    
`;