import styled from "styled-components";
import { darken } from "polished";
import media from "styled-media-query";

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  max-width: 800px;

  select {
    width: 12rem;
    background-color: ${darken(0.1, "#6f3b8c")};
    border: none;
    border-radius: 50px;
    height: 3.5rem;
    outline: none;
    color: #fff;
    font-size: 1rem;
    &:focus {
      outline: none;
      border: none;
    }
  }
  @media (max-width: 690px) {
    display: flex;
    flex-direction: column;
    margin: 0 32px;
    gap: 32px;
  }
  @media (max-width: 600px) {
    max-width: 600px;
  }
`;

export const DescriptionText = styled.small`
  font-size: 11px;
  color: white;
`;

export const TestCon = styled.div`
  width: 100%;
  max-width: 575px;
`;
export const TextNotify = styled.span`
  font-weight: bold;
  color: #6f3b8c;
`;
