import React, { useState } from "react";
import * as S from "./styles";
import closeIcon from "../../../assets/closeIcon.svg";
import previousAtraction from "../../../assets/previousAtraction.svg";
import nextAtraction from "../../../assets/nextAtraction.svg";
import { getWindowDimensions } from "../../../services/getWindowDimensions";
import { Link } from "react-router-dom";

const MobileAtracoes = ({ objectMapper, area, unit, setShowAtracao }) => {
  const width = getWindowDimensions().width;
  const barWidth = width / objectMapper[unit].Atracoes[area].Toys.length - 5;
  const height = getWindowDimensions().height;
  const [activeAtraction, setActiveAtraction] = useState(0);

  return (
    <div
      style={{
        position: "fixed",
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 5,
      }}
    >
      <S.Container
        style={{
          backgroundImage: `linear-gradient(0.4deg, #6F3B8C 26.13%, rgba(111, 59, 140, 0) 45.37%), linear-gradient(0deg, rgba(117, 54, 148, 0.2), rgba(117, 54, 148, 0.2)), url(${objectMapper[unit].Atracoes[area].Toys[activeAtraction].Image})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <S.CloseButtonContainer>
          <S.BarWrapper>
            {objectMapper[unit].Atracoes[area].Toys.map((e, index) => {
              return (
                <S.Bar
                  key={index}
                  width={barWidth}
                  active={
                    activeAtraction > index || activeAtraction === index
                      ? true
                      : false
                  }
                ></S.Bar>
              );
            })}
          </S.BarWrapper>
        </S.CloseButtonContainer>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 10,
            paddingTop: 10,
          }}
        >
          <img
            src={closeIcon}
            alt="close icon"
            width={20}
            style={{ cursor: "pointer" }}
            onClick={() => setShowAtracao(null)}
          />
        </div>
        <S.CenterContainer>
          <S.ButtonContainer marginTop={`${height / 2.5}px`}>
            <img
              src={previousAtraction}
              width={30}
              alt="previous atraction"
              style={{ marginRight: 10, cursor: "pointer" }}
              onClick={() =>
                activeAtraction === 0
                  ? setActiveAtraction(
                      objectMapper[unit].Atracoes[area].Toys.length - 1
                    )
                  : setActiveAtraction(activeAtraction - 1)
              }
            />
            <img
              src={nextAtraction}
              width={30}
              alt="next atraction"
              style={{ marginLeft: 10, cursor: "pointer" }}
              onClick={() =>
                activeAtraction ===
                objectMapper[unit].Atracoes[area].Toys.length - 1
                  ? setActiveAtraction(0)
                  : setActiveAtraction(activeAtraction + 1)
              }
            />
          </S.ButtonContainer>
          <S.TextContainer>
            <S.NameWrapper>
              {objectMapper[unit].Atracoes[area].Toys[activeAtraction].Name}
            </S.NameWrapper>
            <S.DescriptionWrapper>
              {
                objectMapper[unit].Atracoes[area].Toys[activeAtraction]
                  .Description
              }
            </S.DescriptionWrapper>
          </S.TextContainer>
        </S.CenterContainer>
      </S.Container>
    </div>
  );
};

export default MobileAtracoes;
