import styled from "styled-components";

const Container = styled.div`
  background-color: #fdc028;
  border: 1px solid #fdc028;
  border-radius: 20px 20px 0px 0px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 64px;

  @media (min-width: 0px) and (max-width: 1300px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  ${(props) =>
    props.mobile
      ? `
   justify-content: flex-end;
  `
      : ""}
`;

const TextWrapper = styled.div`
  color: #6f3b8c;
  font-size: 40px;
  font-weight: 700;

  @media (min-width: 0px) and (max-width: 1300px) {
    font-size: 26px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: 0px) and (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  width: ${({ width }) => (width ? width : "0")};
  height: ${({ height }) => (height ? height : "")};
  border: 1px solid #753694;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  margin-right: 20px;

  @media (min-width: 0px) and (max-width: 1300px) {
    margin-right: 0px;
    margin-top: 10px;
    height: auto;
    padding: 10px 10px 10px 10px;
  }
`;

const CardTextWrapper = styled.div`
  color: #753694;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};

  @media (min-width: 0px) and (max-width: 1300px) {
    font-size: 16px;
  }
`;

const CardTitleWrapper = styled.div`
  color: #753694;
  font-size: 60px;
  height: 100%;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-itens: center;
  margin: 0px;
  line-height: 60px;
  margin-right: 20px;

  @media (min-width: 0px) and (max-width: 1300px) {
    font-size: 36px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
`;

export {
  Container,
  TextWrapper,
  Card,
  CardContainer,
  CardTextWrapper,
  CardTitleWrapper,
  CardWrapper,
};
