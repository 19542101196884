import styled from "styled-components";

const Container = styled.div`
  height: 578px;
  margin: 0px;
  min-height: 500px;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

  @media (min-width: 0px) and (max-width: 1200px) {
    height: 60%;
    margin-top: 0px;
    justify-content: flex-end;
  }
`;

const TitleWrapper = styled.h2`
  color: white;
  font-weight: 700;

  @media (min-width: 0px) and (max-width: 1200px) {
    font-size: 26px;
  }
`;

const TextWrapper = styled.h2`
  color: white;
  font-weight: 400;
  font-size: 18px;
  max-width: 500px;
  text-align: center;

  @media (min-width: 0px) and (max-width: 1200px) {
    font-size: 16px;
    max-width: 300px;
  }
`;

export { Container, CenterContainer, TitleWrapper, TextWrapper };
