import styled from "styled-components";

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnTitle = styled.div`
  color: white;
  font-weight: 700;
`;

const ColumnItem = styled.div`
  color: white;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "600")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.8em")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "10px")};
  cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
  display: flex;
`;

export { ColumnWrapper, ColumnTitle, ColumnItem };
