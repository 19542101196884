import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { SimulatorHook } from "../../../context/simulatorContext";
import { InputBtnGroup } from "../../../components/InputStates";
import * as S from "./styles";
import { TiArrowBackOutline } from "react-icons/ti";
import { Api } from "../../../api/api";
import ModalCardapio from "../cardapio";
import { SubTotal } from "../SubTotal";

const FiveStepSimulator = () => {
  const {
    setCurrentStep,
    socks,
    setSocks,
    socksAnt,
    setSocksAnt,
    towel,
    setTowel,
    cups,
    setCups,
    buffet,
    setBuffet,
    cardapio,
    setCardapio,
    eventType,
    date,
    duration,
    regularTickets,
    plusOneTickets,
    pcdTickets,
    pcdPlusOneTickets,
    kidsTickets,
    kidsPlusOneTickets,
    kioskTarget,
    setKioskTarget,
    setDurationKioskTarget,
    durationKioskTarget,
    setDurationKiosk,
    durationKiosk,
    unit,
    initHours,
    areas,
    currentStep,
    kiosksOptions,
    setKiosksOptions,
    currentStepOption,
    setCurrentStepOption,
    bundle,
  } = SimulatorHook();
  const [openModal, setOpenModal] = useState(false);
  const [price, setPrice] = useState(0);

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if ((!currentStepOption || currentStepOption === "0") && !bundle)
      setBuffet(false);
  }, [currentStepOption, bundle]);

  useEffect(() => {
    function handleDurationKiosk() {
      Api.get("/party_simulator/filial").then((res) => {
        const results = res.data.filials[unit][eventType].durations_kiosks;
        setDurationKiosk(results);
        setDurationKioskTarget(results[0]);
      });
    }
    if (eventType === "single" && !bundle) {
      handleDurationKiosk();
    }
  }, [bundle, eventType]);

  useEffect(() => {
    setLoad(true);

    const obj = {
      filial: unit,
      spaces: areas,
      duration: duration,
      regular: regularTickets,
      plus_one: plusOneTickets,
      kids: kidsTickets,
      kids_plus_one: kidsPlusOneTickets,
      pcd: pcdTickets,
      pcd_plus_one: pcdPlusOneTickets,
      date: date,
      time: initHours,
      kiosk_1: false,
      kiosk_2: false,
      kiosk_3: false,
      kiosk_4: false,
      kiosk_5: false,
      kiosk_6: false,
      kiosk_indoor: false,
      buffet: buffet,
      menu_1: cardapio === 1 && buffet === true ? true : false,
      menu_2: cardapio === 2 && buffet === true ? true : false,
      menu_3: cardapio === 3 && buffet === true ? true : false,
      menu_4: cardapio === 4 && buffet === true ? true : false,
      common_sock: socks,
      non_slip_sock: socksAnt,
      cup: cups,
      towel: towel,
      cabinet_rental: 0,
      cotton_candy: 0,
      cotton_candy_machine: 0,
      exclusive_monitor: 0,
      kiosks_target: bundle
        ? kioskTarget
        : eventType === "single"
        ? kiosksOptions[currentStepOption]
        : kioskTarget,
      model: eventType,
      duration_kiosk: Number(durationKioskTarget),
      bundle,
    };
    Api.post("/party_simulator/party_price", obj)
      .then((res) => {
        console.log({ res });
        setPrice(res.data.data.price_total);
      })
      .finally(() => {
        setLoad(false);
      });
  }, [
    socks,
    socksAnt,
    cups,
    towel,
    cardapio,
    buffet,
    kioskTarget,
    currentStepOption,
    currentStep,
    kiosksOptions,
    bundle,
  ]);

  useEffect(() => {
    if (!bundle)
      Api.post("/party_simulator/kiosk", {
        filial: unit,
        date: date,
        duration: duration,
        regular: regularTickets,
        plus_one: plusOneTickets,
        kids: kidsTickets,
        kids_plus_one: kidsPlusOneTickets,
        model: eventType,
        pcd: pcdTickets,
        duration_kiosk: Number(durationKioskTarget),
        pcd_plus_one: pcdPlusOneTickets,
      }).then((res) => {
        setKiosksOptions(res.data.data);

        if (!durationKiosk) {
          if (eventType === "single") {
          }
        }
        if (eventType === "exclusive") {
          const arr = res.data.data.length - 1;
          setKioskTarget(res.data.data[arr]);
        }
      });
  }, [durationKioskTarget, bundle]);

  function handleNextStep() {
    if (eventType === "single" && !bundle) {
      setKioskTarget(kiosksOptions[currentStepOption]);
    }

    setCurrentStep(6);
  }

  function handleModal() {
    setOpenModal((prevState) => !prevState);
  }

  return (
    <S.Container>
      {<ModalCardapio open={openModal} onClose={handleModal} unit={unit} />}
      <S.ContainerWrapper>
        <Row className="">
          {!bundle && eventType !== "exclusive" ? (
            <>
              <Col sm={Number(currentStepOption) !== 0 ? "6" : "12"}>
                <Form.Group>
                  <Form.Label className="text-white">Quiosque</Form.Label>
                  <Form.Select
                    size="lg"
                    className="select"
                    onChange={(e) => {
                      setCurrentStepOption(e.target.value);
                    }}
                    value={currentStepOption}
                  >
                    {kiosksOptions.map((item, index) => {
                      return <option value={index}>{item.label}</option>;
                    })}
                  </Form.Select>
                  <p className="text-white ">
                    Indicamos a aquisição de{" "}
                    <span className="text-warning">
                      um quiosque a cada 25 convidados
                    </span>
                  </p>
                </Form.Group>
              </Col>
              {Number(currentStepOption) !== 0 && (
                <Col sm="6">
                  <Form.Group>
                    <Form.Label className="text-white">
                      Duração de Quiosque
                    </Form.Label>
                    <Form.Select
                      size="lg"
                      className="select"
                      value={durationKioskTarget}
                      onChange={(e) => {
                        setDurationKioskTarget(e.target.value);
                      }}
                    >
                      {durationKiosk.map((item, index) => {
                        return (
                          <option value={item}>
                            {item} {item > 1 ? "horas" : "hora"}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <p className="text-white ">
                      <span className="text-warning">
                        Tempo que o Quiosque ficara ativo para os participantes
                      </span>
                    </p>
                  </Form.Group>
                </Col>
              )}
            </>
          ) : !bundle ? (
            <p
              className="text-white text-center pb-2"
              style={{ fontWeight: "bold" }}
            >
              Todos os Quiosques estão inclusos no pacote da exclusiva{" "}
            </p>
          ) : (
            <></>
          )}
        </Row>
        {(Number(currentStepOption) !== 0 || eventType === "exclusive") && (
          <Row className="row-buffet mb-2">
            <Col sm="12" lg={buffet === true ? "6" : "12"}>
              <Form.Group>
                <Form.Label className="text-white">Buffet?</Form.Label>
                <Form.Select
                  disabled={bundle ? true : false}
                  size="lg"
                  className="select"
                  value={buffet}
                  onChange={(e) => {
                    e.target.value === "true"
                      ? setBuffet(true)
                      : setBuffet(false);
                  }}
                >
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </Form.Select>
                {!bundle && (
                  <p className="text-white ">
                    <span className="text-warning">
                      Buffet só pode ser contratado para no mínimo 15 pessoas.
                    </span>
                  </p>
                )}
              </Form.Group>
            </Col>
            {buffet === true && (
              <>
                <Col sm="12" lg="6">
                  <Form.Group>
                    <Form.Label className="text-white">
                      {bundle ? "Cardápio do pacote" : "Escolha um cardápio"}
                    </Form.Label>
                    <Form.Select
                      size="lg"
                      className="select"
                      onChange={(e) => setCardapio(Number(e.target.value))}
                      value={cardapio}
                      disabled={bundle ? true : false}
                    >
                      <option value={1}>Cardápio 1</option>
                      <option value={2}>Cardápio 2</option>
                      <option value={3}>Cardápio 3</option>
                      <option value={4}>Cardápio 4</option>
                    </Form.Select>
                    <p className="text-white">
                      Veja{" "}
                      <a
                        href="#"
                        className="text-warning"
                        onClick={() => setOpenModal(true)}
                      >
                        aqui{" "}
                      </a>{" "}
                      as opções de cardápio
                    </p>
                  </Form.Group>
                </Col>
                {unit !== "BRASILIA" && (
                  <Col
                    xs={12}
                    className="text-secondary text-wrap text-center mb-3"
                    style={{ fontSize: "0.8rem" }}
                  >
                    * O Buffet da festa, quando incluso, é servido pelo período
                    de 3h. Para horas adicionais, entre em contato conosco.
                  </Col>
                )}
              </>
            )}
          </Row>
        )}

        <Row className="products">
          {(unit === "TATUAPE" || unit === "SANTO_AMARO") && (
            <Col lg="6">
              <Form.Label className="text-white">Meia Comum</Form.Label>
              <InputBtnGroup state={socks} stateInput={setSocks} text={""} />
            </Col>
          )}

          {unit === "SANTO_AMARO" && (
            <Col lg="6">
              <Form.Label className="text-white">
                Meias Antiderrapantes
              </Form.Label>
              <InputBtnGroup
                state={socksAnt}
                stateInput={setSocksAnt}
                text={""}
              />
              <small
                className="text-white"
                style={{ fontWeight: "bold", fontSize: 9 }}
              >
                Uso obrigatório na área PopJump da unidade de Santo Amaro
              </small>
            </Col>
          )}

          {(unit === "TATUAPE" || unit === "SANTO_AMARO") && (
            <Col lg="6">
              <Form.Label className="text-white">Copos</Form.Label>
              <InputBtnGroup state={cups} stateInput={setCups} text={""} />
            </Col>
          )}

          <Col lg="6">
            <Form.Label className="text-white">Toalha de Banho</Form.Label>
            <InputBtnGroup state={towel} stateInput={setTowel} text={""} />
          </Col>
        </Row>
      </S.ContainerWrapper>
      {!(unit === "BRASILIA" && eventType === "single") && (
        <SubTotal value={price ? price : 0} loading={load} />
      )}
      <div className="wrapper_buttons">
        <button onClick={() => setCurrentStep(4)} className="btn_1">
          <span>Voltar</span>
          <TiArrowBackOutline size="38" />
        </button>
        <button onClick={() => handleNextStep()} className="btn_2">
          Proximo
        </button>
      </div>
    </S.Container>
  );
};

export default FiveStepSimulator;
