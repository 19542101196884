import React from "react";

const Alert = ({ type, message, close, mobile }) => {
  const getAlertType = () => {
    switch (type) {
      case "success":
        return "Sucesso!";
      case "danger":
        return "Erro!";
      case "warning":
        return "Atenção!";
      default:
        return "";
    }
  };

  if (!type || !message) return;

  return (
    <div
      class={`alert alert-${type} alert-dismissible fade show`}
      style={{
        position: "fixed",
        bottom: 8,
        right: mobile ? 8 : "30%",
        left: mobile ? 8 : "30%",
        zIndex: 50,
        minWidth: mobile ? 0 : 300,
      }}
    >
      <strong>{getAlertType()}</strong>
      <br />
      {message}
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        onClick={close}
      ></button>
    </div>
  );
};

export default Alert;
