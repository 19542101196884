import styled from "styled-components";

const BuyButton = styled.div`
  background-color: #fdc028;
  height: 44px;
  border: 1px solid #fdc028;
  border-radius: 20px;
  color: #6f3b8c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: ${({ width }) => (width ? width : "")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  cursor: pointer;
`;

export { BuyButton };
