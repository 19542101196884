import React, { useState } from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import facebookIcon from "../../assets/facebookIcon.svg";
import instagramIcon from "../../assets/instagramIcon.svg";
import youtubeIcon from "../../assets/youtubeIcon.svg";
import mobileUpArrow from "../../assets/mobileIcons/mobileUpArrow.svg";
import mobileDownArrow from "../../assets/mobileIcons/mobileDownArrow.svg";
import lineFooter from "../../assets/mobileIcons/lineFooter.svg";
import { dataContact } from "../../utils/dataContact";

const MobileFooter = () => {
  const navigate = useNavigate();
  const [institucional, setInstitucional] = useState(false);
  const [unidadeSantoAmaro, setUnidadeSantoAmaro] = useState(false);
  const [unidadeTatuape, setUnidadeTatuape] = useState(false);
  const [unidadeBrasilia, setUnidadeBrasilia] = useState(false);
  const [encontre, setEncontre] = useState(false);

  const openPrivacyPolitics = () => {
    window.open(
      "https://pophaus-files.s3.us-east-2.amazonaws.com/Pol%C3%ADtica+de+privacidade.pdf",
      "_blank"
    );
  };

  const openUsageTerms = () => {
    window.open(
      "https://pophaus-files.s3.us-east-2.amazonaws.com/Termos+de+uso.pdf",
      "_blank"
    );
  };

  const openMail = (mail) => {
    window.open(`mailto:${mail}`);
  };

  return (
    <S.Container>
      <S.ColumnWrapper>
        <S.TitleContainer onClick={() => setInstitucional(!institucional)}>
          <S.ColumnTitle>Institucional</S.ColumnTitle>
          <img
            src={institucional ? mobileUpArrow : mobileDownArrow}
            alt="up arrow"
            style={{ cursor: "pointer" }}
          />
        </S.TitleContainer>
        {institucional ? (
          <>
            <S.ColumnItem onClick={() => navigate("/Sobre")}>
              Sobre o PopHaus
            </S.ColumnItem>
            <S.ColumnItem onClick={() => navigate("/PopPontos")}>
              Programa de Pontos
            </S.ColumnItem>
            <S.ColumnItem onClick={() => navigate("/Galeria")}>
              Galeria
            </S.ColumnItem>
            <S.ColumnItem>
              <div
                className="text-white"
                onClick={openPrivacyPolitics}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                Política de Privacidade
              </div>
            </S.ColumnItem>
            <S.ColumnItem>
              <div
                className="text-white"
                onClick={openUsageTerms}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                Termos de Uso
              </div>
            </S.ColumnItem>
          </>
        ) : null}
      </S.ColumnWrapper>
      <S.ColumnWrapper>
        <img src={lineFooter} alt="line" />
        <S.TitleContainer
          onClick={() => setUnidadeSantoAmaro(!unidadeSantoAmaro)}
        >
          <S.ColumnTitle>Unidade Santo Amaro</S.ColumnTitle>
          <img
            src={unidadeSantoAmaro ? mobileUpArrow : mobileDownArrow}
            alt="up arrow"
            style={{ cursor: "pointer" }}
          />
        </S.TitleContainer>
        {unidadeSantoAmaro ? (
          <>
            <S.ColumnText>
              <strong>Horário de funcionamento</strong>
            </S.ColumnText>
            <S.ColumnText marginTop={"0px"} fontWeight={400}>
              {dataContact.SANTO_AMARO.horario}
            </S.ColumnText>
            <S.ColumnText>
              <strong>Endereço</strong>
            </S.ColumnText>
            <S.ColumnText
              marginTop={"0px"}
              fontWeight={400}
              onClick={() =>
                window.open("https://goo.gl/maps/pE787VJGLwgk7eSg9")
              }
            >
              Rua Doutor Rubens Gomes Bueno, 288 - Várzea de Baixo, São Paulo -
              SP, 04730-000 (Ver no mapa)
            </S.ColumnText>
            <S.ColumnText
              onClick={() => window.open(dataContact.SANTO_AMARO.work_with_us)}
            >
              <strong className="text-secondary">Trabalhe conosco</strong>
            </S.ColumnText>
            <S.ColumnText>
              <strong>WhatsApp</strong>
            </S.ColumnText>
            <S.ColumnText
              marginTop={"0px"}
              fontWeight={400}
              onClick={() =>
                window.open("https://api.whatsapp.com/send?phone=5511917706110")
              }
            >
              Número: (11) 91770-6110
            </S.ColumnText>
            <S.ColumnText>
              <strong>Telefone fixo</strong>
            </S.ColumnText>
            <S.ColumnText
              marginTop={"0px"}
              fontWeight={400}
              onClick={() => {
                window.location.href = `tel:(11)5641-1720`;
              }}
            >
              Número: (11) 5641-1720
            </S.ColumnText>
            <S.ColumnText>
              <strong>CNPJ</strong>
            </S.ColumnText>
            <S.ColumnText marginTop={"0px"} fontWeight={400}>
              CNPJ: 22.308.504/0001-06
            </S.ColumnText>
            <S.ColumnText>
              <strong>Imprensa</strong>
            </S.ColumnText>
            <S.ColumnText
              marginTop={"0px"}
              fontWeight={400}
              onClick={() => openMail("marketing@pophaus.net")}
            >
              marketing@pophaus.net
            </S.ColumnText>
          </>
        ) : null}
      </S.ColumnWrapper>
      <S.ColumnWrapper>
        <img src={lineFooter} alt="line" />
        <S.TitleContainer onClick={() => setUnidadeTatuape(!unidadeTatuape)}>
          <S.ColumnTitle>Unidade Tatuapé</S.ColumnTitle>
          <img
            src={unidadeTatuape ? mobileUpArrow : mobileDownArrow}
            alt="up arrow"
            style={{ cursor: "pointer" }}
          />
        </S.TitleContainer>
        {unidadeTatuape ? (
          <>
            <S.ColumnText>
              <strong>Horário de funcionamento</strong>
            </S.ColumnText>
            <S.ColumnText marginTop={"0px"} fontWeight={400}>
              {dataContact.TATUAPE.horario}
            </S.ColumnText>
            <S.ColumnText>
              <strong>Endereço</strong>
            </S.ColumnText>
            <S.ColumnText
              marginTop={"0px"}
              fontWeight={400}
              onClick={() =>
                window.open("https://goo.gl/maps/XgeBX66UoYczXbncA")
              }
            >
              Rua Euclides Pacheco, 483 - Vila Gomes Cardim, São Paulo - SP,
              03321-000 (Ver no mapa)
            </S.ColumnText>
            <S.ColumnText
              onClick={() => window.open(dataContact.TATUAPE.work_with_us)}
            >
              <strong className="text-secondary">Trabalhe conosco</strong>
            </S.ColumnText>
            <S.ColumnText>
              <strong>WhatsApp</strong>
            </S.ColumnText>
            <S.ColumnText
              marginTop={"0px"}
              fontWeight={400}
              onClick={() =>
                window.open("https://api.whatsapp.com/send?phone=5511917706110")
              }
            >
              Número: (11) 91770-6110
            </S.ColumnText>
            <S.ColumnText>
              <strong>Telefone fixo</strong>
            </S.ColumnText>
            <S.ColumnText
              marginTop={"0px"}
              fontWeight={400}
              onClick={() => {
                window.location.href = `tel:(11)5641-1720`;
              }}
            >
              Número: (11) 5641-1720
            </S.ColumnText>
            <S.ColumnText>
              <strong>CNPJ</strong>
            </S.ColumnText>
            <S.ColumnText marginTop={"0px"} fontWeight={400}>
              CNPJ: 41.920.044/0001-17
            </S.ColumnText>
            <S.ColumnText>
              <strong>Imprensa</strong>
            </S.ColumnText>
            <S.ColumnText
              marginTop={"0px"}
              fontWeight={400}
              onClick={() => openMail("marketing@pophaus.net")}
            >
              marketing@pophaus.net
            </S.ColumnText>
          </>
        ) : null}
      </S.ColumnWrapper>
      <S.ColumnWrapper>
        <img src={lineFooter} alt="line" />
        <S.ColumnTitle>Encontre o Pop</S.ColumnTitle>
      </S.ColumnWrapper>

      <S.IconContainer>
        <S.IconWrapper
          onClick={() =>
            window.open("https://instagram.com/pophaus_sp/", "_blank")
          }
        >
          <img src={instagramIcon} alt="Instagram Icon" />
        </S.IconWrapper>
        <S.IconWrapper
          onClick={() =>
            window.open("https://m.facebook.com/PopHaus4Fun/", "_blank")
          }
        >
          <img src={facebookIcon} alt="Facebook Icon" />
        </S.IconWrapper>
        <S.IconWrapper
          onClick={() =>
            window.open(
              "https://youtube.com/channel/UCov_8HtqI8Wg1WwzmUJAY5g",
              "_blank"
            )
          }
        >
          <img src={youtubeIcon} alt="Youtube Icon" />
        </S.IconWrapper>
      </S.IconContainer>
    </S.Container>
  );
};

export default MobileFooter;
