export const getAlbum = (data, callback = console.log) => {
  fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/albums/${
      data?.id
    }?${new URLSearchParams(data)}`
  )
    .then((response, a, b) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject({ message: response.statusText });
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const getAlbumPics = (data, callback = console.log) => {
  fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/photos/list?${new URLSearchParams(
      data
    )}`
  )
    .then((response, a, b) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject({ message: response.statusText });
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      callback(null, error);
    });
};
