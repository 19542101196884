import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Header from "../../../components/Header";
import { StepBar } from "../../../components/Steps";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import { SimulatorHook } from "../../../context/simulatorContext";
import { TiArrowBackOutline } from "react-icons/ti";
import { viewUnit } from "../../../utils/handleUnitSelect";
import { viewPartyType } from "../../../utils/handleUnitPartyType";
import { sumPlayersAll } from "../../../utils/sumPlayers";
import { handleDate } from "../../../utils/handleDate";
import { Api } from "../../../api/api";
import ModalAnswer from "../modalAnsewer";
import EditSimulator from "../EditSimulator";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "../../../components/CircularProgress";

const SixContainerSimulator = () => {
  const navigate = useNavigate();
  const {
    name,
    phone,
    email,
    currentStep,
    setCurrentStep,
    unit,
    eventType,
    duration,
    date,
    totalParticipants,
    kids_player,
    initHours,
    quiosque,
    buffet,
    cardapio,
    socks,
    socksAnt,
    kidsPlusOneTickets,
    kidsTickets,
    pcdTickets,
    currentStepOption,
    allKiosks,
    pcdPlusOneTickets,
    plusOneTickets,
    regularTickets,
    cups,
    towel,
    areas,
    kioskTarget,
    durationKioskTarget,
    productsPrices,
    setProductsPrices,
    simulationMetricID,
    buttonsDisabled,
    setButtonsDisabled,
    doubtsId,
    setDoubtsId,
    bundle,
  } = SimulatorHook();
  const [openEditSimulator, setOpenEditSimulator] = useState(false);
  const [price, setPrice] = useState(0);
  const [reRender, setReRender] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);

    const obj = {
      filial: unit,
      spaces: areas,
      duration: duration,
      regular: regularTickets,
      plus_one: plusOneTickets,
      kids: kidsTickets,
      kids_plus_one: kidsPlusOneTickets,
      pcd: pcdTickets,
      pcd_plus_one: pcdPlusOneTickets,
      date: date,
      time: initHours,
      kiosk_1: false,
      kiosk_2: false,
      kiosk_3: false,
      kiosk_4: false,
      kiosk_5: false,
      kiosk_6: false,
      kiosk_indoor: false,
      buffet: buffet,
      menu_1: cardapio === 1 && buffet === true ? true : false,
      menu_2: cardapio === 2 && buffet === true ? true : false,
      menu_3: cardapio === 3 && buffet === true ? true : false,
      menu_4: cardapio === 4 && buffet === true ? true : false,
      common_sock: socks,
      non_slip_sock: socksAnt,
      cup: cups,
      towel: towel,
      cabinet_rental: 0,
      cotton_candy: 0,
      cotton_candy_machine: 0,
      exclusive_monitor: 0,
      kiosks_target: kioskTarget,
      model: eventType,
      duration_kiosk: Number(durationKioskTarget),
      bundle,
    };
    Api.post("/party_simulator/party_price", obj)
      .then((res) => {
        setPrice(res.data.data.price_total);
        setReRender(false);
        setProductsPrices(res.data.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }, [reRender]);

  function handleCreateParty({ answer, doubts = false, editDoubts = false }) {
    const obj = {
      filial: unit,
      spaces: areas,
      duration: duration,
      regular: regularTickets,
      plus_one: plusOneTickets,
      kids: kidsTickets,
      kids_plus_one: kidsPlusOneTickets,
      pcd: pcdTickets,
      pcd_plus_one: pcdPlusOneTickets,
      date: date,
      time: initHours,
      buffet: buffet,
      menu_1: cardapio === 1 && buffet === true ? true : false,
      menu_2: cardapio === 2 && buffet === true ? true : false,
      menu_3: cardapio === 3 && buffet === true ? true : false,
      menu_4: cardapio === 4 && buffet === true ? true : false,
      common_sock: socks,
      non_slip_sock: socksAnt,
      cup: cups,
      towel: towel,
      cabinet_rental: 0,
      name: name,
      email: email,
      phone: phone,
      cotton_candy: 0,
      cotton_candy_machine: 0,
      exclusive_monitor: 0,
      kiosks_target: kioskTarget,
      scheduled: answer, //STATUS
      doubts: doubts,
      crm: false,
      scheduled_by: "",
      price_total: 0,
      total_number_of_guests: 0,
      additional_info: "",
      created_at: "",
      updated_at: "",
      model: eventType,
      duration_kiosk: Number(durationKioskTarget),
      bundle,
    };
    if (editDoubts) {
      const response = Api.put(`/party_simulator/update_info/${doubtsId}`, obj)
        .then((res) => {
          if (answer) {
            setCurrentStep(7);
          }
          if (!answer && doubts === false) {
            setOpenAnswer(true);
            if (unit === "SANTO_AMARO") {
              window.open("https://api.whatsapp.com/send?phone=5511917706110");
            } else if (unit === "BRASILIA") {
              window.open("https://api.whatsapp.com/send?phone=5561998253145");
            } else if (unit === "TATUAPE") {
              window.open("https://api.whatsapp.com/send?phone=5511976338493" );
            } else {
              window.open("https://api.whatsapp.com/send?phone=5511976338493" );
            }
          }
          return res;
        })
        .catch(() => {
          window.alert("Err");
        });
      return response;
    } else {
      const response = Api.post("/party_simulator/create_party", obj)
        .then((res) => {
          return res;
        })
        .catch(() => {
          window.alert("Err");
        });
      return response;
    }
  }

  const updateSimulationMetric = (data) => {
    Api.put(`simulations_metrics/${simulationMetricID}`, data);
  };

  useEffect(() => {
    if (!doubtsId)
      handleCreateParty({ answer: false, doubts: true }).then((res) =>
        setDoubtsId(res.data.id)
      );

    updateSimulationMetric({ ended_simulation: moment().toJSON(), sent_simulation: moment().toJSON(), sent_type: "SCHEDULE"});
  }, []);

  return (
    <S.Container>
      <ModalAnswer open={openAnswer} onClose={() => setOpenAnswer(false)} />
      <EditSimulator
        open={openEditSimulator}
        onClose={() => setOpenEditSimulator(false)}
        reRender={() => {
          setReRender(true);
          setButtonsDisabled(false);
        }}
      />
      <S.SubContainer>
        <S.GridContainer>
          <div>
            <S.Description>
              <p>Nome:</p>
              <span>{name}</span>
            </S.Description>

            <S.Description>
              <p>Email: </p>
              <span>{email}</span>
            </S.Description>

            <S.Description>
              <p>Telefone: </p>
              <span>{phone}</span>
            </S.Description>
            <S.Description>
              <p>Unidade: </p>
              <span>{viewUnit(unit)}</span>
            </S.Description>

            <S.Description>
              <p>Data: </p>
              <span>{handleDate(date)}</span>
            </S.Description>

            <S.Description>
              <p>Período: </p>
              <span>{`${initHours}`}</span>
            </S.Description>

            <S.Description>
              <p>Tipo de Evento: </p>
              <span>{viewPartyType(eventType)}</span>
            </S.Description>

            <S.Description>
              <p>Áreas: </p>
              <div className="d-flex gap-1">
                {areas.map((area) => (
                  <p className="text-warning">
                    {area.label}
                    {areas.length > 1 && ", "}
                  </p>
                ))}
              </div>
            </S.Description>

            <S.Description>
              <p>Total de Convidados: </p>
              <span>
                x
                {sumPlayersAll({
                  kidsPlusOneTickets: kidsPlusOneTickets,
                  kidsTickets: kidsTickets,
                  regularTickets: regularTickets,
                  pcdPlusOneTickets: pcdPlusOneTickets,
                  pcdTickets: pcdTickets,
                  plusOneTickets: plusOneTickets,
                })}
              </span>
            </S.Description>
            {eventType !== "exclusive" && (
              <>
                <S.Description>
                  <p>Brincantes: </p>
                  <span>x{regularTickets}</span>
                </S.Description>
                <S.Description>
                  <p>Não Brincantes: </p>
                  <span>x{plusOneTickets}</span>
                </S.Description>
              </>
            )}
            {!bundle && eventType !== "exclusive" && (
              <>
                <S.Description>
                  <p>Menor de 5 anos: </p>
                  <span>x{kidsTickets}</span>
                </S.Description>
                <S.Description>
                  <p>Acompanhante do Menor de 5 anos: </p>
                  <span>x{kidsPlusOneTickets}</span>
                </S.Description>
                <S.Description>
                  <p>PcD: </p>
                  <span>x{pcdTickets}</span>
                </S.Description>
                <S.Description>
                  <p>Acompanhante: </p>
                  <span>x{pcdPlusOneTickets}</span>
                </S.Description>
              </>
            )}
          </div>
          <div className="border-div"></div>
          <div>
            {bundle && (
              <S.Description>
                <p>Pacote:</p>
                <span>{bundle.name}</span>
              </S.Description>
            )}
            <S.Description>
              <p>Duração: </p>
              <span>
                {Number(duration) === 1
                  ? `${duration} Hora`
                  : `${duration} Horas`}
              </span>
            </S.Description>
            <S.Description>
              <p>Buffet: </p>
              <span>{buffet ? "Sim" : "Não"}</span>
            </S.Description>

            <S.Description>
              <p>Cardápio: </p>
              <span>
                {buffet && cardapio ? `Cardápio ${cardapio}` : "Não"}{" "}
              </span>
            </S.Description>

            <S.Description>
              <p>Quiosque: </p>
              <span> {kioskTarget?.label ?? "Não"} </span>
            </S.Description>

            <S.Description>
              <p>Duração de Quiosque: </p>
              {bundle || eventType === "exclusive" ? (
                <span>Todo o evento</span>
              ) : (
                <span>
                  {kioskTarget?.value === "kiosk_0"
                    ? "S/ Quiosque"
                    : `${durationKioskTarget} horas`}{" "}
                </span>
              )}
            </S.Description>

            {towel > 0 && (
              <S.Description>
                <p>Toalhas de Banho: </p>
                <span>
                  R${" "}
                  {(productsPrices.towel_value || 0)
                    .toFixed(2)
                    .replace(".", ",")}{" "}
                  x{towel} / R${" "}
                  {((productsPrices.towel_value || 0) * towel)
                    .toFixed(2)
                    .replace(".", ",")}
                </span>
              </S.Description>
            )}

            {socks > 0 && (
              <S.Description>
                <p>Meias: </p>
                <span>
                  R${" "}
                  {(productsPrices.common_sock_value || 0)
                    .toFixed(2)
                    .replace(".", ",")}{" "}
                  x{socks} / R${" "}
                  {((productsPrices.common_sock_value || 0) * socks)
                    .toFixed(2)
                    .replace(".", ",")}
                </span>
              </S.Description>
            )}

            {socksAnt > 0 && (
              <S.Description>
                <p>Meias Antiderrapantes: </p>
                <span>
                  R${" "}
                  {(productsPrices.non_slip_sock_value || 0)
                    .toFixed(2)
                    .replace(".", ",")}{" "}
                  x{socksAnt} / R${" "}
                  {((productsPrices.non_slip_sock_value || 0) * socksAnt)
                    .toFixed(2)
                    .replace(".", ",")}
                </span>
              </S.Description>
            )}

            {cups > 0 && (
              <S.Description>
                <p>Copos: </p>
                <span>
                  R${" "}
                  {(productsPrices.cup_value || 0).toFixed(2).replace(".", ",")}{" "}
                  x{cups} / R${" "}
                  {((productsPrices.cup_value || 0) * cups)
                    .toFixed(2)
                    .replace(".", ",")}
                </span>
              </S.Description>
            )}

            <hr className="bg-warning" />
            <S.Description>
              <h2>Total </h2>
              {unit !== "BRASILIA" ? (
                <h2 className="text-warning d-flex align-items-center justify-content-center gap-1">
                  {" "}
                  {price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  {load && <CircularProgress size={20} />}
                </h2>
              ) : (
                <h2 className="text-warning text-truncate d-flex align-items-center justify-content-center gap-1">
                  {" "}
                  {price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  {load && <CircularProgress size={20} />}
                </h2>
              )}
            </S.Description>
          </div>

          <div className="border-div"></div>

          <S.ContainerButtons>
            <div className="d-flex gap-4">
              <button className="btn-refazer" onClick={() => setCurrentStep(1)}>
                Refazer Orçamento
              </button>
              <button
                className="btn-refazer"
                onClick={() => setOpenEditSimulator(true)}
              >
                Editar Orçamento
              </button>
            </div>

            <button
              className="btn-duvidas mt-3"
              style={{ opacity: buttonsDisabled ? 0.6 : 1 }}
              disabled={buttonsDisabled}
              onClick={() => {
                handleCreateParty({
                  answer: false,
                  doubts: false,
                  editDoubts: true,
                });

                updateSimulationMetric({
                  sent_simulation: moment().toJSON(),
                  sent_type: "DOUBTS",
                });

                setButtonsDisabled(true);
              }}
            >
              Quero tirar dúvidas sobre o orçamento
            </button>

            <div className="d-flex gap-4">
              <button
                className="btn_2"
                style={{ opacity: buttonsDisabled ? 0.6 : 1 }}
                disabled={buttonsDisabled}
                onClick={() => {
                  handleCreateParty({
                    answer: true,
                    doubts: false,
                    editDoubts: true,
                  });

                  updateSimulationMetric({
                    sent_simulation: moment().toJSON(),
                    sent_type: "SCHEDULE",
                  });
                }}
              >
                Avançar para o Agendamento
              </button>
            </div>

            {false && (
              <button
                className="btn-duvidas mt-3 text-white"
                onClick={() => {
                  handleCreateParty({
                    answer: false,
                    doubts: true,
                    editDoubts: true,
                  }).then((res) =>
                    toast.success("Orçamento enviado ao E-mail")
                  );
                }}
              >
                Reenviar orçamento
              </button>
            )}
          </S.ContainerButtons>
        </S.GridContainer>
      </S.SubContainer>
    </S.Container>
  );
};

export default SixContainerSimulator;
