import styled from "styled-components";
export const Container = styled.div`
width: 100%;
background: #6F3B8C;
padding-bottom: 50px;
`;

export const SubContainer = styled.div`
  width: 100%;
  padding: 0 7vw;
  color: white;
  padding-bottom: 24px;
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4px 1fr 4px 1fr;
  gap: 48px;

  div.border-div{
    background-color: #fdc028;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }
  @media(max-width: 1320px) {
    display: flex;
    flex-direction: column;
  }
`

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    color: #fdc028;
    font-weight: bold;
  }
`


export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  button.btn_1{
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      span{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
      color: #fff;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 42px;
      text-align: center
    }
    .btn_2{
      padding: 10px;

      border-radius: 25px;
      background-color: #83E193;
      font-weight: 700;
      font-size: 1.3rem;
      text-align: center;
      outline: none;
      border: none;
      opacity: 0.8;
      transition: 0.3s;

      color: #fff;
      &:hover{
        opacity: 1;
      }
    }
    .btn-refazer {
      padding: 15px;
      font-weight: bold;
      border: 1px solid #fdc028;
      background-color: #6f3b8c;
      color: white;
    }
    .btn-duvidas {
      padding: 15px;
      font-weight: bold;
      border: 1px solid #fdc028;
      background-color: #6f3b8c;
      color: #fdc028;
    }
`


