import React, { useState, useEffect } from "react";
import "./index.css";
import { useSwipeable } from "react-swipeable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

const SwipeTimeline = ({
  myList = [
    {
      date: "2018-03-22",
      name: "Event number 01",
    },
  ],
  labelWidth = 200,
  amountMove = 200,
  lineColor = "white",
  darkMode = false,
  eventTextAlignCenter = true,
  arrowsSize = false,
  dateColor = "#6f3b8c",
  nameColor = "white",
  dotBorderColor = "#6f3b8c",
}) => {
  const [positionLeft, setpositionLeft] = useState(0);
  const [positionAllowed, setpositionAllowed] = useState(0);

  const checkIfNeedToDisableRightButton = (leftPosition) => {
    if (leftPosition * -1 >= positionAllowed) {
      setpositionLeft(-positionAllowed);
      return true;
    } else {
      return false;
    }
  };

  const moveAxis = (direction, amount) => {
    let leftPosition;

    if (direction === "right") {
      leftPosition = positionLeft - amount;
      let isNeedDisable = checkIfNeedToDisableRightButton(leftPosition);
      if (isNeedDisable) {
        return;
      }
    } else {
      leftPosition = positionLeft + amount;
      if (leftPosition > 0) {
        setpositionLeft(0);
        return;
      }
    }
    setpositionLeft(leftPosition);
  };

  const resizeListener = () => {
    window.addEventListener("resize", calcAfterResize);
  };

  const calcAfterResize = () => {
    calcPostionSlideLeft();
  };

  useEffect(() => {
    calcPostionSlideLeft();
    resizeListener();
    return () => {
      window.removeEventListener("resize", calcAfterResize);
    };
  }, [positionLeft]);

  const calcPostionSlideLeft = () => {
    let positionAllowed =
      document.getElementById("myAxis").getClientRects()[0].width -
      document.getElementById("axisWrapper").getClientRects()[0].width;
    setpositionAllowed(positionAllowed);
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.deltaX < 0) {
        moveAxis("right", eventData.deltaX * -1);
      } else {
        moveAxis("left", eventData.deltaX);
      }
    },
    trackMouse: true,
    touchEventOptions: { passive: true },
  });

  return (
    <div className="wrapperAll">
      <FontAwesomeIcon
        icon={faChevronCircleLeft}
        size={
          arrowsSize === "sm" || !arrowsSize
            ? "2x"
            : arrowsSize === "med"
            ? "3x"
            : "4x"
        }
        id="leftArrow"
        onClick={() => moveAxis("left", amountMove)}
        style={{
          color: "#6f3b8c",
          borderColor: lineColor,
          cursor: "pointer",
        }}
        className=" enableBtn "
      />
      <div className="axisWrapper" id="axisWrapper" {...handlers}>
        <div
          className={`axis ${positionAllowed <= 0 ? "notEnoghEvents" : ""}`}
          id="myAxis"
          style={{
            left: `${positionLeft}px`,
            borderTop: `2px solid ${lineColor}`,
          }}
        >
          {myList &&
            myList.map((val, index) => {
              return (
                <div
                  className="specificEvent"
                  id="specificEvent"
                  style={{
                    width: `${labelWidth}px`,
                    color: `${darkMode ? "#fff" : "unset"}`,
                    textAlign: `${eventTextAlignCenter ? "center" : "unset"}`,
                  }}
                  key={index}
                >
                  <div
                    className="dot"
                    style={{
                      left: `${labelWidth / 2}px`,
                      background: lineColor,
                      borderStyle: "solid",
                      borderWidth: 2,
                      borderColor: dotBorderColor,
                    }}
                  ></div>

                  <div key={index} className="descriptionEventDetails">
                    <h5 style={{ color: dateColor }}>{val.date}</h5>
                    <p style={{ color: nameColor }}>{val.name}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <FontAwesomeIcon
        icon={faChevronCircleRight}
        size={
          arrowsSize === "sm" || !arrowsSize
            ? "2x"
            : arrowsSize === "med"
            ? "3x"
            : "4x"
        }
        id="rightArrow"
        style={{
          color: "#6f3b8c",
          borderColor: lineColor,
          cursor: "pointer",
        }}
        onClick={() => moveAxis("right", amountMove)}
        className=" enableBtn "
      />
    </div>
  );
};

export default SwipeTimeline;
