import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import * as S from "./styles";
import purpleUpArrow from "../../assets/purpleUpArrow.svg";
import purpleDownArrow from "../../assets/purpleDownArrow.svg";
import lineQuestion from "../../assets/lineQuestion.svg";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Api } from "../../api/api";
import { toast } from "react-toastify";
import ProgressBar from "../../components/ProgressBar";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";

const Duvidas = () => {
  let cookiesUnit = null;

  try {
    cookiesUnit = JSON.parse(localStorage.getItem("doubts_unit"));
  } catch (ex) {}

  const [unit, setUnit] = useState(cookiesUnit || null);
  const [unities, setUnities] = useState([]);
  const [loadUnities, setLoadUnities] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(
    localStorage.getItem("doubt_category_id") || null
  );
  const [loadCategories, setLoadCategories] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [load, setLoad] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState({ page: 0, limit: 5 });

  const handleOpenQuestions = () => {
    window.open("https://my.forms.app/parquepophaus/duvidas");
  };

  const handleNavigateAllQuestions = () => {
    window.open(
      "/Duvidas/Todas/" +
        unit.id +
        "?secao=" +
        category +
        "&unit_name=" +
        unit.name,
      "_self"
    );
  };

  const getUnities = () => {
    setLoadUnities(true);
    Api.get("unities")
      .then((res) => {
        setUnities(res.data.data);
        if (
          res.data.data[0] &&
          (!unit || !res.data.data.find((item) => item.id === unit.id))
        )
          changeUnit(res.data.data[0]);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadUnities(false));
  };

  const getCategories = () => {
    setLoadCategories(true);
    Api.get("doubts_categories/has_doubts", { params: { unit_id: unit.id } })
      .then((res) => {
        setCategories(res.data.list);
        if (
          res.data.list[0] &&
          (!category || !res.data.list.find((item) => item.id === category))
        )
          changeCategory(res.data.list[0].id);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadCategories(false));
  };

  useEffect(() => {
    getUnities();
  }, []);

  useEffect(() => {
    if (unit) getCategories();
  }, [unit]);

  const getList = () => {
    setLoad(true);
    Api.get("doubts", {
      params: {
        ...filter,
        doubt_category_id: category,
        unit_id: unit.id,
        main_page: true,
      },
    })
      .then((res) => {
        setList(res.data.list);
        setTotal(res.data.total);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    if (unit && category) getList();
  }, [category, unit, filter]);

  const getPagesCount = () => {
    const division = Number(Number(total) / Number(filter.limit));
    let count = Math.floor(division);

    if (!Number.isInteger(division)) count += 1;

    return count;
  };

  const getPaginationItems = () => {
    const items = [];

    for (let index = 0; index < getPagesCount(); index++) {
      const currentPage = index + 1;
      items.push(
        <Pagination.Item
          onClick={() => setFilter((prev) => ({ ...prev, page: index }))}
          active={index === filter.page}
          style={{ zIndex: 0 }}
        >
          {currentPage}
        </Pagination.Item>
      );
    }
    return items;
  };

  const handleLimitChange = (e) => {
    setFilter((prev) => ({ ...prev, limit: e.target.value }));
  };

  const changeCategory = (categoryId) => {
    setCategory(categoryId);
    localStorage.setItem("doubt_category_id", categoryId);
  };

  const changeUnit = (newUnit) => {
    setUnit(newUnit);
    localStorage.setItem("doubts_unit", JSON.stringify(newUnit));
  };

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <>
      <Helmet>
        <title>FAQ: tire suas dúvidas sobre o Pop | PopHaus</title>
      </Helmet>
      <Header />
      <S.TitleContainer>
        <S.TitleWrapper>Como podemos ajudar?</S.TitleWrapper>
      </S.TitleContainer>
      <S.CenterContainer>
        <S.TextWrapper fontSize={"1.2em"}>Dúvidas frequentes</S.TextWrapper>
        <S.UnitSelectorContainer>
          <S.SelectionLabel>{"Selecione a unidade:"}</S.SelectionLabel>
          <Select
            options={unities}
            value={unit}
            onChange={changeUnit}
            getOptionLabel={(opt) => opt.name}
            getOptionValue={(opt) => opt.id}
            isDisabled={loadUnities}
          />
        </S.UnitSelectorContainer>
        {unit && (
          <S.SectionContainer>
            {categories.map((sec, secInd) => (
              <S.SectionWrapper
                key={"section_" + sec.id}
                marginLeft={secInd === 0 ? "0px" : undefined}
                onClick={() => changeCategory(sec.id)}
                active={category === sec.id ? true : false}
              >
                {sec.name}
              </S.SectionWrapper>
            ))}
          </S.SectionContainer>
        )}
        {load && <ProgressBar className="m-1" />}
        {list.map((question, index) => {
          return (
            <div key={"question_" + question.id} className="w-100">
              <S.QuestionContainer
                onClick={() => {
                  selectedItem === question.id
                    ? setSelectedItem(null)
                    : setSelectedItem(question.id);
                }}
              >
                <S.QuestionWrapper>{question.question}</S.QuestionWrapper>
                <img
                  src={
                    selectedItem === question.id
                      ? purpleUpArrow
                      : purpleDownArrow
                  }
                  alt="arrow"
                  style={{ pointerEvents: "none", userSelect: "none" }}
                />
              </S.QuestionContainer>
              {selectedItem === question.id ? (
                <S.AnswerWrapper
                  dangerouslySetInnerHTML={{ __html: question.answer }}
                />
              ) : null}
              <img src={lineQuestion} alt="line" width={"100%"} />
            </div>
          );
        })}
        {!list[0] && !load && (
          <span>Não há dúvidas cadastradas nesta unidade.</span>
        )}
        <Pagination className="d-flex align-items-center mt-1">
          {getPaginationItems()}
          <Form.Select
            value={filter.limit}
            onChange={handleLimitChange}
            className="w-auto ml-1"
            style={{ marginLeft: "1rem" }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </Form.Select>
        </Pagination>
        {unit && (
          <S.Button onClick={handleNavigateAllQuestions}>
            Ver todas as dúvidas
          </S.Button>
        )}
        {false && (
          <S.Button onClick={handleOpenQuestions}>Envie sua dúvida</S.Button>
        )}
      </S.CenterContainer>
      <Footer />
    </>
  );
};

export default Duvidas;
