import React from "react";
import { userAcceptedLocalStorageUsage } from "../../LocalStorageAndCookiesModal";
import * as S from "./styles";

const BuyButton = ({ width, marginTop }) => {
  return (
    <div
      className="button__secondary--pulse w-75"
      width={width}
      marginTop={marginTop}
      onClick={() =>
        window.open(
          `${process.env.REACT_APP_OLD_WEBSITE_URL}/comprar-ingressos?origem=website`
        )
      }
    >
      Ingressos
    </div>
  );
};

export default BuyButton;
