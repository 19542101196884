import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import "./grid.css";
import logoHeader from "../../../assets/logoHeader.svg";

const GalleryGrid = ({
  mobile,
  gridPictures,
  currentImage,
  nextImage,
  prevImage,
  handleClick,
  handleClose,
  handleMoveNext,
  handleMovePrev,
}) => {
  return (
    <>
      {currentImage && (
        <Lightbox
          mainSrc={currentImage.src}
          nextSrc={nextImage.src}
          imageTitle={
            <img
              src={logoHeader}
              alt="PopHaus"
              style={{ objectFit: "scale-down", display: "inline-block" }}
            />
          }
          prevSrc={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          wrapperClassName="lightbox-wrapper"
          enableZoom={false}
          reactModalStyle={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "#6F3B8CEE",
            },
          }}
        />
      )}

      <Gallery
        id="grid-gallery"
        onClick={handleClick}
        enableImageSelection={false}
        images={gridPictures}
        rowHeight={mobile ? 100 : 200}
        margin={mobile ? 2 : 6}
      />
    </>
  );
};

export default GalleryGrid;
