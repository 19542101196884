import React, { useState, useEffect } from "react";

import * as S from "./styles";
import { Api } from "../../api/api";
import ParkAreaAttractionsCarousel from "./ParkAreaAttractionsCarousel";
import ProgressBar from "../../components/ProgressBar";
import ParkAreaAttractionsCarouselModal from "./ParkAreaAttractionsCarousel/Modal";

export const ParkAreas = ({ mobile }) => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);
  const [selectedParkArea, setSelectedParkArea] = useState(null);
  const urlParams = new URLSearchParams(window.location.search.substring(1));
  const unit = urlParams.get("Unidade");
  const [
    openParkAreaAttractionsCarouselModal,
    setOpenParkAreaAttractionsCarouselModal,
  ] = useState(undefined);

  const getUnitVarName = (unitName) => {
    let unitVarName = "";

    switch (unitName) {
      case "Tatuape":
        unitVarName = "TATUAPE";
        break;
      case "SantoAmaro":
        unitVarName = "SANTO_AMARO";
        break;
      case "Brasilia":
        unitVarName = "BRASILIA";
        break;

      default:
        break;
    }

    return unitVarName;
  };

  const getParkAreas = () => {
    setLoad(true);
    Api.get(`park_areas/${getUnitVarName(unit)}`)
      .then((res) => {
        setList(res.data.list);
        setSelectedParkArea(res.data.list[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    getParkAreas();
  }, [unit]);

  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, []);

  return (
    <div className="w-100 mb-4">
      <S.CenterContainer mobile={mobile}>
        <div
          className="d-flex justify-content-center flex-wrap p-2 gap-2"
          style={{
            backgroundColor: "rgba(253, 192, 40, 0.4)",
            minWidth: 100,
            marginBottom: 40,
            borderRadius: 20,
          }}
        >
          {load && <ProgressBar />}
          {list.map((row, index) => {
            return (
              <S.RowContainer
                key={index}
                onClick={() => setSelectedParkArea(row)}
              >
                <S.ImageContainer selected={row.id === selectedParkArea.id}>
                  <S.Image src={row.avatar} alt="Avatar da Área do Parque" />
                </S.ImageContainer>
                <S.TextWrapper style={{ fontWeight: "bold" }}>
                  {row.name}
                </S.TextWrapper>
              </S.RowContainer>
            );
          })}
        </div>
      </S.CenterContainer>
      <div
        className="flex align-items-center justify-content-center p-4"
        style={{
          backgroundColor: "#ed5046",
          borderRadius: "20px 20px 20px 20px",
        }}
      >
        <ParkAreaAttractionsCarousel
          list={selectedParkArea?.area_attractions}
          onItemClick={(itemIndex) =>
            setOpenParkAreaAttractionsCarouselModal(itemIndex)
          }
        />
        {openParkAreaAttractionsCarouselModal >= 0 && (
          <ParkAreaAttractionsCarouselModal
            startIndex={openParkAreaAttractionsCarouselModal}
            list={selectedParkArea?.area_attractions}
            handleClose={() =>
              setOpenParkAreaAttractionsCarouselModal(undefined)
            }
          />
        )}
      </div>
    </div>
  );
};
