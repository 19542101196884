import React, { useEffect } from "react";
import * as S from "./styles";

import IconContainer from "../../assets/6.svg";
import IconPopIndoor from "../../assets/8.svg";
import IconPopIndoorT from "../../assets/15.svg";
import IconPopJump from "../../assets/14.svg";
import IconPopOutdoor from "../../assets/21.svg";
import IconPopOutdoorT from "../../assets/22.svg";
import IconSocks from "../../assets/3.svg";
import IconNonSlip from "../../assets/25.svg";
import IconChildrenProhibited from "../../assets/10.svg";
import IconHeight from "../../assets/12.svg";
import IconSlipper from "../../assets/1.svg";
import IconProhibitedJeans from "../../assets/2.svg";
import IconAnimalsNotAllowed from "../../assets/9.svg";
import IconProhibitedCigarettes from "../../assets/13.svg";
import IconNecklaceProhibited from "../../assets/17.svg";
import IconClothes from "../../assets/4.svg";
import IconSwimwear from "../../assets/23.svg";
import IconFoods from "../../assets/24.svg";
import IconTime from "../../assets/5.svg";
import IconQRCode from "../../assets/11.svg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { objectMapper } from "../Unidades/Objects";
import { useBootstrapBreakpoint } from "../../utils/util";
import IconBubbleSoccer from "../../assets/18.svg";
import IconSling from "../../assets/Sling.svg";

const RegrasERecomendacoes = () => {
  const urlParams = new URLSearchParams(window.location.search.substring(1));
  const unit = urlParams.get("filial");
  const breakpoint = useBootstrapBreakpoint();

  const isTatuape = unit === "TATUAPE";

  const whatCant = [
    {
      icon: IconProhibitedJeans,
      text: "Proibido usar roupas jeans.",
    },
    {
      icon: IconAnimalsNotAllowed,
      text: "Proibido animais de estimação (exceto cão-guia).",
    },
    {
      icon: IconProhibitedCigarettes,
      text: "É proibido fumar nas depedências do parque (exceto o estacionamento)",
    },
    {
      icon: IconNecklaceProhibited,
      text: "Proibido o uso de acessórios (colares, brincos, pulseiras e etc) pois podem machucar.",
    },
  ];

  const getFaqUrl = () => {
    let unitParam = "SantoAmaro";

    if (isTatuape) unitParam = "Tatuape";

    let url = `${process.env.REACT_APP_NEW_WEBSITE_URL}/Duvidas/Todas/270ea04e-1505-49d6-bd6e-8346fc26aaf3?secao=4&unit_name=${unitParam}`;

    return url;
  };

  const prepareToPlay = [
    {
      icon: IconClothes,
      text: "No dia da visita, vista-se confortavelmente",
    },
    {
      icon: IconFoods,
      text: "A entrada de alimento e bebidas é parcialmente controlada.",
      action: {
        url: getFaqUrl(),
        label: "Saiba mais",
      },
    },
    {
      icon: IconTime,
      text: "Chegue com antecedência e evite filas.",
    },
    {
      icon: IconQRCode,
      text: "Para retirar a pulseira de identificação, apresente o QR Code ou ID da compra na recepção do parque.",
    },
  ];

  const isMobile = ["xxs", "xs", "sm", "md"].includes(breakpoint)
    ? true
    : false;

  const gridItem = (item, column) => {
    return (
      <S.GridItem isMobile={isMobile} column={column}>
        <S.GridItemIconContainer isMobile={isMobile} column={column}>
          <S.GridItemIcon isMobile={isMobile} src={item.icon} alt={item.url} />
        </S.GridItemIconContainer>
        <div className="d-flex flex-column gap-2">
          <S.GridItemText column={column}>{item.text}</S.GridItemText>
          {item.action && (
            <S.GridItemAction onClick={() => window.open(item.action.url)}>
              {item.action.label}
            </S.GridItemAction>
          )}
        </div>
      </S.GridItem>
    );
  };

  return (
    <>
      <Header />

      <S.Container isMobile={isMobile}>
        <S.TitleContainer>
          <S.Title isMobile={isMobile}>
            Regras e <br /> Recomendações
          </S.Title>
          <S.Subtitle isMobile={isMobile}>
            Um guia prático sobre o funcionamento, dicas e tudo o que você
            precisa saber
          </S.Subtitle>
        </S.TitleContainer>
        <S.Icon
          isMobile={isMobile}
          src={IconContainer}
          alt="Ícone de Regras e Recomendações"
        />
      </S.Container>

      <S.SecondContainer isMobile={isMobile}>
        <S.SecondTitle isMobile={isMobile}>
          A unidade {isTatuape ? "Tatuapé" : "Santo Amaro"} do Pophaus
          <br /> é divida em {isTatuape ? "2" : "3"} áreas:
        </S.SecondTitle>
        <S.IconContainer>
          <div className="d-flex flex-column gap-1 align-items-center">
            <S.IconPopS
              src={!isTatuape ? IconPopIndoor : IconPopIndoorT}
              alt="Ícone 1"
              isMobile={isMobile}
            />
            <S.SecondSubtitle isMobile={isMobile}>Pop Indoor</S.SecondSubtitle>
            <S.Text isMobile={isMobile}>Infláves em espaço coberto</S.Text>
          </div>
          {!isTatuape && (
            <div className="d-flex flex-column gap-1 align-items-center">
              <S.IconPopS src={IconPopJump} alt="Ícone 2" isMobile={isMobile} />
              <S.SecondSubtitle isMobile={isMobile}>Pop Jump</S.SecondSubtitle>
              <S.Text isMobile={isMobile}>Camas elásticas e trampolins</S.Text>
            </div>
          )}
          <div className="d-flex flex-column gap-1 align-items-center">
            <S.IconPopS
              src={!isTatuape ? IconPopOutdoor : IconPopOutdoorT}
              alt="Ícone 3"
              isMobile={isMobile}
            />
            <S.SecondSubtitle isMobile={isMobile}>Pop Outdoor</S.SecondSubtitle>
            <S.Text isMobile={isMobile}>
              {isTatuape
                ? "Brinquedos ao ar livre*"
                : "Atrações com água ao ar livre"}
            </S.Text>
            {isTatuape && (
              <S.SmallText>
                *Atrações aquáticas podem ser substituídas durante o
                outono/inverno. Consulte a disponibilidade na página da unidade
                ou no perfil do Instagram.
              </S.SmallText>
            )}
          </div>
        </S.IconContainer>

        <S.TextSecond>
          É o ingresso que determina as áreas onde você pode brincar
        </S.TextSecond>
      </S.SecondContainer>

      <S.WhiteContainer isMobile={isMobile}>
        <S.ThirdContainer>
          <S.ThirdTitle>Como funciona?</S.ThirdTitle>
          <div>
            <S.ThirdInfoContainer>
              <S.Number className="text-orange">1</S.Number>
              <S.ThirdText className="text-orange">
                Escolha o tipo de ingresso
              </S.ThirdText>
            </S.ThirdInfoContainer>

            <S.TextDescription>
              <b>Brincante:</b> Selecione as áreas (Indoor
              {unit === "SANTO_AMARO" ? ", Jump" : ""} e Outdoor) e o tempo de
              acesso às atrações (2, 3 ou 6 horas).
            </S.TextDescription>

            <S.TextDescription>
              <b>Não brincante:</b> Transite livremente pelo parque sem entrar
              nos brinquedos - nem para tirar fotos, ok?
            </S.TextDescription>

            <S.TextDescription>
              Informação importante: crianças até 4 anos e 11 meses precisam
              estar acompanhadas de um adulto em todas as atividades.
            </S.TextDescription>
          </div>

          <S.Button
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_OLD_WEBSITE_URL}/comprar-ingressos?origem=website&filial=${unit}`
              )
            }
          >
            Compre seu ingresso
          </S.Button>

          <S.Line className="mt-4" />

          <S.ThirdInfoContainer>
            <S.Number className="text-orange">2</S.Number>
            <S.ThirdText className="text-orange">
              Fique atento às regras
            </S.ThirdText>
          </S.ThirdInfoContainer>

          <S.ContainerIcons isMobile={isMobile}>
            <S.ColumnUnitS>
              <S.TextSubtitle>Pop Outdoor</S.TextSubtitle>
              <S.DivWithLabel>
                <S.ContainerSmallIcon isMobile={isMobile}>
                  <S.SmallIcon
                    src={IconSwimwear}
                    alt="Ícone de trajes de banho"
                    isMobile={isMobile}
                  />
                </S.ContainerSmallIcon>
                <S.TextDescriptionIcons>
                  Recomendamos trajes de banho confortáveis, como tops e shorts.
                  Traga toalha e uma troca de roupa
                </S.TextDescriptionIcons>
              </S.DivWithLabel>

              {unit === "SANTO_AMARO" && (
                <S.DivWithLabel>
                  <S.ContainerSmallIcon isMobile={isMobile}>
                    <S.SmallIcon
                      src={IconHeight}
                      alt="Ícone de altura"
                      isMobile={isMobile}
                    />
                  </S.ContainerSmallIcon>
                  <S.TextDescriptionIcons>
                    <strong>Brinquedo com restrições:</strong> Acqua Zone –
                    Altura máxima de 1,50 metros;
                  </S.TextDescriptionIcons>
                </S.DivWithLabel>
              )}

              <S.DivWithLabel>
                <S.ContainerSmallIcon isMobile={isMobile}>
                  <S.SmallIcon
                    src={IconSlipper}
                    alt="Chinelos"
                    isMobile={isMobile}
                  />
                </S.ContainerSmallIcon>
                <S.TextDescriptionIcons>
                  Traga seus chinelos (em dias de sol, o chão fica quente!)
                </S.TextDescriptionIcons>
              </S.DivWithLabel>

              {isTatuape && (
                <>
                  <S.DivWithLabel>
                    <S.ContainerSmallIcon isMobile={isMobile}>
                      <S.SmallIcon
                        src={IconBubbleSoccer}
                        alt="Bubble soccer"
                        isMobile={isMobile}
                      />
                    </S.ContainerSmallIcon>
                    <S.TextDescriptionIcons>
                      Brinquedos com restrições:
                      <br />
                      <strong>Ninja Splash e Mega Slide</strong>: altura mínima
                      1,20m
                    </S.TextDescriptionIcons>
                  </S.DivWithLabel>

                  <S.DivWithLabel>
                    <S.ContainerSmallIcon isMobile={isMobile}>
                      <S.SmallIcon
                        src={IconSling}
                        alt="Slingshot"
                        isMobile={isMobile}
                      />
                    </S.ContainerSmallIcon>
                    <S.TextDescriptionIcons>
                      <strong>Sling Shot</strong>: peso máximo de 80kg
                    </S.TextDescriptionIcons>
                  </S.DivWithLabel>
                </>
              )}
            </S.ColumnUnitS>

            {unit === "SANTO_AMARO" && (
              <S.ColumnUnitS>
                <S.TextSubtitle>Pop Jump</S.TextSubtitle>

                <S.DivWithLabel>
                  <S.ContainerSmallIcon isMobile={isMobile}>
                    <S.SmallIcon
                      src={IconChildrenProhibited}
                      alt="Proibido menores de 3 anos"
                      isMobile={isMobile}
                    />
                  </S.ContainerSmallIcon>
                  <S.TextDescriptionIcons>
                    Acesso exclusivo para <strong>maiores de 3 anos</strong>
                  </S.TextDescriptionIcons>
                </S.DivWithLabel>

                <S.DivWithLabel>
                  <S.ContainerSmallIcon isMobile={isMobile}>
                    <S.SmallIcon
                      src={IconNonSlip}
                      alt="Meias antiderrapantes"
                      isMobile={isMobile}
                    />
                  </S.ContainerSmallIcon>
                  <S.TextDescriptionIcons>
                    Use meias antiderrapantes e roupas secas.
                  </S.TextDescriptionIcons>
                </S.DivWithLabel>
              </S.ColumnUnitS>
            )}

            <S.ColumnUnitS>
              <S.TextSubtitle>Pop Indoor</S.TextSubtitle>

              <S.DivWithLabel>
                <S.ContainerSmallIcon isMobile={isMobile}>
                  <S.SmallIcon
                    src={IconSocks}
                    alt="Ícone de meias"
                    isMobile={isMobile}
                  />
                </S.ContainerSmallIcon>
                <S.TextDescriptionIcons>
                  Use meias comuns e roupas secas
                </S.TextDescriptionIcons>
              </S.DivWithLabel>
            </S.ColumnUnitS>
          </S.ContainerIcons>

          <S.ThirdText className="text-danger my-3">
            O que não pode?
          </S.ThirdText>
          <S.GridContainer isMobile={isMobile}>
            {whatCant.map((item) => gridItem(item))}
          </S.GridContainer>

          <S.Line />

          <S.ThirdInfoContainer>
            <S.Number className="text-orange">3</S.Number>
            <S.ThirdText className="text-orange">
              Prepare-se para brincar!
            </S.ThirdText>
          </S.ThirdInfoContainer>
          <S.GridContainer isMobile={isMobile} column>
            {prepareToPlay.map((item) => gridItem(item, true))}
          </S.GridContainer>
        </S.ThirdContainer>
      </S.WhiteContainer>

      <S.FourthContainer>
        <S.TextFifthContainer>
          Está esperando o quê?
          <br />
          Vem pro POP!
        </S.TextFifthContainer>
        <S.ButtonFifthContainer
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_OLD_WEBSITE_URL}/comprar-ingressos?filial=${unit}`
            )
          }
          className="bg-secondary"
        >
          Quero brincar!
        </S.ButtonFifthContainer>
      </S.FourthContainer>

      <Footer />
    </>
  );
};

export default RegrasERecomendacoes;
