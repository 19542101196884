import React, { useState } from "react";
import * as S from "./styles";
import MobileHeader from "../MobileHeader";
import { Menu } from "../Menu";

import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

const MobileMenu = ({ setMobileMenu, mobileMenu }) => {
  const [sidebar] = useState(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <S.SidebarNav sidebar={sidebar}>
          <MobileHeader setMobileMenu={setMobileMenu} mobileMenu={mobileMenu} />
          <S.SidebarWrap>
            {Menu.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </S.SidebarWrap>
        </S.SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default MobileMenu;
