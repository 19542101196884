import React, { useState } from "react";

export const NavigationContext = React.createContext();

const useMobileMenu = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const changeMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  return [mobileMenu, changeMobileMenu];
};

export const NavigationProvider = ({ children }) => {
  const [mobileMenu, changeMobileMenu] = useMobileMenu();

  return (
    <NavigationContext.Provider
      value={{
        changeMobileMenu: {
          mobileMenu,
          changeMobileMenu,
        },
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
