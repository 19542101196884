const NumberedCards = ({ list, className, cardClassName }) => {
  if (!list) list = [];

  const renderCard = (text, ix) => {
    return (
      <div
        className={`d-flex gap-3 p-3 rounded-4 border w-100 ${
          cardClassName || "border-primary text-primary"
        }`}
        key={`text_item_${ix}`}
      >
        <span className="fs-1 fw-bolder">{ix + 1}</span>
        <span className="fw-bold">{text}</span>
      </div>
    );
  };

  return (
    <div className={`rounded-top-4 ${className || "bg-secondary"}`}>
      <div className="container">
        <div className="d-flex gap-3 pb-5 p-2 p-md-4 p-lg-5 flex-wrap flex-lg-nowrap">
          {list.map(renderCard)}
        </div>
      </div>
    </div>
  );
};

export default NumberedCards;
