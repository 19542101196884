import React, { useContext, useState } from "react";

export const SimulatorContext = React.createContext();

const useSimulator = () => {
  const [planPrice, setPlanPrice] = useState("");
  const [planModel, setPlanModel] = useState("");
  const [planName, setPlanName] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [unit, setUnit] = useState("");
  const [eventType, setEventType] = useState("single");
  const [duration, setDuration] = useState(0);
  const [date, setDate] = useState(new Date());
  const [socks, setSocks] = useState(0);
  const [socksAnt, setSocksAnt] = useState(0);
  const [cups, setCups] = useState(0);
  const [towel, setTowel] = useState(0);
  const [kids_player, setKidsPlayer] = useState(0);
  const [initHours, setInitHours] = useState("Manha");
  const [quiosque, setQuiosque] = useState(0);
  const [cardapio, setCardapio] = useState(1);
  const [kids_not_player, setKidsNotPlayer] = useState(0);
  const [kids_minors_five, setKidsMinorsFive] = useState(false);
  const [buffet, setBuffet] = useState(false);
  const [pcd, setPcd] = useState(false);
  const [areas, setAreas] = useState([]);
  const [regularTickets, setRegularTickets] = useState(0);
  const [plusOneTickets, setPlusOneTickets] = useState(0);
  const [pcdPlusOneTickets, setPcdPlusOneTickets] = useState(0);
  const [pcdTickets, setPcdTickets] = useState(0);
  const [kidsTickets, setKidsTickets] = useState(0);
  const [kidsPlusOneTickets, setKidsPlusOneTickets] = useState(0);
  const totalParticipants = Number(kids_player + kids_not_player);
  const [allKiosks, setAllKiosks] = useState([]);
  const [kioskTarget, setKioskTarget] = useState("");
  const [durationKiosk, setDurationKiosk] = useState([]);
  const [durationKioskTarget, setDurationKioskTarget] = useState(0);
  const [kiosksOptions, setKiosksOptions] = useState([]);
  const [currentStepOption, setCurrentStepOption] = useState(0);
  const [productsPrices, setProductsPrices] = useState({});
  const [simulationMetricID, setSimulationMetricID] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [doubtsId, setDoubtsId] = useState(null);
  const [bundle, setBundle] = useState(null);

  return [
    planPrice,
    setPlanPrice,
    planModel,
    setPlanModel,
    planName,
    setPlanName,
    name,
    setName,
    lastName,
    setLastName,
    currentStep,
    setCurrentStep,
    kidsPlusOneTickets,
    setKidsPlusOneTickets,
    kidsTickets,
    setKidsTickets,
    pcdTickets,
    setPcdTickets,
    pcdPlusOneTickets,
    setPcdPlusOneTickets,
    plusOneTickets,
    setPlusOneTickets,
    regularTickets,
    setRegularTickets,
    unit,
    setUnit,
    eventType,
    setEventType,
    date,
    setDate,
    socks,
    setSocks,
    socksAnt,
    setSocksAnt,
    towel,
    setTowel,
    cups,
    setCups,
    kids_player,
    setKidsPlayer,
    kids_not_player,
    setKidsNotPlayer,
    kids_minors_five,
    setKidsMinorsFive,
    pcd,
    setPcd,
    email,
    setEmail,
    phone,
    setPhone,
    duration,
    setDuration,
    initHours,
    setInitHours,
    totalParticipants,
    quiosque,
    setQuiosque,
    buffet,
    setBuffet,
    cardapio,
    setCardapio,
    areas,
    setAreas,
    allKiosks,
    setAllKiosks,
    kioskTarget,
    setKioskTarget,
    durationKioskTarget,
    setDurationKioskTarget,
    durationKiosk,
    setDurationKiosk,
    kiosksOptions,
    setKiosksOptions,
    currentStepOption,
    setCurrentStepOption,
    productsPrices,
    setProductsPrices,
    simulationMetricID,
    setSimulationMetricID,
    buttonsDisabled,
    setButtonsDisabled,
    doubtsId,
    setDoubtsId,
    bundle,
    setBundle,
  ];
};

export const SimulatorProvider = ({ children }) => {
  const [
    planPrice,
    setPlanPrice,
    planModel,
    setPlanModel,
    planName,
    setPlanName,
    name,
    setName,
    lastName,
    setLastName,
    currentStep,
    setCurrentStep,
    kidsPlusOneTickets,
    setKidsPlusOneTickets,
    kidsTickets,
    setKidsTickets,
    pcdTickets,
    setPcdTickets,
    pcdPlusOneTickets,
    setPcdPlusOneTickets,
    plusOneTickets,
    setPlusOneTickets,
    regularTickets,
    setRegularTickets,
    unit,
    setUnit,
    eventType,
    setEventType,
    date,
    setDate,
    socks,
    setSocks,
    socksAnt,
    setSocksAnt,
    towel,
    setTowel,
    cups,
    setCups,
    kids_player,
    setKidsPlayer,
    kids_not_player,
    setKidsNotPlayer,
    kids_minors_five,
    setKidsMinorsFive,
    pcd,
    setPcd,
    email,
    setEmail,
    phone,
    setPhone,
    duration,
    setDuration,
    initHours,
    setInitHours,
    totalParticipants,
    quiosque,
    setQuiosque,
    buffet,
    setBuffet,
    cardapio,
    setCardapio,
    areas,
    setAreas,
    allKiosks,
    setAllKiosks,
    kioskTarget,
    setKioskTarget,
    durationKioskTarget,
    setDurationKioskTarget,
    durationKiosk,
    setDurationKiosk,
    kiosksOptions,
    setKiosksOptions,
    currentStepOption,
    setCurrentStepOption,
    productsPrices,
    setProductsPrices,
    simulationMetricID,
    setSimulationMetricID,
    buttonsDisabled,
    setButtonsDisabled,
    doubtsId,
    setDoubtsId,
    bundle,
    setBundle,
  ] = useSimulator();
  return (
    <SimulatorContext.Provider
      value={{
        planPrice,
        setPlanPrice,
        planModel,
        setPlanModel,
        planName,
        setPlanName,
        name,
        setName,
        lastName,
        setLastName,
        currentStep,
        kidsPlusOneTickets,
        setKidsPlusOneTickets,
        kidsTickets,
        setKidsTickets,
        pcdTickets,
        setPcdTickets,
        pcdPlusOneTickets,
        setPcdPlusOneTickets,
        plusOneTickets,
        setPlusOneTickets,
        regularTickets,
        setRegularTickets,
        setCurrentStep,
        unit,
        setUnit,
        eventType,
        setEventType,
        date,
        setDate,
        socks,
        setSocks,
        socksAnt,
        setSocksAnt,
        towel,
        setTowel,
        cups,
        setCups,
        kids_player,
        setKidsPlayer,
        kids_not_player,
        setKidsNotPlayer,
        kids_minors_five,
        setKidsMinorsFive,
        pcd,
        setPcd,
        email,
        setEmail,
        phone,
        setPhone,
        duration,
        setDuration,
        initHours,
        setInitHours,
        totalParticipants,
        quiosque,
        setQuiosque,
        buffet,
        setBuffet,
        cardapio,
        setCardapio,
        areas,
        setAreas,
        allKiosks,
        setAllKiosks,
        kioskTarget,
        setKioskTarget,
        durationKioskTarget,
        setDurationKioskTarget,
        durationKiosk,
        setDurationKiosk,
        kiosksOptions,
        setKiosksOptions,
        currentStepOption,
        setCurrentStepOption,
        productsPrices,
        setProductsPrices,
        simulationMetricID,
        setSimulationMetricID,
        buttonsDisabled,
        setButtonsDisabled,
        doubtsId,
        setDoubtsId,
        bundle,
        setBundle,
      }}
    >
      {children}
    </SimulatorContext.Provider>
  );
};

export const SimulatorHook = () => {
  const context = useContext(SimulatorContext);
  return context;
};
