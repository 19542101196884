import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavigationContext } from "../context/navigationContext";
import MobileMenu from "../components/MobileMenu";
import { getWindowDimensions } from "../services/getWindowDimensions";

import Home from "../pages/Home";
import Precos from "../pages/Precos";
import Sobre from "../pages/Sobre";
import Diafeliz from "../pages/Diafeliz";
import Unidades from "../pages/Unidades";
import MobileAtracoes from "../pages/Unidades/MobileAtracoes";
import Coringa from "../pages/Coringa";
import Galeria from "../pages/Galeria";
import Album from "../pages/Album";
import Festas from "../pages/Festas";
import Duvidas from "../pages/Duvidas";
import Alert from "../components/Alert";
import TodasDuvidas from "../pages/TodasDuvidas";
import PopPontos from "../pages/PopPontos";
import Simulator from "../pages/Simulador";
import { NewSimulator } from "../pages/NewSimulator";
import SecondStepSimuluator from "../pages/Simulador/SecondContainer";
import ThirdStepSimulator from "../pages/Simulador/ThirdContainer";
import FourthStepSimulator from "../pages/Simulador/FourthContainer";
import FiveStepSimulator from "../pages/Simulador/FiveContainer";
import SixContainerSimulator from "../pages/Simulador/SixContainer";

import FormContainer from "../pages/NewSimulator/FormContainer";
import { SelectTypeParty } from "../pages/NewSimulator/SelectTypeParty";
import { SelectPan } from "../pages/NewSimulator/SelectPan";
import { FinalOrderPage } from "../pages/NewSimulator/FinalOrderPage";
import { ConfirmationContainer } from "../pages/NewSimulator/ConfirmationContainer";
import { Contact } from "../pages/Contact";
import { InformationPage } from "../pages/informationPage";
import Regras from "../pages/Regras";

const AppRoutes = () => {
  const navigation = useContext(NavigationContext).changeMobileMenu;
  const width = getWindowDimensions().width;

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const mobile = getWindowDimensions().width < getWindowDimensions().height;

  const doAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
  };

  const closeAlert = () => {
    doAlert("", "");
  };

  return (
    <BrowserRouter basename="/">
      <Alert
        close={closeAlert}
        message={alertMessage}
        type={alertType}
        mobile={mobile}
      />
      <Routes>
        <Route
          exact
          path="/"
          name="Home"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Home doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Precos"
          name="Precos Santo Amaro"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Precos doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Sobre"
          name="Sobre o Pop Haus"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Sobre doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        {/* <Route
          path="/Popdiafeliz"
          name="Pop Dia Feliz"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Diafeliz doAlert={doAlert} mobile={mobile} />
            )
          }
        /> */}
        <Route
          path="/Unidades"
          name="Unidades"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Unidades doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/MobileAtracoes"
          name="MobileAtracoes"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <MobileAtracoes doAlert={doAlert} />
            )
          }
        />
        <Route
          path="/Coringa"
          name="Coringa"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Coringa doAlert={doAlert} />
            )
          }
        />
        <Route
          path="/Galeria"
          name="Galeria"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Galeria doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Galeria/:album_id"
          name="Álbum da Galeria"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Album doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Festas"
          name="Festas"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Festas doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Duvidas"
          name="Duvidas"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Duvidas doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Duvidas/Todas/:unit"
          name="Todas as Dúvidas"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <TodasDuvidas doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/PopPontos"
          name="Pop Pontos"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <PopPontos doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Contato"
          name="Contato"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Contact />
            )
          }
        />
        <Route
          path="/InformacaoBrasilia"
          name="InformationPage"
          element={<InformationPage />}
        />
        {/* <Route
          path="/Simulador"
          name="Simulador"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Simulator doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Simulador/step_2"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <SecondStepSimuluator doAlert={doAlert} mobile={mobile} />
            )
          }
        />

        <Route
          path="/Simulador/step_3"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <ThirdStepSimulator doAlert={doAlert} mobile={mobile} />
            )
          }
        />

        <Route
          path="/Simulador/step_4"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <FourthStepSimulator doAlert={doAlert} mobile={mobile} />
            )
          }
        />

        <Route
          path="/Simulador/step_5"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <FiveStepSimulator doAlert={doAlert} mobile={mobile} />
            )
          }
        />
        <Route
          path="/Simulador/step_6"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <SixContainerSimulator doAlert={doAlert} mobile={mobile} />
            )
          }
        /> */}
        <Route
          path="/NewSimulator"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <NewSimulator doAlert={doAlert} mobile={mobile} />
            )
          }
        >
          <Route path="FormContainer" element={<FormContainer />} />
          <Route path="SelectTypeParty" element={<SelectTypeParty />} />
          <Route path="SelectPan" element={<SelectPan />} />
          <Route path="FinalOrderPage" element={<FinalOrderPage />} />
          <Route
            path="ConfirmationContainer"
            element={<ConfirmationContainer />}
          />
        </Route>

        <Route
          path="Regras"
          name="Regras e Recomendações"
          element={
            navigation.mobileMenu ? (
              <MobileMenu
                setMobileMenu={navigation.changeMobileMenu}
                mobileMenu={navigation.mobileMenu}
              />
            ) : (
              <Regras doAlert={doAlert} mobile={mobile} />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
