import React from "react";
import * as S from "./styles";
import aboutThirdIcon from "../../../assets/aboutThirdIcon.svg";

const FifthContainer = ({ width }) => {
  return (
    <S.Container>
      <S.TextContainer>
        <S.TitleContainer>
          <S.TitleWrapper>Diversidade e inclusão</S.TitleWrapper>
        </S.TitleContainer>
        <S.SubTitleContainer>
          <S.SubTextWrapper>
            Nossa missão é proporcionar diversão para todos e não é possível
            fazer isso sem zelar pela diversidade. A inclusão é um dos valores
            que orientam nossas atividades diariamente, e aumentar a
            representatividada não só dos nossos clientes quanto dos nossos
            colaboradores está no centro das nossas preocupações. <br />
            <br />
            Desde setembro de 2020, realizamos o Pop Dia Feliz, uma iniciativa
            em que, mensalmente, trazemos ONGs que trabalham com jovens e
            crianças em situação de vulnerabilidade social para brincar no
            parque gratuitamente. Nossos ambientes - físicos e digitais -
            passaram por adaptações para que se tornassem acessíveis e todos
            nossos colaboradores participam de treinamentos frequentes
            relacionados à diversidade. <br />
            <br />
            Mas não acaba aí: para garantir que estamos sempre nos atualizando e
            promovendo um parque que, de fato, acolha a todos, contamos com um
            comitê de Diversidade e Inclusão. <br />
            <br />
          </S.SubTextWrapper>
        </S.SubTitleContainer>
      </S.TextContainer>
      <S.IconContainer>
        <img
          src={aboutThirdIcon}
          alt="Price Screen Icon"
          width={width > 1200 ? 500 : 250}
        />
      </S.IconContainer>
    </S.Container>
  );
};

export default FifthContainer;
