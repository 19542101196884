import styled from "styled-components";

const Container = styled.div`
  background-color: #ed5046;
  border: 1px solid #ed5046;
  border-radius: 20px 20px 0px 0px;
  height: 685px;
  margin: -20px 0px 0px 0px;
  padding-bottom: 20px;
  display: flex;

  @media (min-width: 0px) and (max-width: 1200px) {
    flex-direction: column-reverse;
    padding-bottom: 80px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  @media (min-width: 0px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  color: #6f3b8c;
  font-size: 40px;
  font-weight: 700;
  line-height: 42.2px;

  @media (min-width: 0px) and (max-width: 1200px) {
    font-size: 26px;
  }
`;

const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
  min-width: 500px;

  @media (min-width: 0px) and (max-width: 600px) {
    min-width: 100%;
  }
`;

const SubTitleWrapper = styled.div`
  margin-top: 15px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.6px;
  max-width: 400px;

  @media (min-width: 0px) and (max-width: 1200px) {
    font-size: 16px;
    line-height: 20px;
    max-width: 300px;
    text-align: center;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (min-width: 0px) and (max-width: 1200px) {
    width: 100%;
  }
`;

export {
  Container,
  TextContainer,
  SubTitleWrapper,
  TitleWrapper,
  IconContainer,
  SubTitleContainer,
};
